@charset "UTF-8";
/**
 * Main stylesheet entry
 */
/**
 * Abstracts
 *
 * Global variables, functions, mixins and placeholders.
 */
/**
 * Contains floats in modern browsers
 *
 * @soruce http://cssmojo.com/the-very-latest-clearfix-reloaded/
 */
/**
 * Hides things but keeps it available for screen readers.
 *
 * @soruce https://github.com/inuitcss/inuitcss/blob/develop/tools/_tools.hidden.scss
 * @param $important Apply all styles as !important
 */
/**
 * Undos hidden-visually()
 *
 * @param $important Apply all styles as !important
 */
/**
 * Removes browser-default list styles
 */
/**
 * Returns url() with an encoded SVG data-uri
 *
 * @param $svg Source SVG
 * @return Inline encoded data value
 */
/**
 * Replaces characters in a string
 *
 * @private
 * @param $string Input value
 * @param $search Value to search
 * @param $replace Value to replace with
 * @return Output value
 */
/**
 * Z-Index helper
 *
 * @param $element Element name
 * @param $list List with elements (defaults to $z-index-order)
 * @param $base Integer to start z-index
 * @return Z-index value for element
 */
/**
 * Vendors
 *
 * CSS files from external libraries and frameworks.
 */
@import url(sanitize.css/sanitize.css);
@import url(swiper/swiper-bundle.css);
@import url(mapbox-gl/dist/mapbox-gl.css);
@import url(tippy.js/dist/tippy.css);
@import url(tippy.js/themes/light.css);
:root {
  --p-icon-size: 1.5rem !important;
  --p-form-field-sm-font-size: 1.1rem !important;
  --p-form-field-icon-color: #0c55a6 !important;
  --p-form-field-border-radius: 0 !important;
  --p-tag-border-radius: 0 !important;
  --p-tag-font-size: 1.3rem !important;
  --p-tag-padding: 0.5rem 1rem !important;
  --p-chip-remove-icon-color: #0c55a6 !important;
  --p-chip-remove-icon-size: 1.2rem !important;
  --p-togglebutton-border-radius: 0 !important;
  --p-togglebutton-content-checked-background: #0c55a6 !important;
  --p-togglebutton-checked-color: #fff !important;
  --p-checkbox-width: 1.6rem !important;
  --p-checkbox-height: 1.6rem !important;
  --p-checkbox-border-radius: 0 !important;
  --p-checkbox-checked-background: #0c55a6 !important;
  --p-checkbox-checked-border-color: #0c55a6 !important;
  --p-select-overlay-border-radius: 0 !important;
  --p-select-option-border-radius: 0 !important;
  --p-multiselect-focus-border-color: #0c55a6 !important;
  --p-multiselect-overlay-border-radius: 0 !important;
  --p-multiselect-option-border-radius: 0 !important;
  --p-multiselect-option-gap: 1rem !important;
  --p-slider-range-background: #0c55a6 !important; }

.p-select-icon {
  font-size: 1.8rem;
  position: absolute;
  top: 0.1rem;
  left: 0.5rem;
  color: #3a54c0; }

.p-select-label {
  font-size: 1.6rem; }

.p-select-option {
  font-size: 1.6rem; }

.p-select-option--icon {
  padding-left: 2rem; }

.p-multiselect-label {
  font-size: 1.6rem; }

.p-multiselect-group-header {
  align-items: center;
  display: flex;
  justify-content: space-between; }

.p-multiselect-group-header-title {
  margin-right: 1rem; }

.p-multiselect-option {
  font-size: 1.6rem; }

.p-multiselect-option__label {
  cursor: pointer;
  user-select: none; }

.p-multiselect-list-container {
  max-height: 25rem !important; }

.p-chip-label {
  font-size: 1.6rem;
  max-width: 25rem;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis; }

.p-tag {
  user-select: none; }

.p-togglebutton-label {
  font-size: 1.2rem; }

.p-button-label {
  font-size: 1.2rem; }

.p-button .icon {
  font-size: 1.2rem; }

.p-inputtext {
  font-size: 1.6rem !important; }

.p-datatable {
  font-size: 1.4rem;
  height: 100%;
  white-space: pre; }

.p-datatable-table-container {
  background-color: #fff;
  height: 100%; }

/**
 * Base
 *
 * Boilerplate code for the project.
 */
body {
  background-color: #fff;
  font-size: 1.6rem;
  min-width: 375px;
  overflow-x: hidden;
  overflow-y: scroll; }

button:-moz-focusring,
[type='button']:-moz-focusring,
[type='reset']:-moz-focusring,
[type='submit']:-moz-focusring {
  outline: 3px solid #868686;
  outline-offset: 5px; }

:focus {
  outline: 3px solid #868686;
  outline-offset: 5px; }

:focus:not(:focus-visible) {
  outline: 0; }

@font-face {
  font-display: swap;
  font-family: 'Lato';
  font-style: normal;
  font-weight: normal;
  src: url("../fonts/Lato-Regular.woff2") format("woff2"), url("../fonts/Lato-Regular.woff") format("woff"); }

@font-face {
  font-display: swap;
  font-family: 'Lato';
  font-style: normal;
  font-weight: bold;
  src: url("../fonts/Lato-Bold.woff2") format("woff2"), url("../fonts/Lato-Bold.woff") format("woff"); }

@font-face {
  font-display: swap;
  font-family: 'Lato';
  font-style: italic;
  font-weight: normal;
  src: url("../fonts/Lato-Italic.woff2") format("woff2"), url("../fonts/Lato-Italic.woff") format("woff"); }

@font-face {
  font-display: swap;
  font-family: 'Lato';
  font-style: italic;
  font-weight: bold;
  src: url("../fonts/Lato-BoldItalic.woff2") format("woff2"), url("../fonts/Lato-BoldItalic.woff") format("woff"); }

@font-face {
  font-display: swap;
  font-family: 'Merriweather';
  font-style: normal;
  font-weight: bold;
  src: url("../fonts/Merriweather-Bold.woff2") format("woff2"), url("../fonts/Merriweather-Bold.woff") format("woff"); }

@font-face {
  font-display: swap;
  font-family: 'MerriweatherLight';
  font-style: normal;
  font-weight: 300;
  src: url("../fonts/Merriweather-Light.woff2") format("woff2"), url("../fonts/Merriweather-Light.woff") format("woff"); }

.f-sans {
  font-family: "Lato", "Lucida Grande", Tahoma, "Helvetica", "Arial", sans-serif; }

.f-serif {
  font-family: "Merriweather", "Georgia", serif; }

.f-serif-light {
  font-family: "MerriweatherLight", "Georgia", serif; }

.f-w-light {
  font-weight: lighter; }

.f-w-normal {
  font-weight: normal; }

.f-w-bold, .person-detail .person-detail__section-text .link {
  font-weight: bold; }

.f-s-normal {
  font-style: normal; }

.f-s-italic {
  font-style: italic; }

/**
 * 1. Offset `alt` text from surrounding copy.
 * 2. Keep images from scaling beyond the width of their parents.
 * 3. Prevent images from being stretched when width or height attributes are
 *    present.
 */
img {
  font-style: italic;
  height: auto;
  max-width: 100%; }

.js-lazyload,
.lazyloading,
.lazyloaded {
  transition-property: opacity; }

.js-lazyload,
.lazyloading {
  opacity: 0; }

.no-js .js-lazyload {
  display: none; }

.lazyloaded {
  opacity: 1; }

/**
 * 1. Set default type styles for the project.
 * 2. Ensure the page always fills at least the entire height of the viewport.
 */
html {
  color: #0e0e0e;
  font-family: "Lato", "Lucida Grande", Tahoma, "Helvetica", "Arial", sans-serif;
  font-size: 62.5%;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-weight: normal;
  line-height: 1;
  min-height: 100%;
  scroll-behavior: smooth; }

.has-overlay {
  overflow: hidden; }

.overlay-background {
  background: rgba(0, 0, 0, 0.7);
  height: 100%;
  left: 0;
  opacity: 0;
  position: fixed;
  top: 0;
  transition: all 300ms ease;
  visibility: hidden;
  width: 100%;
  z-index: 99; }
  .overlay-background[aria-hidden='false'] {
    opacity: 1;
    visibility: visible; }

.tippy-content {
  padding: 3rem; }

::selection {
  background-color: #0c55a6;
  color: #fff; }

/**
 * Declare all block-level element margins in the same direction.
 * See: http://csswizardry.com/2012/06/single-direction-margin-declarations
 */
address,
h1,
h2,
h3,
h4,
h5,
h6,
blockquote,
p,
pre,
dl,
ol,
ul,
figure,
hr,
table,
fieldset {
  margin: 0; }

/**
 * Default headline style
 */
h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: normal; }

/**
 * Match type styles of form elements to parents
 */
button,
input,
optgroup,
select,
textarea {
  font-family: inherit;
  font-size: inherit;
  letter-spacing: inherit;
  padding-inline-end: 0;
  padding-inline-start: 0; }

/**
 * Default link style
 * Style buttons as links
 */
a,
button {
  appearance: none;
  background-color: transparent;
  border: 0;
  color: inherit;
  cursor: pointer;
  display: inline;
  margin: 0;
  outline: none;
  padding: 0;
  text-decoration: none;
  user-select: text; }

/**
 * Default transition time
 */
*,
*::before,
*::after {
  transition-duration: 0.2s;
  transition-property: none; }
  @media (prefers-reduced-motion: reduce) {
    *,
    *::before,
    *::after {
      transition-duration: 0; } }

/**
 * Add the correct font weight in Chrome, Edge, and Safari.
 */
b,
strong {
  font-weight: bold; }

/**
 * Reset citation element
 */
cite {
  font-style: normal; }

.no-js .u-hide-no-js {
  display: none; }

.u-visually-hidden {
  border: 0;
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 1px; }

.u-collapse[aria-expanded='false'] {
  display: none; }

.u-collapse[aria-expanded='true'] {
  display: block; }

.u-margin-top {
  margin-top: 1rem; }

.u-margin-top-2x {
  margin-top: 2rem; }

.u-margin-bottom {
  margin-bottom: 1rem; }

.u-margin-bottom-2x {
  margin-bottom: 2rem; }

.u-padding-top {
  padding-top: 1rem; }

.u-padding-top-2x {
  padding-top: 2rem; }

.u-padding-bottom {
  padding-bottom: 1rem; }

.u-padding-bottom-2x {
  padding-bottom: 2rem; }

.u-highlight-text {
  background-color: initial;
  font-weight: bold; }

.u-no-wrap {
  white-space: nowrap; }

/**
 * Layout
 *
 * Stylesheets for the main parts of the site.
 */
.page {
  display: flex;
  flex-direction: column;
  min-height: 100vh; }

.page__content {
  flex-grow: 1; }
  @media (min-width: 75em) {
    .page--sub .page__content {
      margin-top: 8.8rem; } }

.page__header {
  margin-left: auto;
  margin-right: auto;
  max-width: 100%;
  margin-top: 3rem; }
  @media all {
    .page__header {
      padding-left: 2.4rem;
      padding-right: 2.4rem; } }
  @media (min-width: 23.4375em) {
    .page__header {
      padding-left: 2.4rem;
      padding-right: 2.4rem; } }
  @media (min-width: 48em) {
    .page__header {
      padding-left: 2.4rem;
      padding-right: 2.4rem; } }
  @media (min-width: 75em) {
    .page__header {
      padding-left: 2.4rem;
      padding-right: 2.4rem;
      width: 107.2rem; } }
  @media (min-width: 87.5em) {
    .page__header {
      padding-left: 2.4rem;
      padding-right: 2.4rem; } }

.t-inverted ::selection {
  background-color: #fff;
  color: #0c55a6; }

.t-inverted :focus {
  outline-color: #fff; }

.t-blue {
  background-color: #094381;
  color: #fff; }

.detail {
  margin-left: auto;
  margin-right: auto;
  max-width: 100%; }
  @media all {
    .detail {
      padding-left: 2.4rem;
      padding-right: 2.4rem; } }
  @media (min-width: 23.4375em) {
    .detail {
      padding-left: 2.4rem;
      padding-right: 2.4rem; } }
  @media (min-width: 48em) {
    .detail {
      padding-left: 2.4rem;
      padding-right: 2.4rem; } }
  @media (min-width: 75em) {
    .detail {
      padding-left: 2.4rem;
      padding-right: 2.4rem;
      width: 107.2rem; } }
  @media (min-width: 87.5em) {
    .detail {
      padding-left: 2.4rem;
      padding-right: 2.4rem; } }
  .detail > * {
    margin-top: 3.2rem; }
    @media (max-width: 74.99em) {
      .detail > *:first-child {
        margin-top: 0; } }
    @media (min-width: 75em) {
      .detail > * {
        margin-top: 8rem; } }
  .overview-content + .detail {
    margin-top: 3.2rem; }
    @media (min-width: 75em) {
      .overview-content + .detail {
        margin-top: 10rem; } }

.stage > *:first-child:not(:only-child) {
  margin-bottom: 2rem; }
  @media (min-width: 48em) {
    .stage > *:first-child:not(:only-child) {
      margin-bottom: 4rem; } }
  @media (min-width: 75em) {
    .stage > *:first-child:not(:only-child) {
      margin-bottom: 6rem; } }

.stage > *:not(:first-child) {
  margin-left: auto;
  margin-right: auto;
  max-width: 100%; }
  @media all {
    .stage > *:not(:first-child) {
      padding-left: 2.4rem;
      padding-right: 2.4rem; } }
  @media (min-width: 23.4375em) {
    .stage > *:not(:first-child) {
      padding-left: 2.4rem;
      padding-right: 2.4rem; } }
  @media (min-width: 48em) {
    .stage > *:not(:first-child) {
      padding-left: 2.4rem;
      padding-right: 2.4rem; } }
  @media (min-width: 75em) {
    .stage > *:not(:first-child) {
      padding-left: 2.4rem;
      padding-right: 2.4rem;
      width: 107.2rem; } }
  @media (min-width: 87.5em) {
    .stage > *:not(:first-child) {
      padding-left: 2.4rem;
      padding-right: 2.4rem; } }
  .stage > *:not(:first-child):not(:last-child) {
    margin-bottom: 2rem; }
    @media (min-width: 75em) {
      .stage > *:not(:first-child):not(:last-child) {
        margin-bottom: 3rem; } }

@media (min-width: 75em) {
  .page--overview .stage {
    margin-bottom: 10rem; } }

@media (max-width: 74.99em) {
  .stage {
    margin-bottom: 2rem; } }

.bst-tabs {
  display: block;
  position: relative;
  width: 100%; }

.bst-tabs__control {
  display: block;
  position: relative;
  user-select: none;
  width: 100%; }

.bst-tabs__control ul {
  border-bottom: 0.12rem solid #e2e2e2;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  list-style: none;
  position: relative;
  width: 100%; }

.bst-tabs__control li {
  font-family: "Lato", "Lucida Grande", Tahoma, "Helvetica", "Arial", sans-serif;
  color: #0e0e0e;
  display: inline-block;
  font-size: 1.8rem;
  position: relative; }

.bst-tabs__button {
  background-color: transparent;
  border: 0;
  border-bottom: 0.3rem solid transparent;
  cursor: pointer;
  font-weight: normal;
  padding: 1.8rem;
  position: relative;
  transition: border-color 0.3s ease, font-weight 0.3s ease; }

.bst-tabs__button--active {
  border-color: currentColor;
  color: #0c55a6;
  font-weight: bold;
  pointer-events: none; }

.bst-tabs__content {
  display: block;
  margin-top: 6rem;
  position: relative;
  width: 100%; }

.bst-tabs__content__tabs {
  display: block;
  list-style: none;
  margin: 0;
  padding: 0;
  position: relative;
  width: 100%; }

.bst-tabs__tab {
  display: none;
  position: relative;
  width: 100%; }

.bst-tabs__tab--active {
  display: block; }

/**
 * Preview frame
 */
.preview {
  padding: 2.4rem; }

.preview--paddingless {
  padding: 0; }

.preview-buffer {
  min-height: 100vh; }

/**
 * Particles
 */
.icon {
  display: inline-block;
  height: 1em;
  line-height: 1em;
  user-select: all;
  vertical-align: middle;
  width: 1em; }

@keyframes movement {
  0% {
    background-position: 100% 0; }
  100% {
    background-position: -100% 0; } }

.loading {
  animation: movement 2s linear infinite;
  background-color: #f9f9f9;
  background-image: linear-gradient(to right, #f9f9f9 25%, #868686 50%, #868686 75%);
  background-position: 50% 0;
  background-size: 300% 300%;
  transition-property: background-color; }

.loading--finished {
  animation: none;
  background-color: transparent;
  background-image: none; }

@media (max-width: 74.99em) {
  .jumplist {
    margin-bottom: 2.4rem; } }

.jumplist::-webkit-scrollbar {
  display: none; }

@media (max-width: 74.99em) {
  .jumplist__inner {
    background-color: #f9f9f9;
    box-shadow: 0 2px 4px 0 rgba(225, 225, 225, 0.5);
    padding-left: 2.4rem;
    padding-right: 2.4rem; } }

.jumplist__list {
  list-style: none;
  margin: 0;
  padding-left: 0; }
  @media (max-width: 74.99em) {
    .jumplist__list {
      -ms-overflow-style: none;
      overflow-x: auto;
      overflow-y: hidden;
      scrollbar-width: none;
      white-space: nowrap;
      display: flex;
      padding-bottom: 1.5rem;
      padding-top: 1.5rem; } }
  @media (min-width: 75em) {
    .jumplist__list {
      padding-left: 1rem;
      padding-right: 3rem; } }

@media (max-width: 74.99em) {
  .jumplist__item:not(:last-child) {
    margin-right: 1.5rem; } }

@media (min-width: 75em) {
  .jumplist__item:not(:last-child) {
    margin-bottom: 1.5rem; } }

.jumplist__link {
  color: #535353;
  display: block; }
  .jumplist__link:hover, .jumplist__link:focus {
    text-decoration: underline; }
  @media (max-width: 74.99em) {
    .jumplist__link {
      font-size: 1.2rem;
      letter-spacing: 0.5px;
      text-transform: uppercase; }
      .jumplist__link.active {
        color: #0c55a6;
        font-weight: bold; } }
  @media (min-width: 75em) {
    .jumplist__link {
      font-size: 1.3rem;
      hyphens: auto;
      line-height: 1.25;
      position: relative; }
      .jumplist__link.active {
        color: #0e0e0e;
        font-weight: bold; }
      .jumplist__link.active::before {
        border-bottom: 0.3rem solid transparent;
        border-left: 0.6rem solid #0e0e0e;
        border-top: 0.3rem solid transparent;
        content: '';
        display: inline-block;
        height: 0;
        left: -2rem;
        margin: 0 1rem 0.2rem;
        position: absolute;
        top: 0.5rem;
        width: 0; } }

.basket {
  position: relative; }
  .basket:hover {
    color: #0c55a6; }

.basket__icon {
  font-size: 3rem; }

.basket__count {
  align-items: center;
  background-color: #d71d31;
  border-radius: 50%;
  color: #fff;
  display: flex;
  font-size: 1.2rem;
  font-weight: bold;
  height: 1.8rem;
  justify-content: center;
  position: absolute;
  right: -0.8rem;
  top: -0.8rem;
  width: 1.8rem; }

/**
 * Atoms
 */
.image {
  line-height: 0;
  position: relative; }
  .image.loading--finished {
    padding-top: 0 !important; }

.image__img,
.image__fallback {
  display: block;
  height: auto;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 1; }
  .loading--finished .image__img, .loading--finished
  .image__fallback {
    position: static; }

.image--cover {
  height: 38.4rem; }
  .image--cover .image__img,
  .image--cover .image__fallback {
    height: 100%;
    object-fit: cover; }

.image--16-9 .image__img {
  aspect-ratio: calc(16/9);
  object-fit: cover; }

.button {
  background: linear-gradient(127deg, #0184ca 0%, #094381 100%);
  color: #fff;
  display: inline-flex;
  font-size: 1.6rem;
  letter-spacing: 0.8px;
  line-height: 1.6;
  min-height: 5.6rem;
  padding: 1.5rem 5rem;
  text-align: center;
  text-transform: uppercase; }
  .button:hover, .button:focus {
    background: linear-gradient(127deg, #0184ca 0%, #094381 44%); }
  .button[disabled] {
    cursor: not-allowed;
    opacity: 0.5; }

.t-inverted .button,
.button--white {
  background: none;
  background-color: #fff;
  color: #094381; }
  .t-inverted .button:hover, .t-inverted .button:focus,
  .button--white:hover,
  .button--white:focus {
    background: none;
    background-color: #fff; }

.button__text {
  box-shadow: inset 0 -1px transparent;
  transition-property: box-shadow; }
  .button:not([disabled]):hover .button__text,
  .button:not([disabled]):focus .button__text {
    box-shadow: inset 0 -1px currentColor; }

.headline--1, .header__nav-item-primary,
.headline--2,
.headline--3,
.headline--3,
.headline--4,
.headline--5 {
  font-family: "Merriweather", "Georgia", serif;
  font-weight: bold;
  line-height: 1.5;
  overflow-wrap: break-word;
  word-wrap: break-word; }

.headline--1, .header__nav-item-primary {
  font-size: 2.5rem; }
  .headline--1 .headline__subline, .header__nav-item-primary .headline__subline {
    display: block;
    font-size: 1.8rem;
    font-weight: 300; }
  @media (min-width: 48em) {
    .headline--1, .header__nav-item-primary {
      font-size: 3rem; } }

.headline--2 {
  font-size: 1.6rem; }
  @media (min-width: 48em) {
    .headline--2 {
      font-size: 2rem; } }

.headline--3 {
  font-size: 1.6rem; }

.headline--section {
  font-family: "Lato", "Lucida Grande", Tahoma, "Helvetica", "Arial", sans-serif;
  color: #868686;
  position: relative;
  text-transform: uppercase; }
  .headline--section::after {
    background-color: #868686;
    bottom: -1.5rem;
    content: '';
    display: block;
    height: 1px;
    position: absolute; }
  @media (max-width: 47.99em) {
    .headline--section {
      font-size: 1.4rem;
      letter-spacing: 0.6px;
      margin-bottom: 3.4rem;
      text-align: center; }
      .headline--section::after {
        left: 50%;
        transform: translateX(-50%);
        width: 2.6rem; } }
  @media (min-width: 48em) {
    .headline--section {
      font-size: 1.8rem;
      letter-spacing: 0.8px;
      margin-bottom: 4.7rem; }
      .headline--section::after {
        left: 0;
        width: 5.5rem; } }

.headline__link {
  display: block; }
  .headline__link:hover, .headline__link:focus {
    text-decoration: underline; }

.headline--hidden {
  border: 0 !important;
  clip: rect(0 0 0 0) !important;
  clip-path: inset(50%) !important;
  height: 1px !important;
  margin: -1px !important;
  overflow: hidden !important;
  padding: 0 !important;
  position: absolute !important;
  white-space: nowrap !important;
  width: 1px !important; }

.headline--sapphire {
  color: #0c55a6; }

.kicker {
  color: #868686;
  font-size: 1.2rem;
  letter-spacing: 0.5px;
  line-height: 1.1;
  text-transform: uppercase; }

.link,
.mail:any-link,
.internal-link,
.internal-link-new-window,
.external-link,
.external-link-new-window {
  color: #0c55a6;
  text-decoration: underline; }
  .link:hover, .link:focus,
  .mail:any-link:hover,
  .mail:any-link:focus,
  .internal-link:hover,
  .internal-link:focus,
  .internal-link-new-window:hover,
  .internal-link-new-window:focus,
  .external-link:hover,
  .external-link:focus,
  .external-link-new-window:hover,
  .external-link-new-window:focus {
    color: #0e0e0e; }
  .t-inverted .link, .t-inverted
  .mail:any-link, .t-inverted
  .internal-link, .t-inverted
  .internal-link-new-window, .t-inverted
  .external-link, .t-inverted
  .external-link-new-window {
    color: #fff; }
  .t-inverted .link:hover,
  .t-inverted .link:focus, .t-inverted
  .mail:any-link:hover,
  .t-inverted
  .mail:any-link:focus, .t-inverted
  .internal-link:hover,
  .t-inverted
  .internal-link:focus, .t-inverted
  .internal-link-new-window:hover,
  .t-inverted
  .internal-link-new-window:focus, .t-inverted
  .external-link:hover,
  .t-inverted
  .external-link:focus, .t-inverted
  .external-link-new-window:hover,
  .t-inverted
  .external-link-new-window:focus {
    color: #0184ca; }

.icon-link {
  align-items: center;
  color: #0c55a6;
  display: flex;
  font-size: 1.6rem;
  font-weight: bold; }
  .t-cond .icon-link {
    color: #0e0e0e; }

.icon-link__icon {
  align-items: center;
  border-radius: 50%;
  display: flex;
  flex-shrink: 0;
  font-size: 3.2rem;
  justify-content: center;
  margin-right: 1.6rem; }

.icon-link__text {
  line-height: 1.3; }
  .icon-link:any-link:hover .icon-link__text,
  .icon-link:any-link:focus .icon-link__text {
    text-decoration: underline; }

.icon-link__popover {
  background: #fff; }

.link-list {
  list-style: none;
  margin: 0;
  padding-left: 0; }

.link-list__item {
  border-top: 1px solid #e2e2e2; }
  .link-list__item:last-child {
    border-bottom: 1px solid #e2e2e2; }

.link-list__item-link {
  display: flex;
  font-weight: bold;
  justify-content: space-between;
  line-height: 1.625;
  padding: 1.25rem 2.4rem;
  transition-duration: 0.1s;
  transition-property: background-color; }
  .link-list__item-link:hover, .link-list__item-link:focus {
    background-color: #fff;
    box-shadow: 0 2px 15px 0 rgba(0, 0, 0, 0.08);
    color: #0c55a6; }
  .t-cond .link-list__item-link:hover,
  .t-cond .link-list__item-link:focus {
    color: #0e0e0e;
    text-decoration: underline; }

.link-list__item-icon {
  margin-left: 3rem; }

.breadcrumb {
  margin-left: auto;
  margin-right: auto;
  max-width: 100%; }
  @media all {
    .breadcrumb {
      padding-left: 2.4rem;
      padding-right: 2.4rem; } }
  @media (min-width: 23.4375em) {
    .breadcrumb {
      padding-left: 2.4rem;
      padding-right: 2.4rem; } }
  @media (min-width: 48em) {
    .breadcrumb {
      padding-left: 2.4rem;
      padding-right: 2.4rem; } }
  @media (min-width: 75em) {
    .breadcrumb {
      padding-left: 2.4rem;
      padding-right: 2.4rem;
      width: 107.2rem; } }
  @media (min-width: 87.5em) {
    .breadcrumb {
      padding-left: 2.4rem;
      padding-right: 2.4rem; } }
  @media (max-width: 47.99em) {
    .breadcrumb {
      background-color: #f9f9f9; } }

.breadcrumb::-webkit-scrollbar {
  display: none; }

@media (max-width: 47.99em) {
  .breadcrumb__inner {
    -ms-overflow-style: none;
    overflow-x: auto;
    overflow-y: hidden;
    scrollbar-width: none;
    white-space: nowrap;
    padding: 1.5rem 0; } }

@media (min-width: 48em) {
  .breadcrumb__inner {
    padding: 2.5rem 0; } }

.breadcrumb__list {
  list-style: none;
  margin: 0;
  padding-left: 0;
  display: flex; }
  @media (min-width: 48em) {
    .breadcrumb__list {
      flex-wrap: wrap; } }

.breadcrumb__list-item:not(:last-child)::after {
  border-bottom: 0.3rem solid transparent;
  border-left: 0.6rem solid #868686;
  border-top: 0.3rem solid transparent;
  content: '';
  display: inline-block;
  height: 0;
  margin: 0 1rem 0.2rem;
  width: 0; }

.breadcrumb__item {
  color: #868686;
  font-size: 1.2rem;
  letter-spacing: 0.5px;
  text-transform: uppercase; }
  .breadcrumb__list-item:last-child .breadcrumb__item {
    font-weight: bold; }

.breadcrumb__item:any-link:hover .breadcrumb__label,
.breadcrumb__item:any-link:focus .breadcrumb__label {
  text-decoration: underline; }

.logo {
  color: #0c55a6;
  display: inline-block;
  line-height: 1; }
  .t-cond .logo {
    color: #0e0e0e; }
  .t-inverted .logo {
    color: #fff; }

.logo__image {
  height: 32px;
  width: 260px; }

.definition-list__item:not(:last-child) {
  margin-bottom: 1.5rem; }

.definition-list__term {
  color: #868686;
  font-size: 1.2rem;
  letter-spacing: 0.5px;
  margin-bottom: 0.5rem;
  text-transform: uppercase; }

.definition-list__def {
  line-height: 1.625;
  margin-left: 0; }

.more-link {
  border-bottom: 2px solid #094381;
  color: #094381;
  display: inline-block;
  font-size: 1.6rem;
  letter-spacing: 1px;
  padding: calc(0.25rem + 2px) 0.5rem 0.25rem;
  text-transform: uppercase;
  transition-duration: 0.1s;
  transition-property: background-color, color; }
  .more-link:hover, .more-link:focus {
    background-color: #094381;
    color: #fff; }

.t-cond .more-link {
  border-color: inherit;
  color: inherit; }
  .t-cond .more-link:hover, .t-cond .more-link:focus {
    background-color: unset; }

.input {
  appearance: none;
  background-color: #fff;
  border: 2px solid #e2e2e2;
  display: block;
  font-size: 1.3rem;
  height: 3.2rem;
  padding: 0 1rem;
  transition-property: box-shadow;
  width: 100%; }
  .input::placeholder {
    color: #868686;
    opacity: 1; }
  .input:focus {
    border-color: #0c55a6;
    outline: none; }
  .t-inverted .input {
    background-color: transparent;
    border-color: #fff; }
  @media (min-width: 48em) {
    .input {
      font-size: 1.6rem;
      height: 5.6rem;
      padding: 0 3rem; } }

.input[disabled],
.input[readonly] {
  cursor: not-allowed; }

.input[disabled] {
  color: #868686; }

.input[aria-invalid='true'] {
  border-color: #d71d31; }

.input--small {
  font-size: 1.3rem;
  height: 3.2rem;
  padding: 0 1rem; }

.input--area {
  line-height: 1.5;
  min-height: 18rem;
  padding: 1rem; }
  @media (min-width: 48em) {
    .input--area {
      padding: 2rem; } }

.label {
  color: #0e0e0e;
  display: block;
  font-size: 1.2rem;
  letter-spacing: 0.5px; }
  .t-inverted .label {
    color: #fff; }

.label--invalid {
  color: #d71d31; }

.label--disabled {
  color: #868686; }

.checkbox {
  display: inline-flex;
  font-size: 1.3rem;
  line-height: 1.2;
  min-height: 1.6rem;
  position: relative;
  width: 100%; }
  .checkbox + .checkbox {
    margin-left: 3rem; }
  .checkbox:focus-within {
    outline: 3px solid #868686;
    outline-offset: 5px; }

.checkbox__input {
  border: 0;
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 1px; }

.checkbox__indicator {
  background-color: #fff;
  border: 1px solid #535353;
  display: flex;
  flex-shrink: 0;
  height: 1.6rem;
  justify-content: center;
  position: relative;
  transition-duration: 0.1s;
  transition-property: background-color, border-color;
  user-select: none;
  width: 1.6rem; }
  .checkbox__input:checked + .checkbox__indicator {
    background-color: #0c55a6;
    border-color: #0c55a6;
    color: #fff; }
  .checkbox__input[aria-invalid='true']:checked + .checkbox__indicator {
    background-color: #d71d31;
    border-color: #d71d31;
    color: #fff; }
  .checkbox__input[aria-invalid='true'] ~ .checkbox__indicator {
    background-color: #fff;
    border-color: #d71d31; }
  .checkbox__input[readonly] ~ .checkbox__indicator,
  .checkbox__input[disabled] ~ .checkbox__indicator,
  .checkbox__input[readonly]:hover ~ .checkbox__indicator,
  .checkbox__input[disabled]:hover ~ .checkbox__indicator {
    background-color: #868686;
    border-color: #868686; }

.checkbox__icon {
  align-self: center;
  font-size: 1.1rem;
  opacity: 0; }
  .checkbox__input:checked + .checkbox__indicator .checkbox__icon {
    opacity: 1; }
  .checkbox__input:not(:checked):not([disabled]):hover + .checkbox__indicator .checkbox__icon,
  .checkbox__input:not(:checked)[aria-invalid='true']:hover + .checkbox__indicator .checkbox__icon {
    color: #868686;
    opacity: 1; }

.checkbox__label {
  line-height: 1.5;
  margin-left: 1rem;
  margin-top: -0.2rem; }
  .checkbox__input:checked ~ .checkbox__label {
    color: #0c55a6; }
  .checkbox__input[aria-invalid='true'] ~ .checkbox__label {
    color: #d71d31; }
  .checkbox__input[disabled] ~ .checkbox__label {
    color: #868686; }

.checkbox__indicator,
.checkbox__label {
  cursor: pointer; }
  .checkbox__input[readonly] ~ .checkbox__indicator, .checkbox__input[readonly] ~
  .checkbox__label {
    cursor: default; }
  .checkbox__input[disabled] ~ .checkbox__indicator, .checkbox__input[disabled] ~
  .checkbox__label {
    cursor: not-allowed; }

.radio {
  display: inline-flex;
  font-size: 1.3rem;
  line-height: 1.3;
  min-height: 1.6rem;
  position: relative;
  width: 100%; }
  .radio + .radio {
    margin-left: 3rem; }
  .radio:focus-within {
    outline: 3px solid #868686;
    outline-offset: 5px; }

.radio__input {
  border: 0;
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 1px; }

.radio__indicator {
  background-color: #fff;
  border: 1px solid #535353;
  border-radius: 50%;
  flex-shrink: 0;
  height: 1.6rem;
  position: relative;
  transition-duration: 0.1s;
  transition-property: background-color, border-color;
  user-select: none;
  width: 1.6rem; }
  .radio__indicator::before {
    background-color: #fff;
    border-radius: 50%;
    content: '';
    height: 0.7rem;
    left: 50%;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 0.7rem; }
  .radio__input:checked + .radio__indicator {
    background-color: #0c55a6;
    border-color: #0c55a6; }
  .radio__input:checked + .radio__indicator::before {
    background-color: #fff; }
  .radio__input:not(:checked):not([disabled]):hover + .radio__indicator::before {
    background-color: #868686; }
  .radio__input[aria-invalid='true'] ~ .radio__indicator {
    border-color: #d71d31; }
  .radio__input[aria-invalid='true']:checked ~ .radio__indicator {
    background-color: #d71d31; }
  .radio__input[readonly] ~ .radio__indicator,
  .radio__input[disabled] ~ .radio__indicator {
    background-color: #868686;
    border-color: #868686; }
  .radio__input[readonly]:not(:checked) ~ .radio__indicator::before,
  .radio__input[disabled]:not(:checked) ~ .radio__indicator::before {
    opacity: 0; }

.radio__label {
  line-height: 1.5;
  margin-left: 1rem;
  margin-top: -0.2rem; }
  .radio__input:checked ~ .radio__label {
    color: #0c55a6; }
  .radio__input[aria-invalid='true'] ~ .radio__label {
    color: #d71d31; }
  .radio__input[disabled] ~ .radio__label {
    color: #868686; }

.radio__indicator,
.radio__label {
  cursor: pointer; }
  .radio__input[readonly] ~ .radio__indicator, .radio__input[readonly] ~
  .radio__label {
    cursor: default; }
  .radio__input[disabled] ~ .radio__indicator, .radio__input[disabled] ~
  .radio__label {
    cursor: not-allowed; }

.tag-link {
  color: #0c55a6;
  font-size: 1.6rem; }
  .tag-link:any-link:hover, .tag-link:any-link:focus {
    text-decoration: underline; }

@media (min-width: 48em) {
  .tag-link--large {
    font-size: 2rem;
    line-height: 1.625; } }

.tag-link--serif {
  font-family: "Merriweather", "Georgia", serif;
  color: rgba(255, 255, 255, 0.8);
  font-size: 2rem;
  font-weight: bold; }
  .tag-link--serif:any-link, .tag-link--serif:any-link {
    text-decoration: underline; }
  .tag-link--serif:any-link:hover, .tag-link--serif:any-link:focus {
    color: #fff; }
  @media (max-width: 47.99em) {
    .tag-link--serif {
      font-size: 1.6rem; } }

.link-list {
  list-style: none;
  margin: 0;
  padding-left: 0; }

.link-list__item {
  border-top: 1px solid #e2e2e2; }
  .link-list__item:last-child {
    border-bottom: 1px solid #e2e2e2; }

.link-list__item-link {
  display: flex;
  font-weight: bold;
  justify-content: space-between;
  line-height: 1.625;
  padding: 1.25rem 2.4rem;
  transition-duration: 0.1s;
  transition-property: background-color; }
  .link-list__item-link:hover, .link-list__item-link:focus {
    background-color: #fff;
    box-shadow: 0 2px 15px 0 rgba(0, 0, 0, 0.08);
    color: #0c55a6; }
  .t-cond .link-list__item-link:hover,
  .t-cond .link-list__item-link:focus {
    color: #0e0e0e;
    text-decoration: underline; }

.link-list__item-icon {
  margin-left: 3rem; }

.box-link {
  align-items: center;
  border: 1px solid #e2e2e2;
  display: flex;
  height: 6rem;
  padding: 1.2rem 2rem 1.2rem 1.6rem;
  position: relative; }

.box-link--active {
  border-color: #094381;
  box-shadow: 0 2px 20px rgba(0, 0, 0, 0.2); }

.box-link__icon {
  font-size: 3.2rem;
  margin-right: 1.6rem; }

.box-link__text {
  font-size: 1.8rem;
  letter-spacing: 0.8px;
  padding-right: 2rem; }
  .box-link:any-link:hover .box-link__text,
  .box-link:any-link:focus .box-link__text {
    text-decoration: underline; }

.box-link__price {
  color: #868686;
  font-size: 1.2rem;
  letter-spacing: 0.5px;
  margin-left: auto; }

.box-link__checkmark {
  color: #094381;
  font-size: 1.6rem;
  margin-left: auto; }

.download-link {
  align-items: center;
  display: flex; }

.download-link__icon {
  align-items: center;
  display: flex;
  flex-shrink: 0;
  font-size: 2.4rem;
  justify-content: center; }

.download-link__text {
  display: flex;
  font-size: 1.6rem;
  font-weight: bold;
  margin-left: 0.8rem;
  transition-property: box-shadow; }
  .download-link:hover .download-link__text,
  .download-link:focus .download-link__text {
    text-decoration: underline; }

.lightbox {
  height: 100vh;
  left: 0;
  overflow-y: scroll;
  padding: 2rem;
  position: fixed;
  scroll-behavior: smooth;
  top: 0;
  width: 100vw;
  z-index: 200; }
  @media (min-width: 48em) {
    .lightbox {
      padding: 4rem; } }

.lightbox__background {
  background: rgba(0, 0, 0, 0.5);
  content: '';
  cursor: pointer;
  display: block;
  height: 100%;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%; }

.lightbox__inner {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 100%;
  position: relative;
  z-index: 2; }
  @media (min-width: 48em) {
    .lightbox__inner {
      margin-left: auto;
      margin-right: auto;
      max-width: 100%; } }
  @media all and (min-width: 48em) {
    .lightbox__inner {
      padding-left: 2.4rem;
      padding-right: 2.4rem; } }
  @media (min-width: 48em) and (min-width: 23.4375em) {
    .lightbox__inner {
      padding-left: 2.4rem;
      padding-right: 2.4rem; } }
  @media (min-width: 48em) and (min-width: 48em) {
    .lightbox__inner {
      padding-left: 2.4rem;
      padding-right: 2.4rem;
      width: 58.5rem; } }
  @media (min-width: 48em) and (min-width: 75em) {
    .lightbox__inner {
      padding-left: 2.4rem;
      padding-right: 2.4rem; } }
  @media (min-width: 48em) and (min-width: 87.5em) {
    .lightbox__inner {
      padding-left: 2.4rem;
      padding-right: 2.4rem; } }
  @media (min-width: 75em) {
    .lightbox__inner {
      margin-left: auto;
      margin-right: auto;
      max-width: 100%; } }
  @media all and (min-width: 75em) {
    .lightbox__inner {
      padding-left: 2.4rem;
      padding-right: 2.4rem; } }
  @media (min-width: 75em) and (min-width: 23.4375em) {
    .lightbox__inner {
      padding-left: 2.4rem;
      padding-right: 2.4rem; } }
  @media (min-width: 75em) and (min-width: 48em) {
    .lightbox__inner {
      padding-left: 2.4rem;
      padding-right: 2.4rem; } }
  @media (min-width: 75em) and (min-width: 75em) {
    .lightbox__inner {
      padding-left: 2.4rem;
      padding-right: 2.4rem;
      width: 107.2rem; } }
  @media (min-width: 75em) and (min-width: 87.5em) {
    .lightbox__inner {
      padding-left: 2.4rem;
      padding-right: 2.4rem; } }

.lightbox:not(.lightbox--transparent) .lightbox__inner {
  background: #fff;
  padding: 0 !important; }

.lightbox__inner::after {
  content: '';
  display: block;
  height: 100%;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1; }

.lightbox__content {
  height: calc(100% - 8rem);
  overflow: hidden;
  padding: 4rem;
  position: relative;
  width: 100%;
  z-index: 5; }

.lightbox__close {
  cursor: pointer;
  height: 2.4rem;
  margin: 2rem 2rem 0 auto;
  width: 2.4rem;
  z-index: 10; }

.lightbox__close-icon {
  height: 100%;
  width: 100%; }

.lightbox--fadein {
  animation: fadeIn 200ms ease normal;
  opacity: 1; }

.lightbox--closing {
  animation: fadeOut 200ms ease normal;
  opacity: 0; }

@keyframes fadeIn {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

@keyframes fadeOut {
  0% {
    opacity: 100; }
  100% {
    opacity: 0; } }

/**
 * Lightbox--transparent
 */
.lightbox--transparent {
  overflow: scroll; }
  .lightbox--transparent .lightbox__content {
    background: transparent;
    color: #fff;
    overflow: visible;
    padding: 0; }
  .lightbox--transparent .lightbox__background {
    background: rgba(0, 0, 0, 0.8); }
  .lightbox--transparent .lightbox__close {
    color: #fff;
    margin: 0 0 1rem auto;
    mix-blend-mode: difference; }
  .lightbox--transparent .figure__copyright {
    color: #fff; }

.box-list {
  list-style: none;
  margin: 0;
  padding-left: 0;
  display: flex;
  flex-wrap: wrap; }

.box-list__item {
  border: 1px solid #e2e2e2;
  margin-bottom: 0.8rem; }
  .box-list__item:not(:last-child) {
    margin-right: 1.6rem; }

.box-list__link {
  align-items: center;
  display: flex;
  padding: 0.4rem 0.8rem; }

.box-list__icon {
  display: flex;
  font-size: 2.4rem;
  margin-right: 0.8rem; }

.box-list__text {
  font-size: 1.2rem;
  letter-spacing: 0.5px; }
  .box-list__item:hover .box-list__text {
    text-decoration: underline; }

.select {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 56 56'%3E%3Cpath fill='%23e2e2e2' d='M0 0h56v56H0z'/%3E%3Cpath fill='%230e0e0e' d='M20.85 22.56a1.64 1.64 0 1 0-2.3 2.3l8.2 8.19c.64.62 1.66.62 2.3 0l8.2-8.2a1.64 1.64 0 1 0-2.3-2.3l-7.05 7.02-7.05-7.05v.04z'/%3E%3C/svg%3E");
  appearance: none;
  background-color: #fff;
  background-position: right;
  background-repeat: no-repeat;
  border: 2px solid #e2e2e2;
  color: #0e0e0e;
  cursor: pointer;
  display: block;
  font-size: 1.3rem;
  height: 3.2rem;
  letter-spacing: 0.5px;
  padding: 0 4rem 0 1rem;
  position: relative;
  text-overflow: ellipsis;
  transition-property: box-shadow;
  width: 100%; }
  .select:focus {
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 56 56'%3E%3Cpath fill='%230c55a6' d='M0 0h56v56H0z'/%3E%3Cpath fill='%23fff' d='M20.85 22.56a1.64 1.64 0 1 0-2.3 2.3l8.2 8.19c.64.62 1.66.62 2.3 0l8.2-8.2a1.64 1.64 0 1 0-2.3-2.3l-7.05 7.02-7.05-7.05v.04z'/%3E%3C/svg%3E");
    border-color: #0c55a6;
    outline: none; }
  .select option {
    background-color: inherit;
    color: inherit; }
  .select::-ms-expand {
    display: none; }
  .select:-moz-focusring {
    color: transparent;
    text-shadow: 0 0 0 #000; }
  .t-inverted .select {
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 56 56'%3E%3Cpath fill='%23fff' d='M0 0h56v56H0z'/%3E%3Cpath fill='%230e0e0e' d='M20.85 22.56a1.64 1.64 0 1 0-2.3 2.3l8.2 8.19c.64.62 1.66.62 2.3 0l8.2-8.2a1.64 1.64 0 1 0-2.3-2.3l-7.05 7.02-7.05-7.05v.04z'/%3E%3C/svg%3E");
    background-color: transparent;
    border-color: #fff;
    color: #fff; }
  @media (min-width: 48em) {
    .select {
      font-size: 1.6rem;
      height: 5.6rem;
      padding: 0 7rem 0 2rem; } }

.select[aria-invalid] {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 56 56'%3E%3Cpath fill='%23d71d31' d='M0 0h56v56H0z'/%3E%3Cpath fill='%23fff' d='M20.85 22.56a1.64 1.64 0 1 0-2.3 2.3l8.2 8.19c.64.62 1.66.62 2.3 0l8.2-8.2a1.64 1.64 0 1 0-2.3-2.3l-7.05 7.02-7.05-7.05v.04z'/%3E%3C/svg%3E");
  border-color: #d71d31; }

.select[disabled] {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 56 56'%3E%3Cpath fill='%23fff' d='M0 0h56v56H0z'/%3E%3Cpath fill='%23868686' d='M20.85 22.56a1.64 1.64 0 1 0-2.3 2.3l8.2 8.19c.64.62 1.66.62 2.3 0l8.2-8.2a1.64 1.64 0 1 0-2.3-2.3l-7.05 7.02-7.05-7.05v.04z'/%3E%3C/svg%3E");
  color: #868686;
  cursor: not-allowed; }

.download-list__list {
  list-style: none;
  margin: 0;
  padding-left: 0;
  display: flex;
  flex-wrap: wrap; }

.download-list__item {
  flex-basis: 100%; }
  @media (min-width: 48em) {
    .download-list__item:nth-child(odd) {
      flex-basis: 50%;
      padding-right: 3.2rem; } }
  @media (min-width: 48em) {
    .download-list__item:nth-child(even) {
      flex-basis: calc(50% - 3.2rem); } }
  @media (max-width: 47.99em) {
    .download-list__item:not(:last-child) {
      padding-bottom: 1.6rem; } }
  @media (min-width: 48em) {
    .download-list__item:not(:nth-last-child(-n+2)) {
      padding-bottom: 3.2rem; } }

.download-list__link {
  align-items: center;
  background-color: #fff;
  box-shadow: 0 2px 15px 0 rgba(0, 0, 0, 0.08);
  display: flex;
  min-height: 7.5rem;
  padding: 1rem 2rem;
  position: relative; }
  .download-list__link::after {
    background-color: #0c55a6;
    content: '';
    height: 100%;
    position: absolute;
    right: 0;
    top: 0;
    width: 4px; }
  @media (min-width: 48em) {
    .download-list__link {
      padding: 1rem 2.4rem; } }

.download-list__icon {
  color: #0c55a6;
  font-size: 2rem;
  margin-right: 1.7rem; }
  @media (min-width: 48em) {
    .download-list__icon {
      font-size: 2.4rem; } }

.download-list__text {
  line-height: 1.625; }
  .download-list__link:hover .download-list__text {
    text-decoration: underline; }

.download-list__button {
  margin-top: 3.2rem; }
  @media (max-width: 47.99em) {
    .download-list__button {
      text-align: center; } }

.iframe__content {
  border: 0 solid;
  min-height: 500px;
  width: 100%; }

.iframe__text {
  margin-bottom: 2rem; }

.more-teaser {
  background: linear-gradient(127deg, #0184ca 0%, #094381 100%);
  display: block;
  height: 0;
  padding-bottom: 56.15%;
  position: relative;
  transition-property: background; }
  .more-teaser::before {
    content: '';
    height: 100%;
    left: 0;
    opacity: 0;
    position: absolute;
    top: 0;
    transition-property: opacity;
    width: 100%; }
  .more-teaser:hover::before, .more-teaser:focus::before {
    background: linear-gradient(127deg, #0184ca 0%, #094381 44%);
    opacity: 1; }

.more-teaser__link {
  display: flex;
  flex-direction: column;
  font-size: 1.2rem;
  height: 100%;
  left: 0;
  line-height: 1.25;
  padding: 1.5rem;
  position: absolute;
  text-transform: uppercase;
  top: 0;
  width: 100%; }
  .more-teaser__link:hover, .more-teaser__link:focus {
    text-decoration: underline; }
  @media (min-width: 48em) {
    .more-teaser__link {
      font-size: 1.6rem;
      line-height: 1.75; } }

.more-teaser__icon {
  font-size: 1.5rem;
  margin-top: 1rem;
  transition-property: margin-left; }
  .more-teaser__link:hover .more-teaser__icon,
  .more-teaser__link:focus .more-teaser__icon {
    margin-left: 1rem; }
  @media (min-width: 48em) {
    .more-teaser__icon {
      font-size: 2.4rem;
      margin-top: 1.5rem; } }

.inline-icon {
  color: #0c55a6;
  font-size: 3rem; }
  .inline-icon .icon {
    display: block; }
  @media (min-width: 48em) {
    .inline-icon {
      font-size: 4.8rem; } }

.price-list__label {
  margin-bottom: 1rem; }

.price-list__group {
  align-items: end;
  color: #868686;
  display: grid;
  grid-gap: 2rem;
  grid-template-columns: 1fr 1fr;
  line-height: 1.5;
  margin-bottom: 0.5rem; }

.price-list__group--total {
  color: #0e0e0e; }

.price-list__group--total .price-list__type {
  font-weight: bold; }

.price-list__amount {
  margin-left: 0; }
  .price-list__group--total .price-list__amount {
    font-size: 2.8rem;
    margin-bottom: -0.5rem; }

.teaser-link {
  background-color: #fff;
  box-shadow: 0 2px 20px rgba(0, 0, 0, 0.1);
  display: block;
  font-weight: bold;
  height: 100%;
  overflow: hidden;
  padding: 3rem 2rem;
  position: relative;
  transition-property: box-shadow; }
  .teaser-link:hover, .teaser-link:focus {
    box-shadow: 0 2px 20px rgba(0, 0, 0, 0.2); }

.teaser-link__kicker {
  color: #868686;
  display: block;
  font-size: 1.2rem;
  margin-bottom: 1.5rem;
  text-transform: uppercase; }

.teaser-link__text {
  color: #0c55a6;
  font-size: 1.6rem;
  line-height: 1.625; }
  .teaser-link:hover .teaser-link__text,
  .teaser-link:focus .teaser-link__text {
    text-decoration: underline; }

.teaser-link__icon {
  bottom: -0.4rem;
  color: #094381;
  font-size: 10rem;
  opacity: 0.2;
  position: absolute;
  right: 1rem; }

.tag-badge {
  font-family: "Lato", "Lucida Grande", Tahoma, "Helvetica", "Arial", sans-serif;
  align-items: center;
  background-color: #e2e2e2;
  border-radius: 1.2rem;
  color: #0e0e0e;
  display: inline-flex;
  font-size: 1.4rem;
  height: 1.7em;
  justify-content: center;
  padding: 0.05em 0.71em 0.143em;
  position: relative;
  user-select: none;
  white-space: nowrap; }

/**
 * Molecules
 */
.text {
  font-size: 1.6rem;
  line-height: 1.625; }
  .text > *:not(:last-child) {
    margin-bottom: 2.8rem; }
  .text > ul,
  .text > ul > li > ul,
  .text > ol,
  .text > ol > li > ol {
    overflow: hidden;
    padding-left: 2.4rem; }
  .text > ul > li > ul,
  .text > ol > li > ol {
    margin-top: 1rem; }
  .text > ul,
  .text > ul > li > ul {
    list-style-type: none; }
  .text > ol,
  .text > ol > li > ol {
    counter-reset: step-counter;
    list-style: none; }
  .text > ul li,
  .text > ol li {
    position: relative; }
    .text > ul li:not(:last-child),
    .text > ol li:not(:last-child) {
      margin-bottom: 1rem; }
  .text > ol li {
    counter-increment: step-counter; }
  .text > ol li::before {
    content: counter(step-counter) ".";
    position: absolute;
    transform: translateX(-2rem); }
  .text > ul li::before {
    background-color: #0c55a6;
    border-radius: 50%;
    content: '';
    display: block;
    height: 0.8rem;
    position: absolute;
    transform: translate(-2rem, 0.9rem);
    width: 0.8rem; }

.text--small {
  font-size: 1.3rem; }

@media (min-width: 48em) {
  .text--large {
    font-size: 2rem;
    line-height: 1.5; } }

.figure {
  position: relative; }

.figure--expandable:not(.figure--expanded) .figure__media {
  max-height: 22rem;
  overflow: hidden; }
  .figure--expandable:not(.figure--expanded) .figure__media::after {
    background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, white 100%);
    bottom: 0;
    content: '';
    height: 15rem;
    position: absolute;
    width: 100%;
    z-index: 1; }
  @media (min-width: 48em) {
    .figure--expandable:not(.figure--expanded) .figure__media {
      max-height: 27.5rem; } }

.figure__media {
  display: block;
  position: relative; }

.figure__caption {
  font-size: 1.2rem;
  letter-spacing: 0.5px;
  line-height: 1.3;
  margin-top: 0.8rem; }
  @media (min-width: 48em) {
    .figure__caption {
      text-align: right; } }

.figure__caption--inside {
  background-color: rgba(0, 0, 0, 0.65);
  bottom: 0;
  color: #fff;
  margin-top: 0;
  padding: 0.5rem 1rem;
  position: absolute;
  right: 0; }

.figure__copyright {
  color: #535353; }
  .figure__caption--inside .figure__copyright {
    color: #fff; }

.figure__copyright-icon {
  margin-right: 0.5rem;
  vertical-align: baseline; }

.figure__copyright + .figure__caption-text {
  margin-top: 0.25rem; }

.figure__media--video {
  padding-top: 56.25%;
  position: relative; }
  .figure__media--video .figure__iframe-wrapper {
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%; }
  .figure__media--video .figure__iframe-video {
    border: 0;
    height: 100%;
    width: 100%; }

.figure__media--soundcloud {
  position: relative; }
  .figure__media--soundcloud .figure__iframe-wrapper {
    height: 100%;
    width: 100%; }
  .figure__media--soundcloud .figure__iframe-audio {
    border: 0;
    height: 100%;
    width: 100%; }

.figure__expand-button {
  color: #0c55a6;
  display: flex;
  font-weight: bold;
  justify-content: center;
  margin: 2rem auto 0;
  user-select: none;
  width: 100%; }
  .figure__expand-button:hover {
    text-decoration: underline; }

.figure__expand-button-icon {
  margin-right: 1rem; }
  .figure--expanded .figure__expand-button-icon {
    transform: rotate(180deg); }

.form-group {
  border: 0;
  flex-shrink: 0;
  padding: 0;
  width: 100%; }

.form-group__label {
  margin-bottom: 1rem; }

.form-group__description,
.form-group__error {
  font-size: 1.2rem;
  line-height: 1.3;
  margin-top: 0.8rem; }

.form-group__description {
  color: #535353; }
  .t-inverted .form-group__description {
    color: #fff; }

.form-group__error {
  color: #d71d31; }

.form-group__item {
  margin-top: 0.5rem; }
  .form-group__item + .form-group__item {
    margin-top: 1rem; }
  .form-group__item + .form-group__description,
  .form-group__item + .form-group__error {
    margin-top: 1.5rem; }

.accordion__item {
  border-bottom: 1px solid #e2e2e2; }
  .accordion__item:first-child {
    border-top: 1px solid #e2e2e2; }

.accordion__toggle {
  display: flex;
  justify-content: space-between;
  padding: 1.25rem;
  position: relative; }
  .accordion__toggle::-webkit-details-marker {
    display: none; }
  .accordion__toggle:hover, .accordion__toggle:focus {
    cursor: pointer;
    text-decoration: underline; }

.accordion__title {
  font-weight: bold;
  line-height: 1.3;
  padding-right: 3rem; }

.accordion__icon {
  flex-shrink: 0;
  font-size: 1.2rem;
  position: absolute;
  right: 1.5rem;
  top: 1.75rem;
  transition-property: transform; }
  .accordion__item[open] .accordion__icon {
    transform: rotate(180deg); }

.accordion__content {
  padding: 1.5rem 1.25rem; }

.tag-link-list::-webkit-scrollbar {
  display: none; }

@media (max-width: 47.99em) {
  .tag-link-list--large {
    -ms-overflow-style: none;
    overflow-x: auto;
    overflow-y: hidden;
    scrollbar-width: none; } }

.tag-link-list__list {
  list-style: none;
  margin: 0;
  padding-left: 0;
  display: flex;
  flex-wrap: wrap; }
  .tag-link-list--large .tag-link-list__list {
    flex-wrap: nowrap; }
    @media (min-width: 48em) {
      .tag-link-list--large .tag-link-list__list {
        flex-wrap: wrap; } }

.tag-link-list__item {
  line-height: 1.625; }
  .tag-link-list__item:not(:last-child) {
    margin-right: 2.1rem; }
    @media (min-width: 48em) {
      .tag-link-list__item:not(:last-child) {
        margin-right: 2.5rem; } }
    .tag-link-list--large .tag-link-list__item:not(:last-child) {
      margin-right: 1.5rem; }
      @media (min-width: 48em) {
        .tag-link-list--large .tag-link-list__item:not(:last-child) {
          margin-right: 6.5rem; } }
    @media (min-width: 48em) {
      .tag-link-list--serif .tag-link-list__item:not(:last-child) {
        margin-right: 4rem; } }

.teaser {
  background-color: #fff;
  position: relative; }
  @media (min-width: 48em) {
    .featured-breaker .teaser {
      padding: 3.5rem; } }

.teaser--inverted {
  background-image: url("..//images/background/breaker-dark.svg");
  background-position: 20% 100%;
  background-size: cover;
  padding-bottom: 2.4rem;
  padding-right: 2.4rem;
  padding-top: 2.4rem; }
  @media (min-width: 48em) {
    .teaser--inverted {
      background-position: center; } }
  .teaser--inverted .kicker,
  .teaser--inverted .text {
    color: #fff; }
  @media (max-width: 47.99em) {
    .teaser--inverted {
      padding-left: 2.4rem; } }

.teaser--horizontal {
  display: flex; }
  @media (max-width: 47.99em) {
    .teaser--horizontal {
      flex-direction: column; } }

.teaser__image {
  margin-bottom: 1.5rem;
  position: relative; }
  @media (max-width: 47.99em) {
    .teaser--horizontal .teaser__image {
      width: 23rem; } }
  @media (min-width: 48em) {
    .teaser--horizontal .teaser__image {
      flex-basis: 23rem;
      flex-shrink: 0;
      margin-bottom: 0;
      margin-right: 3.2rem; } }
  .teaser--horizontal:not(.teaser--large-image) .teaser__image:not(.teaser__image--portrait) {
    max-height: 13rem;
    overflow: hidden; }
  @media (max-width: 47.99em) {
    .teaser--large-image .teaser__image {
      width: 100%; } }
  @media (min-width: 48em) {
    .teaser--large-image .teaser__image {
      flex-basis: 32rem; } }
  @media (min-width: 75em) {
    .teaser--large-image .teaser__image {
      flex-basis: 50rem; } }

.teaser__image--portrait .image {
  width: 14.5rem; }

@media (min-width: 48em) {
  .teaser__image--portrait {
    display: flex;
    justify-content: center; } }

.teaser__image-icon {
  fill: #fff;
  height: 4rem;
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 4rem; }

@media (min-width: 48em) {
  .teaser--horizontal .teaser__inner {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding-bottom: 1rem;
    padding-top: 1rem; } }

.teaser__kicker {
  margin-bottom: 1rem; }

.teaser:not(.teaser--horizontal) .teaser__headline {
  hyphens: auto; }

@media (max-width: 47.99em) {
  .teaser--horizontal .teaser__headline {
    hyphens: auto; } }

@media (min-width: 48em) {
  .teaser:not(.teaser--horizontal) .teaser__headline .headline {
    font-size: 1.6rem; } }

.teaser:hover .teaser__headline .headline__link {
  text-decoration: underline; }

.teaser__headline .headline__link::before {
  content: '';
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%; }

.teaser__headline--dash {
  padding-bottom: 2rem;
  position: relative; }
  .teaser__headline--dash::after {
    background-color: #0e0e0e;
    bottom: 1rem;
    content: '';
    display: block;
    height: 1px;
    left: 0;
    position: absolute;
    width: 5.5rem; }
    @media (min-width: 48em) {
      .teaser__headline--dash::after {
        bottom: 1.5rem; } }
  @media (min-width: 48em) {
    .teaser__headline--dash {
      padding-bottom: 3rem; } }

.teaser__text {
  color: #535353;
  margin-top: 1rem; }
  .teaser__headline--dash + .teaser__text {
    margin-top: 0; }

.teaser__tags {
  margin-top: 2rem; }

.teaser__box-list {
  margin-bottom: -0.8rem;
  margin-top: 2rem; }
  .teaser__box-list .box-list__link {
    position: relative;
    z-index: 1; }
  @media (min-width: 48em) {
    .teaser--horizontal .teaser__box-list {
      margin-top: 3rem; } }

.teaser__person-name,
.teaser__person-email {
  margin-bottom: 1.25rem; }

.teaser__person-role {
  color: #868686;
  margin-bottom: 3rem; }

.teaser__morelink {
  margin-top: 2.5rem; }

.icon-link-list {
  list-style: none;
  margin: 0;
  padding-left: 0; }

.icon-link-list__item:not(:last-child) {
  margin-bottom: 2rem; }

.infobox {
  background-color: #fff;
  box-shadow: 0 2px 15px 0 rgba(0, 0, 0, 0.08);
  color: #535353;
  padding: 2.4rem; }
  @media (min-width: 48em) {
    .infobox {
      padding: 3.5rem; } }

.infobox--centered {
  text-align: center; }

.t-cond .infobox--black {
  background-color: #0e0e0e;
  box-shadow: unset;
  color: #fff; }

.t-cond .infobox--border {
  background-color: #fff;
  border: 1px solid #0e0e0e;
  box-shadow: unset;
  color: #0e0e0e; }

.image-teaser {
  position: relative; }
  @media (min-width: 48em) {
    .image-teaser::after {
      background-image: linear-gradient(180deg, rgba(14, 14, 14, 0) 2%, rgba(14, 14, 14, 0.5) 100%);
      content: '';
      height: 100%;
      left: 0;
      position: absolute;
      top: 0;
      width: 100%;
      z-index: 1; } }

@media (max-width: 47.99em) {
  .image-teaser__image {
    margin-bottom: 1.5rem; } }

@media (min-width: 48em) {
  .image-teaser__inner {
    bottom: 9rem;
    left: 0;
    padding: 0 1.6rem;
    position: absolute;
    z-index: 2; } }

.image-teaser .kicker {
  margin-bottom: 1rem; }
  @media (min-width: 48em) {
    .image-teaser .kicker {
      color: #fff; } }

.image-teaser:hover .image-teaser__headline {
  text-decoration: underline; }

@media (min-width: 48em) {
  .image-teaser__headline {
    color: #fff; } }

.box-teaser {
  position: relative; }
  @media (min-width: 75em) {
    .box-teaser {
      margin: -1.5rem 0;
      overflow: hidden; }
      .slider .box-teaser {
        margin: 0; } }

@media (min-width: 48em) {
  .box-teaser__inner {
    background-color: #fff;
    box-shadow: 0 2px 15px 0 rgba(0, 0, 0, 0.08);
    column-gap: 4rem;
    display: grid;
    grid-template: 'bt-1 bt-2' auto 'bt-3 bt-2' auto / 55fr 45fr;
    margin: 1.5rem;
    padding: 3.5rem; }
    .detail-content__block--large .box-teaser__inner {
      margin-left: 0;
      margin-right: 0; } }

.box-teaser__inner--right {
  grid-template: 'bt-1 bt-2' auto 'bt-1 bt-3' auto / 45fr 55fr; }
  @media (min-width: 48em) {
    .box-teaser__inner--right .box-teaser__text {
      grid-area: bt-2; } }
  @media (min-width: 48em) {
    .box-teaser__inner--right .box-teaser__image {
      grid-area: bt-1; } }
  @media (min-width: 48em) {
    .box-teaser__inner--right .box-teaser__button {
      grid-area: bt-3; } }

@media (min-width: 48em) {
  .box-teaser__text {
    grid-area: bt-1; } }

.box-teaser__kicker {
  margin-bottom: 1rem; }
  @media (min-width: 48em) {
    .box-teaser__kicker {
      margin-bottom: 2rem; } }

.box-teaser:hover .box-teaser__headline .headline {
  text-decoration: underline; }

@media (min-width: 48em) {
  .box-teaser__headline .headline {
    font-size: 3rem; } }

@media (max-width: 47.99em) {
  .box-teaser__image {
    margin-top: 1.5rem; } }

@media (min-width: 48em) {
  .box-teaser__image {
    grid-area: bt-2; } }

@media (max-width: 47.99em) {
  .box-teaser__button {
    margin-top: 2.5rem;
    text-align: center; } }

@media (min-width: 48em) {
  .box-teaser__button {
    align-items: flex-end;
    display: flex;
    grid-area: bt-3;
    margin-top: 3rem; } }

.box-teaser__link::before {
  content: '';
  display: block;
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%; }

.person-box {
  text-align: center; }
  .person-box .person-box__image {
    margin-bottom: 1.5rem; }
  .person-box .person-box__name {
    font-size: 1.6rem;
    font-weight: bold;
    margin-bottom: 1.5rem; }
  .person-box .person-box__role {
    color: #868686;
    font-size: 1.6rem;
    line-height: 1.4; }

.person-box--dialog {
  cursor: pointer; }
  .person-box--dialog:hover .person-box__name,
  .person-box--dialog:focus .person-box__name {
    text-decoration: underline; }

.media-teaser {
  position: relative; }

.media-teaser--large::after {
  background-image: linear-gradient(180deg, rgba(14, 14, 14, 0) 2%, rgba(14, 14, 14, 0.5) 100%);
  content: '';
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 1; }

.media-teaser__media {
  position: relative; }

.media-teaser__image {
  margin-bottom: 1.5rem; }
  .media-teaser--large .media-teaser__image {
    margin-bottom: 0; }

.media-teaser__icon {
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%); }

.media-teaser__icon .icon {
  fill: #fff;
  height: 4rem;
  width: 4rem; }

.media-teaser--large .media-teaser__inner {
  bottom: 1rem;
  left: 0;
  padding: 0 1.6rem;
  position: absolute;
  z-index: 2; }
  @media (min-width: 48em) {
    .media-teaser--large .media-teaser__inner {
      bottom: 3rem; } }

.media-teaser__kicker .kicker {
  margin-bottom: 1rem; }
  .media-teaser--large .media-teaser__kicker .kicker {
    color: #fff;
    margin-bottom: 0.5rem; }
    @media (min-width: 48em) {
      .media-teaser--large .media-teaser__kicker .kicker {
        margin-bottom: 1rem; } }

.media-teaser__headline {
  margin-bottom: 1rem; }
  .media-teaser:hover .media-teaser__headline {
    text-decoration: underline; }
  .media-teaser:not(.media-teaser--large) .media-teaser__headline .headline__link::before {
    content: '';
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%; }
  @media (min-width: 48em) {
    .media-teaser__headline {
      margin-bottom: 2rem; } }

.media-teaser--large .media-teaser__headline {
  color: #fff; }

.media-teaser__duration {
  align-items: center;
  color: #868686;
  display: flex; }
  .media-teaser--large .media-teaser__duration {
    color: #fff; }

.media-teaser__duration .icon {
  margin-right: 8px; }

.box-link-list__list {
  list-style: none;
  margin: 0;
  padding-left: 0; }

.box-link-list__title {
  color: #868686;
  font-size: 1.2rem;
  letter-spacing: 0.5px;
  margin-bottom: 0.9rem;
  text-transform: uppercase; }

.box-link-list__item:not(:last-child) {
  margin-bottom: 0.8rem; }

.download-link-list {
  list-style: none;
  margin: 0;
  padding-left: 0;
  display: flex;
  flex-wrap: wrap; }

.download-link-list__item {
  margin-bottom: 2rem;
  margin-right: 2rem;
  min-width: 124px; }
  .download-link-list__item:last-child {
    margin-right: 0; }
  @media (min-width: 48em) {
    .download-link-list__item {
      margin-right: 4.2rem; } }

.list-filter fieldset {
  border: 0;
  padding: 0; }

.list-filter__title {
  color: #868686;
  font-size: 1.2rem;
  letter-spacing: 0.5px;
  margin-bottom: 2rem;
  text-transform: uppercase; }

.list-filter__links,
.list-filter__hashtags {
  list-style: none;
  margin: 0;
  padding-left: 0; }

.list-filter__link-item:not(:last-child) {
  margin-bottom: 0.3rem; }

.list-filter__link {
  color: #868686;
  display: block;
  font-size: 1.3rem;
  margin-left: -1.5rem;
  padding: 0.6rem 1rem 0.6rem 1.5rem;
  user-select: none; }
  .list-filter__link:hover, .list-filter__link:focus {
    text-decoration: underline; }

.list-filter__link--active {
  background-color: #0c55a6;
  color: #fff;
  cursor: default;
  position: relative;
  text-decoration: none; }
  .list-filter__link--active:hover, .list-filter__link--active:focus {
    text-decoration: none; }
  .list-filter__link--active::before {
    border-bottom: 0.3rem solid transparent;
    border-left: 0.6rem solid #fff;
    border-top: 0.3rem solid transparent;
    content: '';
    display: block;
    height: 0;
    left: -0.5rem;
    margin: 0 1rem 0.2rem;
    position: absolute;
    top: 1rem;
    width: 0; }

.list-filter__hashtag-item:not(:last-child) {
  margin-bottom: 1.5rem; }

.list-filter__hashtag-link {
  color: #0c55a6;
  display: block; }
  .list-filter__hashtag-link:hover, .list-filter__hashtag-link:focus {
    text-decoration: underline; }

.list-filter__checkbox {
  display: block; }
  .list-filter__checkbox:not(:last-child) {
    margin-bottom: 1rem; }

.list-filter__form-group:not(:last-child) {
  margin-bottom: 2rem; }

.list-filter__date-button {
  border: 2px solid #e2e2e2;
  color: #535353;
  font-size: 2rem;
  padding: 0.5rem; }
  .list-filter__date-button:hover, .list-filter__date-button:focus {
    border-color: #0c55a6; }

.list-filter__date-icon {
  display: block; }

.contact-box {
  box-shadow: 0 2px 15px 0 rgba(0, 0, 0, 0.08);
  max-width: 50rem;
  padding: 2.5rem; }
  @media (min-width: 48em) {
    .contact-box {
      padding: 3.5rem; } }

.contact-box__headline {
  margin-bottom: 5rem;
  position: relative; }
  .contact-box__headline::after {
    background-color: #0e0e0e;
    bottom: -1.5rem;
    content: '';
    display: block;
    height: 1px;
    left: 0;
    position: absolute;
    width: 5rem; }

.person-teaser {
  display: flex; }
  @media (max-width: 47.99em) {
    .person-teaser {
      flex-direction: column; } }

.person-teaser__image {
  width: 23rem; }
  @media (max-width: 47.99em) {
    .person-teaser__image {
      margin-bottom: 1.5rem; } }
  @media (min-width: 48em) {
    .person-teaser__image {
      flex-basis: 23rem;
      flex-shrink: 0;
      margin-right: 3.2rem; } }

@media (min-width: 48em) {
  .person-teaser__content {
    flex-grow: 1;
    padding-bottom: 1rem;
    padding-top: 1rem; } }

.person-teaser__kicker {
  margin-bottom: 1rem; }

.person-teaser__button {
  display: block;
  margin-bottom: 2rem;
  text-align: left;
  width: 100%; }
  .person-teaser__button:hover, .person-teaser__button:focus {
    text-decoration: underline; }

.quote-box {
  box-shadow: 0 2px 15px 0 rgba(0, 0, 0, 0.08);
  margin: 1.5rem 0;
  padding: 2.5rem 0;
  position: relative; }
  @media (min-width: 48em) {
    .quote-box {
      display: flex;
      padding: 3.5rem 0; } }

@media (max-width: 47.99em) {
  .quote-box__image {
    margin-bottom: 2rem; } }

@media (min-width: 48em) {
  .quote-box__image {
    flex-basis: 50%;
    padding-right: 3rem; } }

@media (min-width: 48em) {
  .quote-box__content {
    display: flex;
    flex-basis: 50%;
    flex-direction: column;
    justify-content: space-between; } }

.quote-box__text {
  font-size: 1.8rem;
  line-height: 1.4; }
  @media (min-width: 48em) {
    .quote-box__text {
      font-size: 2rem; } }

.quote-box__text p {
  quotes: '»' '«'; }
  .quote-box__text p::before {
    content: open-quote; }
  .quote-box__text p::after {
    content: close-quote; }

.quote-box__source {
  margin-top: 2rem; }
  @media (min-width: 48em) {
    .quote-box__source {
      margin-top: 3rem; } }

.quote-box__name {
  font-family: "Merriweather", "Georgia", serif;
  display: block;
  font-weight: bold; }
  .quote-box:hover .quote-box__name:any-link {
    text-decoration: underline; }
  .quote-box__name:any-link::before {
    content: '';
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%; }

.quote-box__role {
  color: #868686;
  margin-top: 1rem; }

.twitter-timeline {
  background-color: #fff;
  height: 50rem;
  overflow-y: scroll; }
  @media (min-width: 48em) {
    .twitter-timeline {
      height: 40rem; } }

.twitter-timeline__inner {
  padding-right: 2.5rem;
  position: relative; }
  @media (min-width: 48em) {
    .twitter-timeline__inner {
      padding-left: 3.5rem;
      padding-right: 3.5rem; } }

.twitter-timeline__banner {
  background-color: #fff;
  height: 5.5rem;
  left: 0;
  padding-top: 1.5rem;
  position: sticky;
  top: 0;
  width: 100%;
  z-index: 1; }
  .twitter-timeline__banner::after {
    background-image: linear-gradient(#fff 0, transparent 100%);
    bottom: -1.5rem;
    content: '';
    display: block;
    height: 1.5rem;
    left: 0;
    position: absolute;
    width: 100%; }

.twitter-timeline__banner-link {
  align-items: center;
  display: grid;
  grid-gap: 2rem;
  grid-template-columns: 30px 1fr; }
  .twitter-timeline__banner-link:hover {
    text-decoration: underline; }

.twitter-timeline__banner-icon {
  color: #000;
  font-size: 3rem; }

.twitter-timeline__banner-text {
  font-size: 1.6rem;
  font-weight: bold; }

.twitter-timeline__list {
  padding-bottom: 2.5rem;
  padding-top: 1.5rem; }
  @media (min-width: 48em) {
    .twitter-timeline__list {
      padding-bottom: 3.5rem; } }

.twitter-timeline__item:not(:last-child) {
  border-bottom: 1px solid #e2e2e2;
  margin-bottom: 1.5rem;
  padding-bottom: 2rem; }

.twitter-timeline__item-date {
  color: #535353;
  font-size: 1.2rem;
  margin-top: 0.5rem;
  text-align: right; }

@media (min-width: 48em) {
  .box-columns {
    align-items: flex-start;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between; }
    .detail-content .box-columns {
      margin-bottom: -3.2rem; } }

.box-columns__column {
  box-shadow: 0 2px 15px 0 rgba(0, 0, 0, 0.08);
  padding: 2rem; }
  @media (max-width: 47.99em) {
    .box-columns__column:not(:last-child) {
      margin-bottom: 2.4rem; } }
  @media (min-width: 48em) {
    .box-columns__column:not(:nth-child(3n)) {
      margin-right: 3%; } }
  @media (min-width: 48em) {
    .box-columns__column {
      flex-basis: 31%;
      flex-shrink: 0;
      margin-bottom: 3.2rem; } }

.box-columns__text {
  color: #535353;
  hyphens: auto; }
  .box-columns__text .headline {
    color: #0e0e0e; }

.box-columns__more-link {
  margin-top: 2.4rem; }

.social-teaser__inner {
  background-image: url("..//images/background/breaker-light.svg");
  background-position: 20% 100%;
  background-size: cover;
  padding: 2.4rem;
  text-align: center; }
  @media (min-width: 48em) {
    .social-teaser__inner {
      background-position: center; } }
  @media (min-width: 75em) {
    .social-teaser--horizontal .social-teaser__inner {
      display: flex;
      justify-content: space-between;
      text-align: left; } }
  @media (min-width: 48em) {
    .social-teaser__inner {
      padding: 4rem; } }

.social-teaser__icon {
  font-size: 5rem;
  margin-bottom: 2.4rem; }
  @media (min-width: 75em) {
    .social-teaser--horizontal .social-teaser__icon {
      align-self: center;
      font-size: 8rem;
      margin-bottom: 0;
      margin-right: 5rem; } }

.social-teaser__icon--facebook {
  color: #1778f2; }

.social-teaser__icon--twitter {
  color: #000; }

.social-teaser__icon--instagram {
  color: #e51c3c; }

.social-teaser__text {
  margin-top: 2rem; }

.social-teaser__button {
  margin-top: 2.4rem; }
  @media (min-width: 75em) {
    .social-teaser--horizontal .social-teaser__button {
      align-self: flex-end;
      flex-shrink: 0;
      margin-left: 5rem;
      margin-top: 0; } }

.text-quote__inner {
  display: grid;
  gap: 7.2rem 8.8rem;
  position: relative; }
  .text-quote__inner::after {
    background-color: #0e0e0e;
    content: '';
    height: 1px;
    left: 0;
    position: absolute;
    top: 50%;
    transform: translateY(-1.2rem);
    width: 100%; }
  @media (min-width: 48em) {
    .text-quote__inner {
      gap: 8.8rem;
      grid-template-columns: repeat(2, 1fr); }
      .text-quote__inner::after {
        background-color: #0e0e0e;
        content: '';
        height: 95%;
        left: 50%;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        width: 1px; } }

.text-quote__quote-text p {
  font-family: "Merriweather", "Georgia", serif;
  font-size: 2rem;
  line-height: 3rem;
  quotes: '„' '“'; }
  .text-quote__quote-text p::before {
    content: open-quote; }
  .text-quote__quote-text p::after {
    content: close-quote; }

.text-quote__source {
  margin-top: 2rem; }
  @media (min-width: 48em) {
    .text-quote__source {
      margin-top: 3rem; } }

.text-quote__name {
  display: block; }
  .text-quote:hover .text-quote__name:any-link {
    text-decoration: underline; }
  .text-quote__name:any-link::before {
    content: '';
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%; }

.text-quote__role {
  color: #868686;
  margin-top: 1rem; }

.box-column__inner {
  box-shadow: 0 2px 15px 0 rgba(0, 0, 0, 0.08);
  padding: 2rem; }

.box-column__image,
.box-column__icon {
  margin-bottom: 2.4rem; }

.box-column__icon {
  font-size: 4.8rem; }

.box-column__headline {
  margin-bottom: 1rem; }
  .box-column__headline .headline {
    font-family: "Lato", "Lucida Grande", Tahoma, "Helvetica", "Arial", sans-serif;
    font-weight: normal; }

.box-column__text {
  color: #535353;
  hyphens: auto; }
  .box-column__text .headline {
    color: #0e0e0e; }

.box-column__more-link {
  margin-top: 2.4rem; }

.blog-card {
  box-shadow: 0 0 5vw 0 rgba(0, 0, 0, 0.25);
  display: flex;
  flex-direction: column;
  position: relative;
  transition: transform 0.3s ease;
  width: 100%;
  z-index: 1; }
  .blog-card:hover, .blog-card:focus {
    transform: scale(1.05);
    z-index: 2; }

.blog-card__img {
  aspect-ratio: 1/1;
  display: inline-block;
  object-fit: cover;
  position: relative;
  width: 100%; }
  .blog-card__img img {
    aspect-ratio: 1/1;
    object-fit: cover; }

.blog-card__content {
  background-color: #fff;
  display: inline-flex;
  flex-direction: column;
  flex-grow: 1;
  padding: 4rem;
  position: relative;
  width: calc(100% - 8rem); }

.blog-card__header {
  align-items: center;
  display: inline-flex;
  flex-wrap: wrap;
  gap: 1.7rem;
  position: relative; }

.blog-card__date {
  font-family: "Lato", "Lucida Grande", Tahoma, "Helvetica", "Arial", sans-serif;
  color: #868686;
  font-size: 1.2rem;
  font-weight: 600; }

.blog-card__title {
  font-family: "MerriweatherLight", "Georgia", serif;
  display: inline-block;
  font-size: 2rem;
  font-weight: bold;
  line-height: 1.5em;
  margin-top: 3rem;
  position: relative; }

.expert-card {
  font-family: "Lato", "Lucida Grande", Tahoma, "Helvetica", "Arial", sans-serif;
  align-items: flex-start;
  color: #0e0e0e;
  display: inline-flex;
  flex-direction: column;
  list-style: none;
  position: relative;
  user-select: none;
  width: 100%; }

.expert-card__img {
  aspect-ratio: 240/253;
  display: block;
  object-fit: cover;
  position: relative;
  width: 100%; }
  .expert-card__img img {
    aspect-ratio: 240/253;
    height: 100%;
    object-fit: cover; }

.expert-card__name {
  font-family: "MerriweatherLight", "Georgia", serif;
  display: block;
  font-size: 2rem;
  font-weight: 600;
  line-height: 1.5em;
  margin-top: 3rem;
  position: relative; }

.expert-card__job-position {
  display: block;
  flex-grow: 1;
  font-size: 1.8rem;
  line-height: 1.2em;
  margin-top: 0.8rem;
  position: relative; }

.expert-card__link {
  border-bottom: 0.125em solid currentColor;
  color: #0c55a6;
  display: inline-block;
  font-size: 1.6rem;
  font-weight: bold;
  letter-spacing: 0.1rem;
  line-height: 1.625em;
  margin-top: 3rem;
  position: relative;
  text-transform: uppercase;
  transition: transform 0.3s ease;
  width: auto; }
  .expert-card__link:hover, .expert-card__link:focus,
  .expert-card:hover .expert-card__link {
    transform: scale(1.05); }

.image-link-tile {
  font-family: "MerriweatherLight", "Georgia", serif;
  aspect-ratio: 334/555;
  color: #fff;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  font-size: 2.4rem;
  font-weight: 600;
  height: auto;
  justify-content: flex-end;
  line-height: 1.3em;
  overflow: hidden;
  padding: 4rem;
  position: relative;
  user-select: none; }
  .image-link-tile::before {
    background: linear-gradient(rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0) 50%, black 100%);
    content: '';
    display: block;
    height: 100%;
    left: 0;
    pointer-events: none;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 10; }
  @media (min-width: 87.5em) {
    .image-link-tile {
      font-size: 3rem; } }
  @media (min-width: 75em) {
    .image-link-tile {
      aspect-ratio: 449/555; } }

.image-link-tile__img {
  display: block;
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  transition: transform 0.3s ease;
  width: 100%; }
  .image-link-tile__img img {
    height: 100%;
    object-fit: cover; }
  .image-link-tile:hover .image-link-tile__img,
  .image-link-tile:focus .image-link-tile__img {
    transform: scale(1.1); }

.image-link-tile__title {
  display: block;
  position: relative;
  z-index: 20; }

.indicator-control {
  display: inline-flex;
  gap: 2.4rem;
  position: relative; }

.indicator-control__indicators {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  gap: 0.6rem;
  list-style: none;
  padding: 0;
  position: relative; }

.indicator-control__indicator {
  cursor: pointer;
  display: block;
  font-size: 0;
  height: 2rem;
  width: 1.2rem; }

.indicator-control__indicator span {
  background-color: #0c55a6;
  border-radius: 0.1rem;
  display: block;
  height: 0.2rem;
  opacity: 0.3;
  position: relative;
  transition: border-radius 0.3s ease, height 0.3s ease, opacity 0.3s ease;
  width: 100%; }

.indicator-control__indicator--active {
  pointer-events: none; }

.indicator-control__indicator--active span {
  border-radius: 0.2rem;
  height: 0.4rem;
  opacity: 1; }

.indicator-control__prev,
.indicator-control__next {
  appearance: none;
  background: transparent;
  border: 0;
  color: #0c55a6;
  display: block;
  position: relative;
  transition: transform 0.3s ease; }

.indicator-control__prev::before,
.indicator-control__next::before {
  background-color: currentColor;
  content: '';
  display: block;
  height: 2.4rem;
  mask: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3E%3Cpath d='M20.6 12.9q.4-.25.4-.9 0-.4-.3-.7l-6-6q-.3-.25-.7-.25-.45 0-.75.25-.25.3-.25.75 0 .4.25.7l4.3 4.25H4q-1 0-1 1t1 1h13.55l-4.3 4.3q-.25.3-.25.75 0 .4.25.7.3.25.75.25.4 0 .7-.25l5.9-5.85Z'/%3E%3C/svg%3E");
  mask-repeat: no-repeat;
  position: relative;
  transition: opacity 0.3s ease;
  width: 2.4rem; }

@media (hover: hover) {
  .indicator-control__prev:hover,
  .indicator-control__next:hover {
    transform: scale(1.1); } }

.indicator-control__prev:disabled,
.indicator-control__next:disabled {
  pointer-events: none; }

.indicator-control__prev:disabled::before,
.indicator-control__next:disabled::before {
  opacity: 0.25; }

.indicator-control__prev::before {
  transform: scale(-1, 1); }

.indicator-control--hidden {
  display: none !important; }

.news-teaser {
  font-family: "Lato", "Lucida Grande", Tahoma, "Helvetica", "Arial", sans-serif;
  display: inline-flex;
  flex-direction: column;
  gap: 3rem;
  position: relative;
  width: 100%; }

.news-teaser__img,
.news-teaser__figure {
  aspect-ratio: 32/22;
  display: inline-block;
  height: auto;
  object-fit: cover;
  position: relative;
  width: 100%; }

.news-teaser__img img,
.news-teaser__figure img {
  height: 100%;
  object-fit: cover; }

.news-teaser__content {
  display: inline-block;
  position: relative;
  width: 100%; }

.news-teaser__content-head {
  align-items: flex-start;
  display: inline-flex;
  gap: 2.3rem;
  margin-bottom: 2rem;
  position: relative;
  width: 100%; }

.news-teaser__date {
  align-items: center;
  color: #868686;
  display: inline-flex;
  font-size: 1.2rem;
  font-weight: 600;
  height: 2.4rem;
  position: relative; }

.news-teaser__headline {
  font-family: "MerriweatherLight", "Georgia", serif;
  font-size: 2rem;
  line-height: 1.5em; }

.news-teaser__tag-badges {
  display: none;
  flex-wrap: wrap;
  gap: 2.3rem;
  position: relative; }

.news-teaser__text {
  display: none;
  font-size: 1.8rem;
  line-height: 1.5em;
  margin-top: 1.6rem;
  position: relative;
  width: 100%; }

.news-teaser__hashtag {
  box-shadow: inset 0 -1px transparent;
  color: #0c55a6;
  font-weight: 700;
  text-decoration: none;
  text-transform: uppercase; }

.news-teaser__hashtags {
  display: inline-flex;
  flex-wrap: wrap;
  font-size: 1.6rem;
  gap: 1em;
  margin-top: 3rem;
  position: relative; }

.news-teaser__hashtag[href] {
  transition: box-shadow 0.3s ease; }
  .news-teaser__hashtag[href]:hover, .news-teaser__hashtag[href]:focus {
    box-shadow: inset 0 -1px currentColor; }

.news-teaser--boxed {
  align-items: center;
  box-shadow: 0 0 5vw 0 rgba(0, 0, 0, 0.25);
  flex-direction: row;
  gap: 0;
  max-width: 102.4rem;
  overflow: hidden; }

.news-teaser--boxed .news-teaser__img,
.news-teaser--boxed .news-teaser__figure {
  aspect-ratio: 51.2/35.6;
  display: none; }

.news-teaser--boxed .news-teaser__img img,
.news-teaser--boxed .news-teaser__figure img {
  aspect-ratio: 51.2/35.6; }

.news-teaser--boxed .news-teaser__content {
  padding: 4rem; }

.news-teaser--boxed .news-teaser__content__head {
  justify-content: space-between;
  margin-bottom: 3rem; }

.news-teaser--boxed .news-teaser__headline {
  font-size: 2rem; }

.news-teaser--boxed .news-teaser__tag-badges {
  display: inline-flex; }

.news-teaser--boxed .news-teaser__text {
  display: block;
  margin-top: 3rem; }

.news-teaser--publication .news-teaser__figure {
  background-color: #e2e2e2; }

.news-teaser--publication .news-teaser__img {
  aspect-ratio: 70/100;
  border: 0.5rem solid #fff;
  border-bottom-width: 1rem;
  left: 50%;
  max-height: 90%;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  transform-origin: center center;
  width: 50%; }

.news-teaser--publication .news-teaser__date::before {
  content: attr(data-pre);
  margin-right: 0.3em; }

@media (min-width: 75em) {
  .news-teaser__tag-badges {
    display: inline-flex; }
  .news-teaser__text {
    display: inline-block; }
  .news-teaser--boxed .news-teaser__img,
  .news-teaser--boxed .news-teaser__figure {
    display: block; }
  .news-teaser--boxed .news-teaser__content__head {
    justify-content: flex-start; }
  .news-teaser--featured {
    flex-direction: row; }
    .preview .news-teaser--featured {
      max-width: 102.4rem; }
  .news-teaser--featured .news-teaser__img,
  .news-teaser--featured .news-teaser__figure {
    aspect-ratio: 51.2/40.3;
    object-fit: cover; }
  .news-teaser--featured .news-teaser__img img,
  .news-teaser--featured .news-teaser__figure img {
    aspect-ratio: 51.2/40.3;
    object-fit: cover; }
  .news-teaser--featured .news-teaser__figure {
    max-width: 50%;
    min-width: 50%; }
  .news-teaser--featured .news-teaser__headline {
    font-size: 3rem; }
  .news-teaser--featured.news-teaser--publication .news-teaser__img {
    aspect-ratio: 70/100; } }

.news-teaser .news-teaser__img,
.news-teaser .news-teaser__content {
  transition: transform 0.3s ease; }

.news-teaser:hover .news-teaser__img {
  transform: scale(1.05); }

.news-teaser:hover .news-teaser__content {
  transform: scale(1.025); }

.news-teaser.news-teaser--publication:hover .news-teaser__img {
  transform: translate(-50%, -50%) scale(1.05); }

/**
* Organisms
*/
.footer {
  background-color: #f9f9f9;
  margin-top: 10rem;
  padding-bottom: 1.5rem;
  padding-top: 4rem; }
  @media (min-width: 75em) {
    .footer {
      padding-bottom: 4rem; } }

.footer__inner {
  margin-left: auto;
  margin-right: auto;
  max-width: 100%;
  align-items: center;
  display: flex; }
  @media all {
    .footer__inner {
      padding-left: 2.4rem;
      padding-right: 2.4rem; } }
  @media (min-width: 23.4375em) {
    .footer__inner {
      padding-left: 2.4rem;
      padding-right: 2.4rem; } }
  @media (min-width: 48em) {
    .footer__inner {
      padding-left: 2.4rem;
      padding-right: 2.4rem; } }
  @media (min-width: 75em) {
    .footer__inner {
      padding-left: 2.4rem;
      padding-right: 2.4rem; } }
  @media (min-width: 87.5em) {
    .footer__inner {
      padding-left: 2.4rem;
      padding-right: 2.4rem;
      width: 133.8rem; } }
  @media (max-width: 74.99em) {
    .footer__inner {
      flex-direction: column; } }
  @media (min-width: 75em) {
    .footer__inner {
      justify-content: space-between; } }

@media (max-width: 74.99em) {
  .footer__logo {
    margin-bottom: 5rem; } }

.footer__links {
  list-style: none;
  margin: 0;
  padding-left: 0;
  display: flex;
  flex-wrap: wrap;
  padding: 0 4rem; }
  @media (max-width: 74.99em) {
    .footer__links {
      flex-direction: column;
      text-align: center;
      width: 100%; } }

@media (max-width: 74.99em) {
  .footer__links-item:not(:last-child) {
    margin-bottom: 0.5rem; } }

@media (min-width: 75em) {
  .footer__links-item:not(:last-child) {
    margin-right: 2.5rem; } }

.footer__links-link {
  display: block;
  padding: 1rem; }
  .footer__links-link:hover, .footer__links-link:focus {
    text-decoration: underline; }
  @media (min-width: 75em) {
    .footer__links-link {
      font-size: 1.4rem; } }

.footer__social {
  list-style: none;
  margin: 0;
  padding-left: 0;
  display: flex;
  margin-left: -1.25rem;
  margin-right: -1.25rem; }
  @media (max-width: 74.99em) {
    .footer__social {
      flex-wrap: wrap;
      justify-content: center;
      margin-top: 5rem; } }

.footer__social-item {
  margin-left: 1.25rem;
  margin-right: 1.25rem; }
  @media (max-width: 74.99em) {
    .footer__social-item {
      margin-bottom: 2.5rem; } }

.footer__social-link {
  color: #535353;
  display: block; }
  .footer__social-link:hover, .footer__social-link:focus {
    color: #0e0e0e; }

.footer__social-icon {
  font-size: 3.4rem; }
  @media (min-width: 75em) {
    .footer__social-icon {
      font-size: 2.7rem; } }

.header {
  background-color: #fff;
  padding: 1.5rem 0;
  position: relative;
  z-index: 106; }
  @media (min-width: 48em) {
    .header {
      box-shadow: 0 2px 15px 0 rgba(0, 0, 0, 0.08);
      display: flex;
      height: 8.8rem; } }
  @media (min-width: 75em) {
    .header {
      position: fixed;
      width: 100%; } }

.header__inner {
  margin-left: auto;
  margin-right: auto;
  max-width: 100%;
  align-items: center;
  display: flex;
  justify-content: space-between;
  width: 100%; }
  @media all {
    .header__inner {
      padding-left: 2.4rem;
      padding-right: 2.4rem; } }
  @media (min-width: 23.4375em) {
    .header__inner {
      padding-left: 2.4rem;
      padding-right: 2.4rem; } }
  @media (min-width: 48em) {
    .header__inner {
      padding-left: 2.4rem;
      padding-right: 2.4rem; } }
  @media (min-width: 75em) {
    .header__inner {
      padding-left: 2.4rem;
      padding-right: 2.4rem;
      width: 107.2rem; } }
  @media (min-width: 87.5em) {
    .header__inner {
      padding-left: 2.4rem;
      padding-right: 2.4rem; } }

.header__logo {
  position: relative;
  transition: all 200ms ease; }
  .header__logo svg {
    width: 100%; }
  @media (max-width: 47.99em) {
    .header__logo {
      width: 12.5rem; } }
  @media (min-width: 48em) {
    .header__logo {
      width: 26rem;
      z-index: 101; }
      .navbar--active .header__logo {
        color: #fff; } }
  @media (min-width: 75em) {
    .header__logo::before {
      background-repeat: no-repeat;
      background-size: cover;
      border-radius: 50%;
      box-shadow: 0 2px 20px rgba(0, 0, 0, 0.15);
      content: '';
      height: 6rem;
      left: -7.5rem;
      position: absolute;
      top: -1.2rem;
      width: 6rem; }
    body:lang(de) .header__logo::before {
      background-image: url("/images/logo-demokratie-stärken.png"); }
    body:lang(en) .header__logo::before {
      background-image: url("/images/logo-strengthen-democracy.png"); } }
  @media (min-width: 87.5em) {
    .header__logo::before {
      height: 12rem;
      left: -14rem;
      top: -0.8rem;
      width: 12rem; } }

.header__menu {
  display: flex; }

.header__basket {
  align-self: flex-end;
  display: flex;
  margin-right: 4rem; }

.header__search-wrapper {
  display: flex; }
  @media (min-width: 48em) {
    .header--no-search .header__search-wrapper {
      display: none; } }

.header__search-btn {
  margin-right: 4rem;
  position: relative;
  z-index: 1; }
  .header__search-btn:hover {
    color: #0c55a6; }

.header__search-icon {
  height: 2.4rem;
  transition: all 300ms ease;
  width: 2.4rem; }

.header__search-dropdown-inner {
  background: #fff;
  display: flex;
  flex-direction: column;
  left: 0;
  opacity: 1;
  padding-bottom: 2.4rem;
  padding-top: 2.4rem;
  position: absolute;
  top: 100%;
  transition: top 300ms ease, opacity 300ms ease;
  visibility: visible;
  width: 100vw;
  z-index: 1; }
  .header__search-dropdown[aria-hidden=true] .header__search-dropdown-inner {
    opacity: 0;
    top: calc(100% - 0.5rem);
    transition: opacity 300ms ease, top 300ms ease, visibility 0ms linear 300ms;
    visibility: hidden; }

.header__search-background {
  background-color: rgba(14, 14, 14, 0.8);
  height: 100vh;
  left: 0;
  position: absolute;
  top: 100%;
  transition: all 300ms ease;
  width: 100%; }
  .header__search-dropdown[aria-hidden=true] .header__search-background {
    opacity: 0;
    visibility: hidden; }

.header__language-wrapper {
  display: none;
  position: relative; }
  @media (min-width: 75em) {
    .header__language-wrapper {
      display: block; } }

.header__language-btn {
  align-items: center;
  display: flex;
  height: 3rem;
  margin-right: 4rem;
  width: auto; }
  .header__language-btn:hover {
    color: #0c55a6; }

.header__language-btn-label {
  user-select: none; }

.header__language-btn-title[title] {
  border: 0;
  text-transform: uppercase; }

.header__language-icon {
  height: 1.2rem;
  margin-left: 1rem;
  transition: all 200ms ease;
  width: 2.4rem; }
  .header__language-btn[aria-expanded=true] .header__language-icon {
    transform: rotate(180deg); }

.header__language-dropdown {
  background: #fff;
  border-radius: 0 0 1rem 1rem;
  box-shadow: 0 2px 15px 0 rgba(0, 0, 0, 0.08);
  display: flex;
  flex-direction: column;
  left: -2.4rem;
  margin-top: 2.9rem;
  opacity: 1;
  padding: 2.4rem;
  position: absolute;
  top: 100%;
  transition: all 300ms ease, visibility 300ms ease;
  visibility: visible;
  width: 22rem; }
  .header__language-dropdown[aria-hidden=true] {
    opacity: 0;
    top: calc(100% - 0.5rem);
    transition: all 300ms ease, visibility 300ms ease 300ms;
    visibility: hidden; }

.header__language-dropdown-title {
  color: #868686;
  font-size: 1.2rem;
  letter-spacing: 0.5px;
  margin-bottom: 1rem;
  text-transform: uppercase; }

.header__languages-list {
  list-style: none;
  margin: 0;
  padding-left: 0; }

.header__navigation {
  align-items: center;
  display: flex;
  justify-content: center; }

.header__menu-icon {
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 2.4rem;
  justify-content: center;
  transition: all 300ms ease;
  width: 2.4rem; }

.header__menu-icon span {
  background-color: #0e0e0e;
  border-radius: 2px;
  display: block;
  height: 2px;
  position: absolute;
  transform: translateY(-50%);
  transition: all 200ms ease;
  width: 2.4rem; }
  .header__menu-icon span:nth-child(1) {
    top: 20%;
    transition: all 200ms ease 200ms, transform 200ms ease; }
  .header__menu-icon span:nth-child(2) {
    top: 50%;
    transition: all 200ms ease 200ms; }
  .header__menu-icon span:nth-child(3) {
    top: 80%;
    transition: all 200ms ease 200ms, transform 200ms ease; }

.header__menu-btn[aria-expanded=true] .header__menu-icon span {
  background: #fff;
  margin-bottom: 0; }
  .header__menu-btn[aria-expanded=true] .header__menu-icon span:nth-child(1) {
    top: 50%;
    transform: rotate(45deg);
    transition: all 200ms ease, transform 200ms ease 200ms; }
  .header__menu-btn[aria-expanded=true] .header__menu-icon span:nth-child(2) {
    opacity: 0;
    transition: all 200ms ease; }
  .header__menu-btn[aria-expanded=true] .header__menu-icon span:nth-child(3) {
    top: 50%;
    transform: rotate(-45deg);
    transition: all 200ms ease, transform 200ms ease 200ms; }

.header__menu-btn {
  position: relative;
  z-index: 101; }

.header__nav-canvas {
  background: rgba(9, 67, 129, 0.9);
  display: flex;
  height: 100%;
  left: 0;
  overflow: scroll;
  padding: 10rem 4rem 0;
  position: fixed;
  scroll-behavior: smooth;
  top: 0;
  width: 100%;
  z-index: 100; }
  .t-cond .header__nav-canvas {
    background: rgba(0, 0, 0, 0.9); }
  @media (min-width: 75em) {
    .header__nav-canvas {
      padding: 20rem 0 12.5rem; } }

.header__nav-canvas {
  opacity: 1;
  transform: translateX(0%);
  transition: all 200ms ease, transform 0ms linear, visibility 0ms linear;
  visibility: visible; }
  .header__nav-canvas[aria-hidden=true] {
    opacity: 0;
    transform: translateX(100%);
    transition: all 200ms ease, transform 0ms linear 200ms, visibility 0ms linear 200ms;
    visibility: hidden; }

.header__nav-canvas-inner {
  margin-left: auto;
  margin-right: auto;
  max-width: 100%;
  align-items: flex-end;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%; }
  @media all {
    .header__nav-canvas-inner {
      padding-left: 2.4rem;
      padding-right: 2.4rem; } }
  @media (min-width: 23.4375em) {
    .header__nav-canvas-inner {
      padding-left: 2.4rem;
      padding-right: 2.4rem; } }
  @media (min-width: 48em) {
    .header__nav-canvas-inner {
      padding-left: 2.4rem;
      padding-right: 2.4rem; } }
  @media (min-width: 75em) {
    .header__nav-canvas-inner {
      padding-left: 2.4rem;
      padding-right: 2.4rem;
      width: 107.2rem; } }
  @media (min-width: 87.5em) {
    .header__nav-canvas-inner {
      padding-left: 2.4rem;
      padding-right: 2.4rem; } }

.header__nav-list {
  display: flex;
  flex-direction: column;
  margin: 0;
  padding: 0;
  width: 100%; }
  .header__nav-list:not(:first-child) {
    border-top: 1px solid #fff;
    margin-top: 2rem;
    padding-top: 3rem; }
  @media (min-width: 48em) {
    .header__nav-list {
      margin-right: 10rem;
      width: 23rem; }
      .header__nav-list:not(:first-child) {
        margin-top: 5rem;
        padding-top: 6rem; } }

.header__nav-languages {
  padding-bottom: 4rem; }

.header__nav-link {
  display: block;
  text-decoration: none; }
  .header__nav-link:hover, .header__nav-link:focus {
    color: #fff;
    text-decoration: underline; }
  .t-inverted .header__nav-link:hover,
  .t-inverted .header__nav-link:focus {
    color: #fff; }

.header__nav-item {
  color: #fff;
  display: flex; }
  .header__nav-item .icon--checkmark {
    margin-right: 1rem;
    width: 1rem; }

.header__nav-item-primary {
  margin-bottom: 1.6rem; }
  @media (min-width: 75em) {
    .header__nav-item-primary:not(:last-child) {
      margin-bottom: 3rem; } }

.header__nav-item-secondary,
.header__nav-item-languages {
  font-size: 1.6rem;
  margin-bottom: 2rem; }
  @media (min-width: 75em) {
    .header__nav-item-secondary,
    .header__nav-item-languages {
      font-size: 2rem; }
      .header__nav-item-secondary:not(:last-child),
      .header__nav-item-languages:not(:last-child) {
        margin-bottom: 2.4rem; } }

.header__nav-item-secondary {
  display: flex;
  position: relative; }

.header__nav-link-secondary {
  display: block;
  flex-shrink: 1; }
  .header__nav-link-secondary::before {
    content: '';
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%; }

.header__nav-link-icon {
  flex-shrink: 0;
  margin-left: 1rem; }

.header__nav-item-languages .header__nav-link-preicon {
  border-radius: 50%;
  margin-right: 2rem; }

.simple-stage {
  margin-left: auto;
  margin-right: auto;
  max-width: 100%;
  padding-top: 1.5rem; }
  @media all {
    .simple-stage {
      padding-left: 2.4rem;
      padding-right: 2.4rem; } }
  @media (min-width: 23.4375em) {
    .simple-stage {
      padding-left: 2.4rem;
      padding-right: 2.4rem; } }
  @media (min-width: 48em) {
    .simple-stage {
      padding-left: 2.4rem;
      padding-right: 2.4rem; } }
  @media (min-width: 75em) {
    .simple-stage {
      padding-left: 2.4rem;
      padding-right: 2.4rem;
      width: 107.2rem; } }
  @media (min-width: 87.5em) {
    .simple-stage {
      padding-left: 2.4rem;
      padding-right: 2.4rem; } }

.simple-stage__text {
  margin-top: 1.5rem; }

@media (min-width: 75em) {
  .detail-content {
    display: flex;
    justify-content: space-between;
    position: relative;
    scroll-padding-top: 8.8rem; } }

.detail-content__sidebar {
  position: relative;
  z-index: 2; }

.detail-content__sidebar-inner {
  display: flex;
  flex-direction: column;
  position: relative; }
  @media (min-width: 75em) {
    .detail-content__sidebar-inner {
      position: sticky;
      top: 10rem; } }

.detail-content__sidebar-section {
  padding-top: 4rem; }
  @media (min-width: 75em) {
    .detail-content__sidebar-section:not(:last-child) {
      border-bottom: 1px solid #e2e2e2;
      padding-bottom: 4rem; } }

@media (max-width: 74.99em) {
  .detail-content__sidebar-share {
    display: none; } }

@media (min-width: 75em) {
  .detail-content--border-top {
    border-top: 1px solid #868686;
    margin-top: 5rem;
    padding-top: 5rem; } }

@media (min-width: 75em) {
  .detail-content--border-top .detail-content__jumplist {
    margin-top: 0; } }

@media (max-width: 74.99em) {
  .detail-content__jumplist {
    margin-left: -2.4rem;
    margin-right: -2.4rem; } }

@media (min-width: 75em) {
  .detail-content__jumplist {
    height: 100%;
    margin-top: -5.5rem;
    top: 0;
    width: 17.6rem; } }

@media (min-width: 75em) {
  .detail-content__jumplist .jumplist__inner {
    position: sticky;
    top: 8.8rem;
    width: 17.6rem;
    z-index: 102; } }

@media (min-width: 75em) {
  .detail-content__inner {
    padding-left: 2rem;
    width: 84.8rem; } }

.detail-content__block {
  position: relative; }
  .detail-content__block:not(:last-child) {
    margin-bottom: 3.2rem; }
    @media (min-width: 48em) {
      .detail-content__block:not(:last-child) {
        margin-bottom: 5.6rem; } }
    @media (min-width: 75em) {
      .detail-content__block:not(:last-child) {
        margin-bottom: 8rem; } }

.detail-content__block--header:not(:last-child),
.detail-content__block--no-margin:not(:last-child) {
  margin-bottom: 0; }

.detail-content__block--media {
  max-width: 67rem; }
  @media (min-width: 48em) {
    .detail-content__block--media + .detail-content__block--text,
    .detail-content__block--text + .detail-content__block--media {
      margin-top: -2rem; } }
  @media (min-width: 75em) {
    .detail-content__block--media + .detail-content__block--text,
    .detail-content__block--text + .detail-content__block--media {
      margin-top: -3rem; } }

@media (min-width: 48em) {
  .detail-content__block--media-full + .detail-content__block--text,
  .detail-content__block--text + .detail-content__block--media-full {
    margin-top: -2rem; } }

@media (min-width: 75em) {
  .detail-content__block--media-full + .detail-content__block--text,
  .detail-content__block--text + .detail-content__block--media-full {
    margin-top: -3rem; } }

@media (min-width: 75em) {
  .detail-content__block--large {
    margin-left: calc(-17.6rem - 2rem);
    position: relative;
    z-index: 104; } }

.detail-content__block--full {
  margin-left: -2.4rem;
  margin-right: -2.4rem;
  position: relative;
  transform: translateZ(0);
  z-index: 1;
  position: relative;
  z-index: 104; }
  @media (min-width: 75em) {
    .detail-content__block--full {
      left: calc(50% - 7.5rem);
      position: relative;
      transform: translateX(-50%);
      width: 100vw; } }
  @media (min-width: 75em) {
    .detail-content__block--full::before, .detail-content__block--full::after {
      content: '';
      display: block;
      height: 6rem;
      left: 0;
      pointer-events: none;
      position: absolute;
      width: 100%; }
    .detail-content__block--full::before {
      background-image: linear-gradient(rgba(255, 255, 255, 0) 0, #fff 100%);
      top: -6rem; }
    .detail-content__block--full::after {
      background-image: linear-gradient(#fff 0, rgba(255, 255, 255, 0) 100%);
      bottom: -6rem; } }

.page--article .detail-content__block:first-child .detail-content__headline {
  border: 0;
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 1px; }

@media (min-width: 75em) {
  .detail-content__block:not(.detail-content__block--full) + .detail-content__block .detail-content__headline.headline--section {
    margin-top: 12rem; } }

.detail-content__headline.headline--2, .detail-content__headline.headline--3 {
  margin-bottom: 2.8rem; }

@media (min-width: 75em) {
  .detail-content__headline {
    scroll-margin-top: 10.8rem; } }

.article-stage {
  margin-left: auto;
  margin-right: auto;
  max-width: 100%; }
  @media all {
    .article-stage {
      padding-left: 2.4rem;
      padding-right: 2.4rem; } }
  @media (min-width: 23.4375em) {
    .article-stage {
      padding-left: 2.4rem;
      padding-right: 2.4rem; } }
  @media (min-width: 48em) {
    .article-stage {
      padding-left: 2.4rem;
      padding-right: 2.4rem; } }
  @media (min-width: 75em) {
    .article-stage {
      padding-left: 2.4rem;
      padding-right: 2.4rem;
      width: 107.2rem; } }
  @media (min-width: 87.5em) {
    .article-stage {
      padding-left: 2.4rem;
      padding-right: 2.4rem; } }
  @media (max-width: 74.99em) {
    .article-stage {
      padding-bottom: 3rem; } }

.article-stage__figure {
  margin-bottom: 2rem; }
  @media (max-width: 47.99em) {
    .article-stage__figure .figure__media {
      margin-left: -2.4rem;
      margin-right: -2.4rem; } }

.article-stage__date {
  margin-bottom: 1rem; }

.article-stage__text,
.article-stage__tags {
  margin-top: 1.5rem; }
  @media (min-width: 48em) {
    .article-stage__text,
    .article-stage__tags {
      margin-top: 3rem; } }

@media (max-width: 74.99em) {
  .teaser-grid--tiles .teaser-grid__inner {
    display: none; } }

.teaser-grid--tiles .teaser-grid__inner.teaser-grid__inner--mobile {
  display: block; }
  @media (min-width: 75em) {
    .teaser-grid--tiles .teaser-grid__inner.teaser-grid__inner--mobile {
      display: none; } }

@media (min-width: 75em) {
  .teaser-grid--tiles .teaser-grid__item--large {
    grid-column: auto / span 2; } }

.teaser-grid__inner {
  display: grid;
  gap: 3.2rem;
  grid-template-columns: repeat(3, 1fr);
  max-width: 100%; }
  @media (max-width: 74.99em) {
    .teaser-grid__inner {
      gap: 1.6rem;
      max-width: none; } }

@media (max-width: 74.99em) {
  .teaser-grid__teaser-large {
    margin-bottom: 1.6rem; } }

.teaser-grid__button {
  margin-top: 5.6rem;
  text-align: center; }
  @media (max-width: 74.99em) {
    .teaser-grid__button {
      margin-top: 2.4rem; } }

.box-stage__inner {
  position: relative; }

.box-stage__figure .image--cover {
  height: 100%; }

.box-stage__figure .image__img {
  max-height: 72rem; }
  @media (max-width: 74.99em) {
    .box-stage__figure .image__img {
      min-height: 60rem; } }
  @media (max-width: 47.99em) {
    .box-stage__figure .image__img {
      object-position: right; } }

@media (max-width: 47.99em) {
  .box-stage__inner--right .box-stage__figure .image__img {
    object-position: left; } }

.box-stage__content {
  left: 50%;
  max-width: calc(100% - 4.8rem);
  padding: 2.5rem;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  z-index: 2; }
  .box-stage__content::after {
    backdrop-filter: blur(5px);
    background-color: rgba(255, 255, 255, 0.8);
    content: '';
    height: 100%;
    left: 50%;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    z-index: -1; }
  @media (min-width: 48em) {
    .box-stage__content {
      left: 2.4rem;
      max-width: 65%;
      padding: 3.5rem;
      transform: translateY(-50%); } }
  @media (min-width: 75em) {
    .box-stage__content {
      left: 10%;
      max-width: 67rem; } }
  @media (min-width: 87.5em) {
    .box-stage__content {
      left: 15%; } }

@media (min-width: 48em) {
  .box-stage__inner--right .box-stage__content {
    left: auto;
    right: 2.4rem; } }

@media (min-width: 75em) {
  .box-stage__inner--right .box-stage__content {
    left: auto;
    right: 10%; } }

@media (min-width: 75em) {
  .box-stage__inner--right .box-stage__content {
    left: auto;
    right: 15%; } }

.box-stage__headline {
  padding-bottom: 2rem;
  position: relative; }
  .box-stage__headline::after {
    background-color: #0e0e0e;
    bottom: 0;
    content: '';
    display: block;
    height: 1px;
    left: 0;
    position: absolute;
    width: 7rem; }
  @media (min-width: 75em) {
    .box-stage__headline {
      padding-bottom: 3rem; } }

.box-stage__text {
  margin-top: 1.5rem; }
  @media (min-width: 75em) {
    .box-stage__text {
      margin-top: 2.5rem; } }

.box-stage__more-link {
  margin-top: 2rem; }
  @media (min-width: 75em) {
    .box-stage__more-link {
      margin-top: 6.4rem; } }

.publication-stage {
  background-image: url("..//images/background/breaker-dark.svg");
  background-position: 20% 100%;
  background-size: cover; }
  @media (min-width: 48em) {
    .publication-stage {
      background-position: center; } }

.publication-stage__inner {
  margin-left: auto;
  margin-right: auto;
  max-width: 100%;
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding-bottom: 2.4rem;
  padding-top: 2.4rem;
  width: 100%; }
  @media all {
    .publication-stage__inner {
      padding-left: 2.4rem;
      padding-right: 2.4rem; } }
  @media (min-width: 23.4375em) {
    .publication-stage__inner {
      padding-left: 2.4rem;
      padding-right: 2.4rem; } }
  @media (min-width: 48em) {
    .publication-stage__inner {
      padding-left: 2.4rem;
      padding-right: 2.4rem; } }
  @media (min-width: 75em) {
    .publication-stage__inner {
      padding-left: 2.4rem;
      padding-right: 2.4rem;
      width: 107.2rem; } }
  @media (min-width: 87.5em) {
    .publication-stage__inner {
      padding-left: 2.4rem;
      padding-right: 2.4rem; } }
  @media (min-width: 48em) {
    .publication-stage__inner {
      align-items: stretch;
      flex-direction: row;
      padding-bottom: 4rem;
      padding-top: 4rem; } }
  @media (min-width: 75em) {
    .publication-stage__inner {
      justify-content: space-between; } }

.publication-stage__image {
  flex-shrink: 0;
  position: relative; }
  @media (max-width: 47.99em) {
    .publication-stage__image {
      margin-bottom: 2.4rem; } }
  @media (min-width: 48em) {
    .publication-stage__image {
      margin-right: 6rem; } }
  @media (min-width: 75em) {
    .publication-stage__image {
      margin-right: 12rem; } }

@media (max-width: 47.99em) {
  .publication-stage__image .image__img {
    width: 15rem; } }

.publication-stage__image-link {
  align-items: center;
  background-color: #fff;
  color: #0c55a6;
  display: inline-flex;
  font-size: 1.2rem;
  left: 50%;
  padding: 0.3rem;
  position: relative;
  top: -1.1rem;
  transform: translateX(-50%); }
  .publication-stage__image-link:hover {
    text-decoration: underline; }
  @media (min-width: 48em) {
    .publication-stage__image-link {
      font-size: 1.6rem;
      padding: 0.5rem 1rem 0.5rem 0.5rem;
      top: -1.7rem; } }

.publication-stage__image-icon {
  font-size: 1.5rem;
  margin-right: 0.5rem; }
  @media (min-width: 48em) {
    .publication-stage__image-icon {
      font-size: 2.4rem;
      margin-right: 1rem; } }

@media (min-width: 48em) {
  .publication-stage__content {
    flex-grow: 1; } }

.publication-stage__kicker {
  margin-bottom: 1.6rem; }

.publication-stage__kicker .kicker {
  color: #fff; }

.publication-stage__text {
  margin-top: 0.8rem; }
  @media (min-width: 48em) {
    .publication-stage__text {
      margin-top: 1.6rem; } }

.publication-stage__tags {
  margin-top: 2.4rem; }
  @media (min-width: 48em) {
    .publication-stage__tags {
      margin-top: 4rem; } }

.publication-stage__download-links {
  margin-bottom: -2rem;
  margin-top: 2.4rem; }
  @media (min-width: 48em) {
    .publication-stage__download-links {
      margin-top: 4rem; } }

.list-content {
  margin-top: 4rem; }
  @media (max-width: 74.99em) {
    .list-content {
      padding-top: 2.4rem;
      position: relative; } }
  @media (min-width: 75em) {
    .list-content {
      display: flex;
      flex-direction: column;
      position: relative;
      scroll-padding-top: 8.8rem; } }

@media (min-width: 75em) {
  .list-content__content {
    display: grid;
    grid-gap: 3rem;
    grid-template-columns: 17.6rem 1fr; } }

@media (max-width: 74.99em) {
  .list-content__filter {
    display: flex;
    justify-content: flex-end; } }

@media (min-width: 75em) {
  .list-content__filter {
    margin-top: 6rem; } }

.list-content__filter-button {
  max-width: 100%;
  min-height: 0;
  padding: 0.5rem 1.5rem; }

.list-content__toggle {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 56 56'%3E%3Cpath fill='%23fff' d='M0 0h56v56H0z'/%3E%3Cpath fill='%230e0e0e' d='M20.85 22.56a1.64 1.64 0 1 0-2.3 2.3l8.2 8.19c.64.62 1.66.62 2.3 0l8.2-8.2a1.64 1.64 0 1 0-2.3-2.3l-7.05 7.02-7.05-7.05v.04z'/%3E%3C/svg%3E");
  background-color: #fff;
  background-position: right;
  background-repeat: no-repeat;
  height: 4rem;
  padding-right: 4rem; }
  .list-content__toggle:hover, .list-content__toggle:focus {
    color: #0c55a6;
    text-decoration: underline; }
  .list-content__toggle[aria-expanded='true'] {
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 56 56'%3E%3Cpath fill='%23fff' d='M0 0h56v56H0z'/%3E%3Cpath fill='%230e0e0e' d='M20.85 32.87a1.64 1.64 0 1 1-2.3-2.3l8.2-8.2a1.65 1.65 0 0 1 2.3 0l8.2 8.2a1.64 1.64 0 1 1-2.3 2.3l-7.05-7.01-7.05 7.05v-.04z'/%3E%3C/svg%3E"); }
  @media (max-width: 74.99em) {
    .list-content__toggle {
      border-bottom: 3px solid #0c55a6;
      width: calc(50% - 1.5rem / 2); } }

.list-content__toggle--filter {
  height: 4rem;
  width: calc(50% - 1.5rem / 2); }
  @media (min-width: 75em) {
    .list-content__toggle--filter {
      display: none; } }

@media (max-width: 74.99em) {
  .list-content__toggle-content {
    background-color: #fff;
    border-radius: 0 0 1rem 1rem;
    box-shadow: 0 2px 15px 0 rgba(0, 0, 0, 0.08);
    display: none;
    position: absolute;
    top: 4rem;
    z-index: 1; } }

@media (max-width: 74.99em) {
  .list-content__toggle-content--filter {
    padding: 2rem 1.5rem;
    width: 100%; } }

.list-content__toggle-content--sort {
  list-style: none;
  margin: 0;
  padding-left: 0;
  left: 0;
  padding: 1.5rem 0;
  text-align: left;
  top: 0;
  width: calc(50% - 1.5rem / 2); }
  @media (min-width: 75em) {
    .list-content__toggle-content--sort {
      background-color: #fff;
      border-radius: 0 0 1rem 1rem;
      box-shadow: 0 2px 15px 0 rgba(0, 0, 0, 0.08);
      display: none;
      position: absolute;
      right: 0;
      top: 4rem;
      width: 20rem;
      z-index: 1; } }

.list-content__toggle-content--visible {
  display: block; }

.list-content__list-filter:not(:last-child) {
  border-bottom: 1px solid #e2e2e2;
  margin-bottom: 3rem;
  padding-bottom: 3rem; }

.list-content__header {
  margin-bottom: 3rem; }
  @media (min-width: 48em) {
    .list-content__header {
      margin-bottom: 4rem; } }
  @media (min-width: 75em) {
    .list-content__header {
      margin-bottom: 6rem; } }

.list-content .list-content__search-bar {
  padding-left: 0;
  padding-right: 0; }

.list-content__content {
  position: relative; }

.list-content__top {
  border-bottom: 1px solid #e2e2e2;
  margin-bottom: 2.4rem; }
  @media (max-width: 74.99em) {
    .list-content__top {
      padding-bottom: 2rem;
      position: relative; } }
  @media (min-width: 75em) {
    .list-content__top {
      display: flex;
      justify-content: space-between;
      margin-bottom: 3.2rem; } }

.list-content__count {
  align-items: center;
  display: inline-flex;
  font-weight: bold;
  min-height: 2rem; }
  @media (max-width: 74.99em) {
    .list-content__count {
      bottom: 2rem;
      left: 0;
      line-height: 1.25;
      position: absolute;
      width: calc(50% - 1.5rem / 2); } }
  @media (min-width: 75em) {
    .list-content__count {
      min-height: 4rem;
      padding-right: 3rem; } }

@media (min-width: 75em) {
  .list-content__sort {
    flex-shrink: 0;
    position: relative; } }

@media (max-width: 74.99em) {
  .list-content__toggle--sort {
    position: relative;
    top: -4rem; } }

@media (max-width: 74.99em) {
  .list-content__toggle-sort-label {
    display: none; } }

.list-content__sort-link {
  display: block;
  padding: 1rem 2rem;
  transition-duration: 0.1s;
  transition-property: background-color;
  user-select: none; }
  .list-content__sort-link:hover, .list-content__sort-link:focus {
    background-color: #f9f9f9; }

.list-content__sort-link--active {
  color: #0c55a6;
  cursor: default;
  font-weight: bold;
  position: relative; }

.list-content__error {
  margin-top: 6rem; }

.list-content__meta {
  color: #87012e;
  display: grid;
  grid-template-columns: auto 1fr;
  line-height: 1.625;
  margin: 2rem 0; }

.list-content__meta-label {
  font-weight: bold; }

.list-content__count {
  opacity: 1;
  transition: opacity 300ms ease, visibility 0ms 0ms;
  visibility: visible; }
  .list-content__count.x-hide {
    opacity: 0;
    transition: opacity 300ms ease, visibility 0ms 300ms;
    visibility: hidden; }

.slider {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  position: relative;
  width: 100%; }
  .headline--section + .slider:not(.slider--disabled) {
    margin-top: -2rem; }
    @media (min-width: 48em) {
      .headline--section + .slider:not(.slider--disabled) {
        margin-top: -3.4rem; } }
  .slider .swiper-pagination:not(:empty) {
    margin-top: 5rem;
    position: static; }
  .slider .swiper-pagination-bullet {
    background-color: #868686;
    height: 1rem;
    transition: all 300ms ease;
    width: 1rem; }
  .slider .swiper-pagination-bullet-active {
    background-color: #0c55a6; }
    .lightbox .slider .swiper-pagination-bullet-active {
      background-color: #fff; }
  .slider .swiper-pagination-bullet:not(:last-child) {
    margin-right: 1.2rem; }

.slider--overflow-visible {
  overflow: visible; }

.slider--new .swiper-pagination {
  margin: 1rem 2rem; }

.slider--new .swiper-pagination-bullet {
  background-color: #0c55a6;
  border-radius: 0.1rem;
  cursor: pointer;
  height: 0.2rem;
  opacity: 0.3;
  position: relative;
  transition: border-radius 0.3s ease, height 0.3s ease, opacity 0.3s ease;
  width: 1.2rem; }

.slider--new .swiper-pagination-bullet-active {
  border-radius: 0.2rem;
  height: 0.4rem;
  opacity: 1;
  pointer-events: none;
  top: 0.1rem; }

.slider--new .slider__arrow-icon {
  color: #0c55a6; }

.slider__inner {
  position: relative; }
  @media (min-width: 48em) {
    .slider__inner {
      overflow: hidden; }
      .slider--overflow-visible .slider__inner {
        overflow: visible; } }

.slider:not(.slider--disabled) .slider__headline {
  margin-bottom: -2rem; }
  @media (min-width: 48em) {
    .slider:not(.slider--disabled) .slider__headline {
      margin-bottom: -3.4rem; } }

.slider__arrows {
  align-self: flex-end;
  display: flex;
  justify-content: space-between;
  margin-bottom: 1rem;
  width: 7.2rem; }
  .slider--disabled .slider__arrows {
    display: none; }
  .lightbox--transparent .slider__arrows {
    color: #fff; }
  .slider--new .slider__arrows {
    align-items: end;
    display: flex;
    justify-content: center;
    width: 100%; }

.slider__arrow {
  align-items: center;
  cursor: pointer;
  display: flex;
  height: 2.4rem;
  justify-content: center;
  position: relative;
  transition: all 200ms ease;
  width: 2.4rem; }
  .slider__arrow:not(.slider__arrow--disabled):hover {
    color: #0c55a6; }

.slider__arrow-icon {
  height: 100%;
  width: 100%; }

.slider__arrow--disabled {
  opacity: 0.25; }

.slider__button {
  margin-top: 2.4rem;
  text-align: center; }
  @media (min-width: 48em) {
    .slider__button {
      margin-top: 4rem; } }

.slider--gallery {
  overflow: hidden; }
  .slider--gallery > .slider__arrows {
    left: 50%;
    max-width: calc(100vw - 4rem);
    position: absolute;
    top: 35%;
    transform: translateX(-50%);
    width: calc(100% + 26rem); }
  .slider--gallery > .slider__arrows .slider__arrow {
    display: none;
    height: 6rem;
    width: 6rem; }
    @media (min-width: 75em) {
      .slider--gallery > .slider__arrows .slider__arrow {
        display: block; } }
  .slider--gallery .slider__arrow:not(.slider__arrow--disabled):hover {
    color: #fff; }
  .slider--gallery > .slider__inner .slider__slide {
    padding-top: calc(56.25% + 5rem);
    position: relative; }
  .slider--gallery > .slider__inner .slider__slide-inner {
    align-items: stretch;
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: center;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%; }
  .slider--gallery .slider__slide-image {
    padding-top: 56.25%;
    position: relative; }
  .slider--gallery .slider__slide-image img {
    height: 100%;
    left: 50%;
    object-fit: contain;
    position: absolute;
    top: 0;
    transform: translateX(-50%);
    width: auto; }
  .slider--gallery .swiper-pagination {
    margin-top: 2.5rem; }
  @media (min-width: 75em) {
    .slider--gallery {
      overflow: visible; }
      .slider--gallery .swiper-pagination {
        display: none; } }

.slider--thumbnails {
  margin-top: 1rem; }
  .slider--thumbnails .slider__slide {
    cursor: pointer;
    position: relative; }
  .slider--thumbnails .slider__slide-inner {
    border: 2px solid transparent;
    padding: 3px;
    transition: all 300ms ease; }
  .slider--thumbnails .slider__slide--active .slider__slide-inner {
    border: 2px solid #0e0e0e;
    padding: 3px; }
    .lightbox--transparent .slider--thumbnails .slider__slide--active .slider__slide-inner {
      border-color: #fff; }
  @media (min-width: 75em) {
    .slider--thumbnails {
      margin-top: 6rem; } }

.slider--stretched .slider__slide {
  height: 100% !important; }

.slider--stretched .slider__slide-inner {
  display: flex;
  flex: 1;
  height: 100%; }

.publication-info__inner {
  display: flex;
  flex-wrap: wrap; }
  @media (max-width: 74.99em) {
    .publication-info__inner {
      justify-content: flex-start; } }

@media (max-width: 47.99em) {
  .publication-info__definition-list .definition-list {
    columns: 20rem 2; }
  .publication-info__definition-list .definition-list__item {
    break-inside: avoid; } }

.publication-info__definition-list,
.publication-info__download-list {
  flex: 32rem;
  margin-right: 3.2rem;
  max-width: 32rem; }
  @media (max-width: 74.99em) {
    .publication-info__definition-list,
    .publication-info__download-list {
      flex: calc(50% - 2rem);
      margin-right: 0;
      max-width: 50%; } }
  @media (max-width: 47.99em) {
    .publication-info__definition-list,
    .publication-info__download-list {
      flex: 100%;
      max-width: 100%; } }

@media (max-width: 47.99em) {
  .publication-info__download-list {
    margin-top: 2rem; } }

@media (min-width: 75em) {
  .publication-info__download-list {
    margin-right: 12rem; } }

.publication-info__checkout {
  flex: 23.2rem;
  max-width: 23.2rem; }
  @media (max-width: 74.99em) {
    .publication-info__checkout {
      max-width: 100%; } }

@media (max-width: 74.99em) {
  .publication-info__checkout-flex {
    align-items: flex-end;
    display: flex;
    flex-wrap: wrap; } }

.publication-info__checkout-title {
  color: #868686;
  font-size: 1.2rem;
  letter-spacing: 0.5px;
  margin-bottom: 0.9rem;
  text-transform: uppercase; }

@media (max-width: 74.99em) {
  .publication-info__checkout-price-details {
    flex: 50%;
    margin-top: 2rem;
    order: 1; } }

@media (max-width: 47.99em) {
  .publication-info__checkout-price-details {
    flex: 100%; } }

.publication-info__checkout-price-val {
  font-size: 2.8rem;
  line-height: 5rem;
  margin-bottom: 1.6rem; }

.publication-info__checkout-info {
  color: #868686;
  font-size: 1.2rem;
  letter-spacing: 0.5px;
  line-height: 1.625; }

.publication-info__checkout-logo {
  margin-top: 2.4rem; }
  @media (max-width: 74.99em) {
    .publication-info__checkout-logo {
      flex: 50%;
      order: 0; } }

.publication-info__checkout-logo .logo-grid {
  max-width: 34rem; }

.publication-info__checkout-button {
  margin-top: 2rem; }
  @media (min-width: 75em) {
    .publication-info__checkout-button {
      margin-top: 3.5rem; } }

.publication-info__checkout-button .button {
  padding: 1.5rem 2.8rem; }

.tag-stage {
  background-image: url("..//images/background/breaker-dark.svg");
  background-position: 20% 100%;
  background-size: cover; }
  @media (min-width: 48em) {
    .tag-stage {
      background-position: center; } }

.tag-stage__inner {
  margin-left: auto;
  margin-right: auto;
  max-width: 100%;
  padding-bottom: 3rem;
  padding-top: 3rem; }
  @media all {
    .tag-stage__inner {
      padding-left: 2.4rem;
      padding-right: 2.4rem; } }
  @media (min-width: 23.4375em) {
    .tag-stage__inner {
      padding-left: 2.4rem;
      padding-right: 2.4rem; } }
  @media (min-width: 48em) {
    .tag-stage__inner {
      padding-left: 2.4rem;
      padding-right: 2.4rem; } }
  @media (min-width: 75em) {
    .tag-stage__inner {
      padding-left: 2.4rem;
      padding-right: 2.4rem;
      width: 107.2rem; } }
  @media (min-width: 87.5em) {
    .tag-stage__inner {
      padding-left: 2.4rem;
      padding-right: 2.4rem; } }
  @media (max-width: 47.99em) {
    .tag-stage__inner {
      text-align: center; } }
  @media (min-width: 75em) {
    .tag-stage__inner {
      padding-bottom: 5rem;
      padding-top: 5rem; } }
  @media (min-width: 48em) {
    .tag-stage__inner {
      align-items: center;
      display: flex; } }

.tag-stage__icon .icon {
  font-size: 7rem; }
  @media (max-width: 47.99em) {
    .tag-stage__icon .icon {
      margin-bottom: 2.4rem; } }
  @media (min-width: 48em) {
    .tag-stage__icon .icon {
      font-size: 11rem;
      margin-right: 5rem; } }

.tag-stage__kicker .kicker {
  color: rgba(255, 255, 255, 0.8);
  font-size: 1.8rem;
  letter-spacing: 0.8px;
  margin-bottom: 2rem; }
  @media (max-width: 47.99em) {
    .tag-stage__kicker .kicker {
      font-size: 1.6rem; } }

.tag-stage__headline {
  border-bottom: 2px solid #fff;
  display: inline-block;
  padding-bottom: 4px; }

.tag-stage__tag-link-list {
  margin-top: 2rem; }

@media (max-width: 47.99em) {
  .tag-stage__tag-link-list .tag-link-list__list {
    justify-content: center; } }

.teaser-list__item:not(:last-child) {
  margin-bottom: 2.4rem; }

.teaser-list--lines .teaser-list__item:not(last-child) {
  border-bottom: 1px solid #e2e2e2;
  padding-bottom: 2.4rem; }
  @media (min-width: 75em) {
    .teaser-list--lines .teaser-list__item:not(last-child) {
      margin-bottom: 3.2rem;
      padding-bottom: 3.2rem; } }

.teaser-list__items-wrapper {
  transition: opacity 200ms ease; }

.teaser-list__load-more-content {
  opacity: 0;
  transition: opacity 300ms; }

.teaser-list__button {
  margin-top: 2.4rem;
  text-align: center; }
  @media (min-width: 75em) {
    .teaser-list__button {
      margin-top: 6rem; } }

.js-load-more {
  opacity: 1;
  transition: opacity 300ms ease, visibility 0ms 0ms;
  visibility: visible; }
  .js-load-more.x-hide {
    opacity: 0;
    transition: opacity 300ms ease, visibility 0ms 300ms;
    visibility: hidden; }

.teaser-list__pagination {
  list-style: none;
  margin: 0;
  padding-left: 0;
  display: flex;
  justify-content: space-between;
  margin: 0 -1rem; }

.teaser-list__pagination-item,
.teaser-list__pagination-item {
  max-width: 50%;
  padding: 1rem; }

.teaser-list__pagination-button {
  align-items: center;
  display: flex;
  min-height: 0;
  padding: 1rem 1.5rem; }
  @media (max-width: 47.99em) {
    .teaser-list__pagination-button .button {
      align-items: center;
      display: flex;
      font-size: 14px;
      min-height: 3rem;
      padding: 1rem; } }

.text-stage {
  margin-left: auto;
  margin-right: auto;
  max-width: 100%; }
  @media all {
    .text-stage {
      padding-left: 2.4rem;
      padding-right: 2.4rem; } }
  @media (min-width: 23.4375em) {
    .text-stage {
      padding-left: 2.4rem;
      padding-right: 2.4rem; } }
  @media (min-width: 48em) {
    .text-stage {
      padding-left: 2.4rem;
      padding-right: 2.4rem; } }
  @media (min-width: 75em) {
    .text-stage {
      padding-left: 2.4rem;
      padding-right: 2.4rem;
      width: 107.2rem; } }
  @media (min-width: 87.5em) {
    .text-stage {
      padding-left: 2.4rem;
      padding-right: 2.4rem; } }

.text-stage__headline--color {
  background: linear-gradient(127deg, #0184ca 0%, #094381 100%);
  color: #fff;
  padding: 2rem 1.6rem;
  text-align: center; }
  @media (max-width: 74.99em) {
    .text-stage__headline--color {
      margin-left: -2.4rem;
      margin-right: -2.4rem; } }
  @media (min-width: 48em) {
    .text-stage__headline--color {
      padding: 4rem 3rem; } }

@media (max-width: 47.99em) {
  .text-stage__headline-text {
    hyphens: auto; } }

@media (min-width: 48em) {
  .text-stage__headline-text {
    font-size: 5rem; } }

@media (min-width: 48em) {
  .text-stage .headline {
    margin-top: 1.5rem; } }

.text-stage__text {
  margin-top: 1.6rem; }
  .text-stage__headline--color + .text-stage__text {
    margin-top: 2.4rem; }
    @media (min-width: 48em) {
      .text-stage__headline--color + .text-stage__text {
        margin-top: 5rem; } }

.image-stage__inner {
  margin-left: auto;
  margin-right: auto;
  max-width: 100%; }
  @media all {
    .image-stage__inner {
      padding-left: 2.4rem;
      padding-right: 2.4rem; } }
  @media (min-width: 23.4375em) {
    .image-stage__inner {
      padding-left: 2.4rem;
      padding-right: 2.4rem; } }
  @media (min-width: 48em) {
    .image-stage__inner {
      padding-left: 2.4rem;
      padding-right: 2.4rem; } }
  @media (min-width: 75em) {
    .image-stage__inner {
      padding-left: 2.4rem;
      padding-right: 2.4rem;
      width: 107.2rem; } }
  @media (min-width: 87.5em) {
    .image-stage__inner {
      padding-left: 2.4rem;
      padding-right: 2.4rem; } }

.image-stage__top {
  position: relative; }

@media (max-width: 47.99em) {
  .image-stage__figure .figure__media {
    margin-left: -2.4rem;
    margin-right: -2.4rem; } }

.image-stage__headline {
  bottom: 8.5rem;
  box-shadow: 0 2px 15px 0 rgba(0, 0, 0, 0.08);
  color: #0c55a6;
  font-size: 2.3rem;
  hyphens: auto;
  left: 50%;
  max-width: 85rem;
  padding: 2rem 1.6rem;
  position: absolute;
  text-align: center;
  transform: translateX(-50%);
  width: 100%; }
  .image-stage__headline::before {
    backdrop-filter: blur(5px);
    background: rgba(255, 255, 255, 0.8);
    content: '';
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: -1; }
  @media (max-width: 47.99em) {
    .image-stage__headline {
      width: calc(100% - 4.8rem); } }
  @media (min-width: 48em) {
    .image-stage__headline {
      bottom: 11.5rem;
      font-size: 5rem;
      padding: 4rem 3rem;
      width: calc(100% - 8.8rem); } }
  @media (min-width: 75em) {
    .image-stage__headline {
      bottom: 8.5rem; } }

.image-stage__text {
  margin-top: 1.6rem; }
  @media (min-width: 48em) {
    .image-stage__text {
      margin-top: 2.4rem; } }

@media (min-width: 48em) {
  .featured-person {
    display: flex; } }

.featured-person__image {
  margin-right: 3.2rem; }
  @media (max-width: 47.99em) {
    .featured-person__image {
      display: none; } }

.featured-person__mobile-image {
  margin-bottom: 3.2rem;
  max-width: 34.5rem; }
  @media (min-width: 48em) {
    .featured-person__mobile-image {
      display: none; } }

.featured-person__content {
  flex-grow: 1; }

.featured-person:hover .featured-person__name {
  text-decoration: underline; }

.featured-person__role {
  line-height: 1.75;
  margin-top: 0.5rem; }

.featured-person__link-list {
  margin-top: 3.5rem; }
  @media (max-width: 47.99em) {
    .featured-person__link-list {
      margin-left: -2.4rem;
      margin-right: -2.4rem; } }

.featured-person__modal-content {
  padding-top: 4rem; }

.search-bar {
  margin-left: auto;
  margin-right: auto;
  max-width: 100%;
  width: 100%;
  z-index: 1; }
  @media all {
    .search-bar {
      padding-left: 2.4rem;
      padding-right: 2.4rem; } }
  @media (min-width: 23.4375em) {
    .search-bar {
      padding-left: 2.4rem;
      padding-right: 2.4rem; } }
  @media (min-width: 48em) {
    .search-bar {
      padding-left: 2.4rem;
      padding-right: 2.4rem; } }
  @media (min-width: 75em) {
    .search-bar {
      padding-left: 2.4rem;
      padding-right: 2.4rem;
      width: 107.2rem; } }
  @media (min-width: 87.5em) {
    .search-bar {
      padding-left: 2.4rem;
      padding-right: 2.4rem; } }

.search-bar__inner {
  box-shadow: 0 2px 30px 0 rgba(0, 0, 0, 0.2);
  display: flex; }

.search-bar__form-group {
  flex-grow: 1;
  width: auto;
  z-index: 1; }

.search-bar .input {
  border-color: #fff;
  height: 5.6rem; }
  .search-bar .input:focus {
    border-color: #0c55a6; }
  @media (min-width: 48em) {
    .search-bar .input {
      height: 6.4rem; } }

.search-bar__button {
  align-items: center;
  background-color: #fff;
  display: flex;
  flex-shrink: 0;
  font-size: 2.1rem;
  height: 5.6rem;
  justify-content: center;
  transition-duration: 0.1s;
  transition-property: background-color;
  width: 5.6rem;
  z-index: 1; }
  .search-bar__button:hover, .search-bar__button:focus {
    background-color: #f9f9f9; }
  @media (min-width: 48em) {
    .search-bar__button {
      font-size: 2.8rem;
      height: 6.4rem;
      width: 6.4rem; } }

.search-bar--suggestions .search-bar__inner {
  position: relative; }

.search-bar__suggestions {
  background-color: #fff;
  border-radius: 0 0 1rem 1rem;
  border-top: 1px solid #e2e2e2;
  box-shadow: 0 2px 30px 0 rgba(0, 0, 0, 0.2);
  padding: 2rem 3rem;
  position: absolute;
  top: 100%;
  width: 100%;
  z-index: 105; }
  .search-bar__suggestions[aria-hidden=true] {
    display: none; }

.search-bar__suggestions-inner {
  display: flex;
  flex-direction: column;
  position: relative; }

.search-bar__row {
  margin: 0 -2rem; }

.search-bar__row--content {
  display: flex;
  flex-direction: column; }
  .search-bar__row--content[aria-hidden=true] {
    display: none; }
  @media (min-width: 48em) {
    .search-bar__row--content {
      flex-direction: row; } }

.search-bar__col {
  padding: 0 2rem; }

.search-bar__entities {
  float: left;
  width: 100%; }

.search-bar__projects,
.search-bar__publications,
.search-bar__tags {
  float: left;
  margin-top: 3rem;
  width: 100%; }
  @media (min-width: 48em) {
    .search-bar__projects,
    .search-bar__publications,
    .search-bar__tags {
      width: calc(100% / 3); } }

.search-bar__list-header {
  border-bottom: 1px solid #e2e2e2;
  color: #868686;
  font-size: 1.2rem;
  letter-spacing: 0.5px;
  line-height: 1.3;
  margin-bottom: 1.4rem;
  padding-bottom: 1.8rem; }

.search-bar__list {
  list-style: none;
  margin: 0;
  padding-left: 0; }

.search-bar__list--empty {
  color: #535353;
  font-style: italic;
  padding: 1.2rem 0; }
  .search-bar__entities .search-bar__list--empty {
    padding: 1.2rem 2rem; }

.search-bar__item {
  padding: 1.2rem 0; }

.search-bar__link {
  display: block; }

.search-bar__teaser {
  padding: 1.2rem 0;
  position: relative; }
  .search-bar__teaser .search-bar__link::before {
    content: '';
    display: block;
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%; }

.search-bar__description {
  color: #868686;
  font-size: 1.2rem;
  letter-spacing: 0.5px;
  line-height: 1.3;
  margin-top: 0.5rem; }

.search-bar__entities {
  padding: 0; }
  .search-bar__entities .search-bar__item {
    padding: 0; }
  .search-bar__entities .search-bar__link {
    color: #0e0e0e;
    padding: 1rem 2rem;
    text-decoration: none;
    transition-duration: 0.1s;
    transition-property: background-color;
    transition-timing-function: ease; }
    .search-bar__entities .search-bar__link:hover {
      background-color: rgba(226, 226, 226, 0.5); }

.search-bar__tags .search-bar__link {
  color: #0c55a6;
  text-decoration: none; }

.search-bar__tags .search-bar__link:hover,
.search-bar__tags .search-bar__link:focus {
  text-decoration: underline; }

@media (min-width: 48em) {
  .text-columns {
    column-count: 2;
    column-gap: 10rem; } }

@media (min-width: 75em) {
  .text-columns {
    column-gap: 12rem; } }

@media (max-width: 47.99em) {
  .text-columns__column:not(:last-child) {
    margin-bottom: 2.4rem; } }

@media (min-width: 48em) {
  .text-columns__column:not(:last-child) {
    break-inside: avoid;
    margin-bottom: 6.5rem; } }

.text-columns__icon {
  font-size: 7.2rem;
  line-height: 0;
  margin-bottom: 2.4rem; }

.text-columns__text {
  color: #535353;
  hyphens: auto; }
  .text-columns__text .headline {
    color: #0e0e0e; }

.image-breaker {
  align-items: flex-end;
  display: flex;
  justify-content: center;
  padding: 3.2rem 1.6rem;
  position: relative; }
  .image-breaker:not(:last-child) {
    margin-bottom: 6rem; }
  .stage .image-breaker {
    padding: 3.2rem 1.6rem;
    width: 100%; }
    @media (min-width: 48em) {
      .stage .image-breaker {
        padding: 4rem; } }
  @media (min-width: 48em) {
    .image-breaker {
      min-height: 70rem;
      padding: 4rem; } }

.image-breaker__figure {
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: -1; }
  .image-breaker__figure .figure__media,
  .image-breaker__figure .image {
    height: 100%; }
  .image-breaker__figure .figure__caption {
    padding-left: 2.4rem;
    padding-right: 2.4rem; }

.image-breaker__inner {
  margin-left: auto;
  margin-right: auto;
  max-width: 100%;
  bottom: 2rem;
  box-shadow: 0 2px 15px 0 rgba(0, 0, 0, 0.08);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 3.2rem 0;
  position: relative; }
  @media all {
    .image-breaker__inner {
      padding-left: 2.4rem;
      padding-right: 2.4rem; } }
  @media (min-width: 23.4375em) {
    .image-breaker__inner {
      padding-left: 2.4rem;
      padding-right: 2.4rem; } }
  @media (min-width: 48em) {
    .image-breaker__inner {
      padding-left: 2.4rem;
      padding-right: 2.4rem; } }
  @media (min-width: 75em) {
    .image-breaker__inner {
      padding-left: 2.4rem;
      padding-right: 2.4rem;
      width: 107.2rem; } }
  @media (min-width: 87.5em) {
    .image-breaker__inner {
      padding-left: 2.4rem;
      padding-right: 2.4rem; } }
  .image-breaker__inner::before {
    backdrop-filter: blur(5px);
    background: rgba(255, 255, 255, 0.8);
    content: '';
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: -1; }
  @media (min-width: 48em) {
    .image-breaker__inner {
      align-items: stretch;
      flex-direction: row;
      min-height: 30rem;
      padding: 4rem; } }

.image-breaker__content {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-bottom: 3.2rem;
  max-width: 58.4rem;
  padding: 0 3.2rem;
  position: relative; }
  @media (min-width: 48em) {
    .image-breaker__content {
      margin-bottom: 0;
      margin-right: 4rem;
      padding: 0; } }

.image-breaker__text {
  margin-top: 1.6rem; }

.image-breaker__more-link {
  margin-top: 3.2rem; }

.image-breaker__list {
  width: 100%; }
  .image-breaker__list .link-list__item-link {
    padding-left: 3.2rem;
    padding-right: 3.2rem; }
  @media (min-width: 48em) {
    .image-breaker__list {
      max-width: 32rem; } }

.overview-content__block {
  margin-left: auto;
  margin-right: auto;
  max-width: 144rem; }

.overview-content__block:not(:last-child) {
  margin-bottom: 3.2rem; }
  @media (min-width: 48em) {
    .overview-content__block:not(:last-child) {
      margin-bottom: 6rem; } }
  @media (min-width: 75em) {
    .overview-content__block:not(:last-child) {
      margin-bottom: 10rem; } }

.overview-content__block--narrow {
  margin-left: auto;
  margin-right: auto;
  max-width: 100%; }
  @media all {
    .overview-content__block--narrow {
      padding-left: 2.4rem;
      padding-right: 2.4rem; } }
  @media (min-width: 23.4375em) {
    .overview-content__block--narrow {
      padding-left: 2.4rem;
      padding-right: 2.4rem; } }
  @media (min-width: 48em) {
    .overview-content__block--narrow {
      padding-left: 2.4rem;
      padding-right: 2.4rem; } }
  @media (min-width: 75em) {
    .overview-content__block--narrow {
      padding-left: 2.4rem;
      padding-right: 2.4rem;
      width: 107.2rem; } }
  @media (min-width: 87.5em) {
    .overview-content__block--narrow {
      padding-left: 2.4rem;
      padding-right: 2.4rem; } }

.overview-content__block--fullwidth {
  margin-left: -12px;
  margin-right: -12px;
  max-width: none; }
  @media all {
    .overview-content__block--fullwidth {
      margin-left: -12px;
      margin-right: -12px; } }
  @media (min-width: 23.4375em) {
    .overview-content__block--fullwidth {
      margin-left: -12px;
      margin-right: -12px; } }
  @media (min-width: 48em) {
    .overview-content__block--fullwidth {
      margin-left: -12px;
      margin-right: -12px; } }
  @media (min-width: 75em) {
    .overview-content__block--fullwidth {
      margin-left: -12px;
      margin-right: -12px; } }
  @media (min-width: 87.5em) {
    .overview-content__block--fullwidth {
      margin-left: -12px;
      margin-right: -12px; } }

.icon-breaker {
  background-image: url("..//images/background/breaker-dark.svg");
  background-position: 20% 100%;
  background-size: cover;
  padding-bottom: 2.4rem;
  padding-top: 2.4rem; }
  @media (min-width: 48em) {
    .icon-breaker {
      background-position: center; } }
  @media (min-width: 75em) {
    .icon-breaker {
      padding-bottom: 6rem;
      padding-top: 6rem; } }
  @media (min-width: 75em) and (max-width: 87.49em) {
    .icon-breaker {
      padding-right: 12rem; } }

.icon-breaker__inner {
  margin-left: auto;
  margin-right: auto;
  max-width: 100%; }
  @media all {
    .icon-breaker__inner {
      padding-left: 2.4rem;
      padding-right: 2.4rem; } }
  @media (min-width: 23.4375em) {
    .icon-breaker__inner {
      padding-left: 2.4rem;
      padding-right: 2.4rem; } }
  @media (min-width: 48em) {
    .icon-breaker__inner {
      padding-left: 2.4rem;
      padding-right: 2.4rem; } }
  @media (min-width: 75em) {
    .icon-breaker__inner {
      padding-left: 2.4rem;
      padding-right: 2.4rem;
      width: 107.2rem; } }
  @media (min-width: 87.5em) {
    .icon-breaker__inner {
      padding-left: 2.4rem;
      padding-right: 2.4rem; } }
  @media (max-width: 47.99em) {
    .icon-breaker__inner {
      text-align: center; } }
  @media (min-width: 48em) {
    .icon-breaker__inner {
      align-items: center;
      display: flex; } }

.icon-breaker__icon {
  font-size: 7rem; }
  @media (max-width: 47.99em) {
    .icon-breaker__icon {
      margin-bottom: 2.4rem; } }
  @media (min-width: 48em) {
    .icon-breaker__icon {
      flex-shrink: 0;
      font-size: 11rem;
      margin-right: 5rem; } }

.icon-breaker__kicker {
  margin-bottom: 2.4rem; }
  .icon-breaker__kicker .kicker {
    color: #fff;
    opacity: 0.6; }
    @media (min-width: 48em) {
      .icon-breaker__kicker .kicker {
        font-size: 1.8rem; } }

.icon-breaker__text {
  font-family: "Merriweather", "Georgia", serif;
  font-weight: bold; }

.cta-breaker {
  background-image: url("..//images/background/breaker-dark.svg");
  background-position: 20% 100%;
  background-size: cover;
  padding-bottom: 2.4rem;
  padding-top: 2.4rem; }
  @media (min-width: 48em) {
    .cta-breaker {
      background-position: center; } }
  @media (min-width: 75em) {
    .cta-breaker {
      padding-bottom: 6rem;
      padding-top: 6rem; } }
  @media (min-width: 75em) and (max-width: 87.49em) {
    .cta-breaker {
      padding-right: 12rem; } }

.cta-breaker__inner {
  margin-left: auto;
  margin-right: auto;
  max-width: 100%; }
  @media all {
    .cta-breaker__inner {
      padding-left: 2.4rem;
      padding-right: 2.4rem; } }
  @media (min-width: 23.4375em) {
    .cta-breaker__inner {
      padding-left: 2.4rem;
      padding-right: 2.4rem; } }
  @media (min-width: 48em) {
    .cta-breaker__inner {
      padding-left: 2.4rem;
      padding-right: 2.4rem; } }
  @media (min-width: 75em) {
    .cta-breaker__inner {
      padding-left: 2.4rem;
      padding-right: 2.4rem;
      width: 107.2rem; } }
  @media (min-width: 87.5em) {
    .cta-breaker__inner {
      padding-left: 2.4rem;
      padding-right: 2.4rem; } }
  @media (max-width: 47.99em) {
    .cta-breaker__inner {
      text-align: center; } }
  @media (min-width: 48em) {
    .cta-breaker__inner {
      display: grid;
      grid-template: 'cb-1 cb-2' auto 'cb-3 cb-2' auto / 65fr 35fr; } }

@media (min-width: 48em) {
  .cta-breaker__headline {
    grid-area: cb-1; } }

@media (max-width: 47.99em) {
  .cta-breaker__button {
    margin-top: 2.4rem; } }

@media (min-width: 48em) {
  .cta-breaker__button {
    grid-area: cb-2;
    justify-self: flex-end; } }

.cta-breaker__text {
  margin-top: 2.4rem; }
  @media (min-width: 48em) {
    .cta-breaker__text {
      grid-area: cb-3; } }

.element-breaker {
  background-color: #f9f9f9;
  padding-bottom: 2.4rem;
  padding-top: 2.4rem; }
  @media (min-width: 75em) {
    .element-breaker {
      padding-bottom: 6rem;
      padding-top: 6rem; } }

.element-breaker__inner {
  margin-left: auto;
  margin-right: auto;
  max-width: 100%; }
  @media all {
    .element-breaker__inner {
      padding-left: 2.4rem;
      padding-right: 2.4rem; } }
  @media (min-width: 23.4375em) {
    .element-breaker__inner {
      padding-left: 2.4rem;
      padding-right: 2.4rem; } }
  @media (min-width: 48em) {
    .element-breaker__inner {
      padding-left: 2.4rem;
      padding-right: 2.4rem; } }
  @media (min-width: 75em) {
    .element-breaker__inner {
      padding-left: 2.4rem;
      padding-right: 2.4rem;
      width: 107.2rem; } }
  @media (min-width: 87.5em) {
    .element-breaker__inner {
      padding-left: 2.4rem;
      padding-right: 2.4rem; } }
  @media (max-width: 47.99em) {
    .element-breaker__inner {
      text-align: center; } }

.element-breaker__text {
  margin-bottom: 2.4rem; }

.front-stage {
  margin-bottom: 4rem; }
  @media (min-width: 48em) {
    .front-stage {
      margin-bottom: 6rem; } }
  @media (min-width: 75em) {
    .front-stage {
      margin-bottom: 0; } }

.front-stage__inner {
  height: 40rem;
  position: relative; }
  .front-stage--schwanensee .front-stage__inner::before {
    background-image: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #000 100%);
    bottom: 0;
    content: '';
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    z-index: 1; }
  .front-stage--white .front-stage__inner {
    background-color: #fff; }
  .front-stage--black .front-stage__inner {
    background-color: #0e0e0e; }
  @media (min-width: 48em) {
    .front-stage__inner {
      height: 50rem; } }
  @media (min-width: 75em) {
    .front-stage__inner {
      height: 50.8rem; } }

.front-stage__figure .figure__caption {
  bottom: auto;
  top: 0; }

.front-stage__figure .image--cover {
  height: 40rem; }
  @media (min-width: 48em) {
    .front-stage__figure .image--cover {
      height: 50rem; } }
  @media (min-width: 75em) {
    .front-stage__figure .image--cover {
      height: 50.8rem; } }

.front-stage__figure {
  height: 100%; }

.front-stage__video {
  height: 100%;
  object-fit: cover;
  width: 100%; }

.front-stage__video-caption {
  background-color: rgba(0, 0, 0, 0.65);
  bottom: 0;
  color: #fff;
  font-size: 1.2rem;
  letter-spacing: 0.5px;
  line-height: 1.3;
  margin-top: 0.8rem;
  margin-top: 0;
  padding: 0.5rem 1rem;
  position: absolute;
  right: 0; }
  @media (max-width: 47.99em) {
    .front-stage__video-caption {
      max-width: calc(100% - 8rem); } }
  @media (min-width: 48em) {
    .front-stage__video-caption {
      bottom: auto;
      text-align: right;
      top: 0; } }

.front-stage__content {
  margin-left: auto;
  margin-right: auto;
  max-width: 100%;
  height: 100%;
  position: relative;
  transform: translateY(-100%);
  z-index: 2; }
  @media all {
    .front-stage__content {
      padding-left: 2.4rem;
      padding-right: 2.4rem; } }
  @media (min-width: 23.4375em) {
    .front-stage__content {
      padding-left: 2.4rem;
      padding-right: 2.4rem; } }
  @media (min-width: 48em) {
    .front-stage__content {
      padding-left: 2.4rem;
      padding-right: 2.4rem; } }
  @media (min-width: 75em) {
    .front-stage__content {
      padding-left: 2.4rem;
      padding-right: 2.4rem;
      width: 107.2rem; } }
  @media (min-width: 87.5em) {
    .front-stage__content {
      padding-left: 2.4rem;
      padding-right: 2.4rem; } }
  .front-stage--white .front-stage__content,
  .front-stage--black .front-stage__content {
    transform: none; }

.front-stage__content-inner {
  display: flex;
  max-width: calc(100% - 4.8rem);
  position: absolute;
  width: 100%; }
  @media (min-width: 48em) {
    .front-stage__content-inner {
      flex-direction: column;
      padding: 1.5rem 3rem; }
      .front-stage--schwanensee .front-stage__content-inner {
        padding: 0; } }
  @media (max-width: 74.99em) {
    .front-stage__content-inner {
      top: 50%;
      transform: translateY(-50%); }
      .front-stage--schwanensee .front-stage__content-inner {
        bottom: 5rem;
        top: unset;
        transform: none; } }
  @media (min-width: 75em) {
    .front-stage__content-inner {
      bottom: 15rem;
      flex-direction: row; }
      .front-stage--schwanensee .front-stage__content-inner {
        bottom: 10rem; } }

.front-stage__box {
  backdrop-filter: blur(5px);
  background-color: rgba(255, 255, 255, 0.8);
  padding: 1.5rem; }
  .front-stage--schwanensee .front-stage__box {
    backdrop-filter: none;
    background-color: transparent;
    padding: 0; }
  @media (min-width: 48em) {
    .front-stage__box {
      max-width: 100%;
      padding: 2rem; }
      .front-stage--schwanensee .front-stage__box {
        padding: 0; } }
  @media (min-width: 75em) {
    .front-stage__box {
      max-width: 45%;
      padding-right: 2rem; }
      .front-stage--schwanensee .front-stage__box {
        max-width: 100%;
        padding: 0; } }

.front-stage__headline-kicker {
  font-size: 1.2rem;
  margin-bottom: 1rem; }
  .front-stage--schwanensee .front-stage__headline-kicker {
    color: #fff; }
  .front-stage__headline-block .front-stage__headline-kicker {
    color: #fff;
    font-size: 3rem; }
  .front-stage--white .front-stage__headline-kicker {
    color: #0e0e0e; }
  .front-stage__headline-block--text-black .front-stage__headline-kicker {
    color: #0e0e0e; }

.front-stage__headline {
  color: #0c55a6;
  font-size: 2rem;
  line-height: 1.35;
  padding-bottom: 1.5rem;
  position: relative;
  z-index: 2; }
  .front-stage--schwanensee .front-stage__headline {
    color: #fff; }
  .front-stage__headline-block .front-stage__headline {
    color: #fff;
    font-size: 4rem;
    font-weight: bold;
    line-height: 1.1;
    padding-bottom: 2rem; }
    @media (min-width: 48em) {
      .front-stage__headline-block .front-stage__headline {
        font-size: 6rem; } }
  .front-stage--white .front-stage__headline {
    color: #0e0e0e; }
  .front-stage__headline-block--text-black .front-stage__headline {
    color: #0e0e0e; }
  .front-stage__headline-block .front-stage__headline::after {
    display: none; }
  .front-stage__headline::after {
    background-color: #0c55a6;
    bottom: 0;
    content: '';
    display: block;
    height: 2px;
    left: 0;
    position: absolute;
    width: 2.5rem;
    /* stylelint-disable-next-line max-nesting-depth */ }
    .front-stage--schwanensee .front-stage__headline::after {
      background-color: #fff; }
    @media (min-width: 48em) {
      .front-stage__headline::after {
        bottom: 1.5rem;
        width: 4rem; } }
    @media (min-width: 75em) {
      .front-stage__headline::after {
        bottom: 2rem;
        width: 6rem; } }
  @media (min-width: 48em) {
    .front-stage__headline {
      font-size: 3.5rem;
      padding-bottom: 5rem; } }

@media (max-width: 47.99em) {
  .front-stage__teaser--mobile {
    margin-left: auto;
    margin-right: auto;
    max-width: 100%;
    padding-bottom: 2rem;
    padding-top: 2rem; } }
  @media all and (max-width: 47.99em) {
    .front-stage__teaser--mobile {
      padding-left: 2.4rem;
      padding-right: 2.4rem; } }
  @media (max-width: 47.99em) and (min-width: 23.4375em) {
    .front-stage__teaser--mobile {
      padding-left: 2.4rem;
      padding-right: 2.4rem; } }
  @media (max-width: 47.99em) and (min-width: 48em) {
    .front-stage__teaser--mobile {
      padding-left: 2.4rem;
      padding-right: 2.4rem; } }
  @media (max-width: 47.99em) and (min-width: 75em) {
    .front-stage__teaser--mobile {
      padding-left: 2.4rem;
      padding-right: 2.4rem;
      width: 107.2rem; } }
  @media (max-width: 47.99em) and (min-width: 87.5em) {
    .front-stage__teaser--mobile {
      padding-left: 2.4rem;
      padding-right: 2.4rem; } }

@media (min-width: 48em) {
  .front-stage__teaser--mobile {
    display: none; } }

@media (max-width: 47.99em) {
  .front-stage__teaser--desktop {
    display: none; } }

@media (min-width: 48em) {
  .front-stage__teaser--desktop {
    backdrop-filter: blur(5px);
    background: linear-gradient(127deg, #0184ca 0%, #094381 100%);
    color: #fff;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    max-width: 100%;
    opacity: 0.95;
    padding: 2rem;
    position: relative; } }

@media (min-width: 75em) {
  .front-stage__teaser--desktop {
    max-width: 35%; } }

.front-stage__teaser-date {
  display: block;
  font-size: 1.2rem;
  margin-bottom: 1rem; }

.front-stage__teaser:hover .front-stage__teaser-headline {
  text-decoration: underline; }

@media (min-width: 48em) {
  .front-stage__teaser-headline {
    font-size: 2.5rem; } }

.front-stage__teaser-icon {
  font-size: 1.5rem;
  margin-top: 1rem;
  text-align: right; }
  @media (min-width: 48em) {
    .front-stage__teaser-icon {
      font-size: 2.4rem;
      margin-top: 1.5rem; } }

.front-stage__search-bar {
  margin-top: -2.8rem; }
  @media (min-width: 48em) {
    .front-stage__search-bar {
      margin-top: -3.2rem; } }
  @media (max-width: 47.99em) {
    .front-stage__search-bar {
      display: none; } }

.front-stage__tag-list {
  margin-left: auto;
  margin-right: auto;
  max-width: 100%;
  margin-top: 1.5rem; }
  @media all {
    .front-stage__tag-list {
      padding-left: 2.4rem;
      padding-right: 2.4rem; } }
  @media (min-width: 23.4375em) {
    .front-stage__tag-list {
      padding-left: 2.4rem;
      padding-right: 2.4rem; } }
  @media (min-width: 48em) {
    .front-stage__tag-list {
      padding-left: 2.4rem;
      padding-right: 2.4rem; } }
  @media (min-width: 75em) {
    .front-stage__tag-list {
      padding-left: 2.4rem;
      padding-right: 2.4rem;
      width: 107.2rem; } }
  @media (min-width: 87.5em) {
    .front-stage__tag-list {
      padding-left: 2.4rem;
      padding-right: 2.4rem; } }
  @media (min-width: 48em) {
    .front-stage__tag-list {
      margin-top: 3rem; } }
  @media (min-width: 75em) {
    .front-stage__tag-list {
      margin-top: 5rem; } }

.front-stage__headline-block {
  width: 100%; }

.front-stage__headline-block--left {
  text-align: left; }

.front-stage__headline-block--center {
  text-align: center; }

.front-stage__headline-block--right {
  text-align: right; }

.facts-breaker__inner {
  height: 65rem;
  position: relative; }
  @media (min-width: 48em) {
    .facts-breaker__inner {
      height: 70rem; } }

.facts-breaker__figure .figure__caption {
  bottom: auto;
  top: 0; }

.facts-breaker__figure .image--cover {
  height: 65rem; }
  @media (min-width: 48em) {
    .facts-breaker__figure .image--cover {
      height: 70rem; } }

.facts-breaker__content-wrap {
  margin-left: auto;
  margin-right: auto;
  max-width: 100%;
  position: relative; }
  @media all {
    .facts-breaker__content-wrap {
      padding-left: 2.4rem;
      padding-right: 2.4rem; } }
  @media (min-width: 23.4375em) {
    .facts-breaker__content-wrap {
      padding-left: 2.4rem;
      padding-right: 2.4rem; } }
  @media (min-width: 48em) {
    .facts-breaker__content-wrap {
      padding-left: 2.4rem;
      padding-right: 2.4rem; } }
  @media (min-width: 75em) {
    .facts-breaker__content-wrap {
      padding-left: 2.4rem;
      padding-right: 2.4rem;
      width: 107.2rem; } }
  @media (min-width: 87.5em) {
    .facts-breaker__content-wrap {
      padding-left: 2.4rem;
      padding-right: 2.4rem; } }

.facts-breaker__content {
  bottom: 2.4rem;
  position: absolute;
  width: calc(100% - 4.8rem); }
  @media (min-width: 48em) {
    .facts-breaker__content {
      bottom: 8rem; } }

.facts-breaker__headline {
  color: #fff;
  font-size: 3rem;
  font-weight: bold;
  line-height: 1.2;
  margin-bottom: 2rem;
  text-shadow: 0 1px 1px rgba(0, 0, 0, 0.25); }
  @media (min-width: 48em) {
    .facts-breaker__headline {
      font-size: 4rem;
      margin-bottom: 4rem; } }
  @media (min-width: 75em) {
    .facts-breaker__headline {
      font-size: 5rem;
      margin-bottom: 7.5rem; } }

.facts-breaker__list {
  backdrop-filter: blur(5px);
  background-color: rgba(255, 255, 255, 0.8);
  color: #0c55a6;
  padding: 2.5rem 1rem; }
  @media (min-width: 48em) {
    .facts-breaker__list {
      display: flex;
      flex-wrap: wrap;
      padding: 2.5rem 2.5rem 0;
      position: relative; } }
  @media (min-width: 75em) {
    .facts-breaker__list {
      padding: 3rem 3rem 0; } }

.facts-breaker__list-item {
  text-align: center; }
  @media (max-width: 47.99em) {
    .facts-breaker__list-item:not(:last-child) {
      margin-bottom: 3rem; } }
  @media (min-width: 48em) {
    .facts-breaker__list-item {
      display: flex;
      flex-basis: 33.3333%;
      flex-direction: column;
      justify-content: center;
      margin-bottom: 3rem;
      padding: 0 2rem;
      position: relative; }
      .facts-breaker__list-item:not(:nth-child(3n))::after {
        background-color: #0c55a6;
        content: '';
        display: block;
        height: 100%;
        position: absolute;
        right: 0;
        top: 0;
        width: 2px; } }
  @media (min-width: 75em) {
    .facts-breaker__list-item {
      padding: 0 3rem; } }

.facts-breaker__list-term {
  font-family: "Merriweather", "Georgia", serif;
  font-size: 1.8rem;
  font-weight: bold;
  margin-bottom: 0.5rem; }
  @media (min-width: 48em) {
    .facts-breaker__list-term {
      font-size: 2.2rem;
      margin-bottom: 1rem; } }
  @media (min-width: 75em) {
    .facts-breaker__list-term {
      font-size: 3rem;
      margin-bottom: 1.5rem; } }

.facts-breaker__list-def {
  margin-left: 0; }
  @media (min-width: 75em) {
    .facts-breaker__list-def {
      font-size: 2rem; } }

.facts-breaker__teaser {
  margin-left: auto;
  margin-right: auto;
  max-width: 100%; }
  @media all {
    .facts-breaker__teaser {
      padding-left: 2.4rem;
      padding-right: 2.4rem; } }
  @media (min-width: 23.4375em) {
    .facts-breaker__teaser {
      padding-left: 2.4rem;
      padding-right: 2.4rem; } }
  @media (min-width: 48em) {
    .facts-breaker__teaser {
      padding-left: 2.4rem;
      padding-right: 2.4rem; } }
  @media (min-width: 75em) {
    .facts-breaker__teaser {
      padding-left: 2.4rem;
      padding-right: 2.4rem;
      width: 107.2rem; } }
  @media (min-width: 87.5em) {
    .facts-breaker__teaser {
      padding-left: 2.4rem;
      padding-right: 2.4rem; } }
  @media (min-width: 48em) {
    .facts-breaker__teaser {
      display: flex;
      margin-top: 4rem; } }

@media (max-width: 47.99em) {
  .facts-breaker__text {
    margin-top: 3rem; } }

@media (min-width: 48em) {
  .facts-breaker__text {
    padding-right: 5rem; } }

@media (min-width: 75em) {
  .facts-breaker__text {
    padding-right: 10rem; } }

@media (max-width: 47.99em) {
  .facts-breaker__button {
    margin-top: 2rem; } }

@media (min-width: 48em) {
  .facts-breaker__button {
    flex-shrink: 0; } }

.person-grid__items {
  column-gap: 2rem;
  display: grid;
  grid-template-columns: 1fr 1fr;
  row-gap: 2rem; }
  @media (min-width: 48em) {
    .person-grid__items {
      column-gap: 3.2rem;
      grid-template-columns: 1fr 1fr 1fr;
      row-gap: 3.2rem; } }
  @media (min-width: 75em) {
    .person-grid__items {
      grid-template-columns: 1fr 1fr 1fr 1fr; } }

.person-grid__button {
  margin-top: 5.6rem;
  text-align: center; }
  @media (max-width: 74.99em) {
    .person-grid__button {
      margin-top: 2.4rem; } }

.triple-grid__featured-wrapper {
  margin-bottom: 3rem; }

.triple-grid__featured-item:not(:last-child) {
  margin-bottom: 2rem; }

.triple-grid__items {
  column-gap: 2rem;
  display: grid;
  grid-template-columns: 1fr 1fr;
  row-gap: 2rem; }
  @media (min-width: 48em) {
    .triple-grid__items {
      column-gap: 3.2rem;
      grid-template-columns: 1fr 1fr 1fr;
      row-gap: 3.2rem; } }

.newsletter-breaker {
  background-image: url("..//images/background/breaker-dark.svg");
  background-position: 20% 100%;
  background-size: cover;
  padding-bottom: 2.4rem;
  padding-top: 2.4rem; }
  @media (min-width: 48em) {
    .newsletter-breaker {
      background-position: center; } }
  @media (min-width: 75em) {
    .newsletter-breaker {
      padding-bottom: 6rem;
      padding-top: 6rem; } }
  @media (min-width: 75em) and (max-width: 87.49em) {
    .newsletter-breaker {
      padding-right: 12rem; } }

.newsletter-breaker__inner {
  margin-left: auto;
  margin-right: auto;
  max-width: 100%; }
  @media all {
    .newsletter-breaker__inner {
      padding-left: 2.4rem;
      padding-right: 2.4rem; } }
  @media (min-width: 23.4375em) {
    .newsletter-breaker__inner {
      padding-left: 2.4rem;
      padding-right: 2.4rem; } }
  @media (min-width: 48em) {
    .newsletter-breaker__inner {
      padding-left: 2.4rem;
      padding-right: 2.4rem; } }
  @media (min-width: 75em) {
    .newsletter-breaker__inner {
      padding-left: 2.4rem;
      padding-right: 2.4rem;
      width: 107.2rem; } }
  @media (min-width: 87.5em) {
    .newsletter-breaker__inner {
      padding-left: 2.4rem;
      padding-right: 2.4rem; } }

.newsletter-breaker__form {
  margin-top: 3.2rem; }
  @media (min-width: 48em) {
    .newsletter-breaker__form {
      align-items: flex-end;
      display: flex; } }

@media (max-width: 47.99em) {
  .newsletter-breaker__form-group {
    margin-bottom: 1rem; } }

@media (min-width: 48em) {
  .newsletter-breaker__form-group {
    flex-grow: 1; } }

@media (max-width: 47.99em) {
  .newsletter-breaker__button {
    max-width: 100%;
    min-height: 0;
    padding: 0.5rem 1.5rem; } }

.featured-breaker__inner {
  height: 20rem;
  position: relative; }
  @media (min-width: 48em) {
    .featured-breaker__inner {
      height: 35rem; } }
  @media (min-width: 75em) {
    .featured-breaker__inner {
      height: 51.5rem; } }

.featured-breaker__figure .image--cover {
  height: 20rem; }
  @media (min-width: 48em) {
    .featured-breaker__figure .image--cover {
      height: 35rem; } }
  @media (min-width: 75em) {
    .featured-breaker__figure .image--cover {
      height: 51.5rem; } }

@media (min-width: 48em) {
  .featured-breaker__figure .figure__caption {
    bottom: auto;
    top: 0; } }

.featured-breaker__text {
  margin-left: auto;
  margin-right: auto;
  max-width: 100%;
  color: #0e0e0e;
  display: flex;
  flex-direction: column;
  height: 100%;
  left: 50%;
  position: absolute;
  top: 0;
  transform: translateX(-50%);
  width: 100%;
  z-index: 1; }
  @media all {
    .featured-breaker__text {
      padding-left: 2.4rem;
      padding-right: 2.4rem; } }
  @media (min-width: 23.4375em) {
    .featured-breaker__text {
      padding-left: 2.4rem;
      padding-right: 2.4rem; } }
  @media (min-width: 48em) {
    .featured-breaker__text {
      padding-left: 2.4rem;
      padding-right: 2.4rem; } }
  @media (min-width: 75em) {
    .featured-breaker__text {
      padding-left: 2.4rem;
      padding-right: 2.4rem;
      width: 107.2rem; } }
  @media (min-width: 87.5em) {
    .featured-breaker__text {
      padding-left: 2.4rem;
      padding-right: 2.4rem; } }
  .featured-breaker--inverted .featured-breaker__text {
    color: #fff;
    text-shadow: 0 1px 1px rgba(0, 0, 0, 0.25); }
  .featured-breaker--right .featured-breaker__text {
    align-items: flex-end;
    text-align: right; }
  .featured-breaker--center .featured-breaker__text {
    align-items: center;
    text-align: center; }
  @media (max-width: 47.99em) {
    .featured-breaker__text {
      display: flex;
      flex-direction: column;
      justify-content: center; } }
  @media (min-width: 48em) {
    .featured-breaker__text {
      padding-bottom: 9rem;
      padding-top: 6rem; } }

.featured-breaker__kicker {
  color: #0e0e0e;
  margin-bottom: 2rem;
  position: relative; }
  .featured-breaker--inverted .featured-breaker__kicker {
    color: #fff; }
  .featured-breaker__kicker::after {
    background-color: #0e0e0e;
    bottom: -1rem;
    content: '';
    height: 1px;
    left: 0;
    position: absolute;
    width: 3rem; }
  .featured-breaker--inverted .featured-breaker__kicker::after {
    background-color: #fff; }
  .featured-breaker--right .featured-breaker__kicker::after {
    left: auto;
    right: 0; }
  .featured-breaker--center .featured-breaker__kicker::after {
    left: 50%;
    transform: translateX(-50%); }
  @media (min-width: 48em) {
    .featured-breaker__kicker {
      font-size: 1.6rem;
      margin-bottom: 3rem; }
      .featured-breaker__kicker::after {
        bottom: -1.5rem;
        width: 4rem; } }
  @media (min-width: 75em) {
    .featured-breaker__kicker {
      font-size: 1.8rem;
      margin-bottom: 5rem; }
      .featured-breaker__kicker::after {
        width: 5.5rem; } }

.featured-breaker__headline {
  font-size: 2rem;
  line-height: 1.2; }
  @media (min-width: 48em) {
    .featured-breaker__headline {
      font-size: 3rem; } }
  @media (min-width: 75em) {
    .featured-breaker__headline {
      font-size: 5rem;
      max-width: 80%; } }

.featured-breaker__content {
  margin-left: auto;
  margin-right: auto;
  max-width: 100%; }
  @media all {
    .featured-breaker__content {
      padding-left: 2.4rem;
      padding-right: 2.4rem; } }
  @media (min-width: 23.4375em) {
    .featured-breaker__content {
      padding-left: 2.4rem;
      padding-right: 2.4rem; } }
  @media (min-width: 48em) {
    .featured-breaker__content {
      padding-left: 2.4rem;
      padding-right: 2.4rem; } }
  @media (min-width: 75em) {
    .featured-breaker__content {
      padding-left: 2.4rem;
      padding-right: 2.4rem;
      width: 107.2rem; } }
  @media (min-width: 87.5em) {
    .featured-breaker__content {
      padding-left: 2.4rem;
      padding-right: 2.4rem; } }
  @media (max-width: 47.99em) {
    .featured-breaker__content {
      margin-top: 3.2rem; } }
  @media (min-width: 48em) {
    .featured-breaker__content {
      display: grid;
      grid-gap: 3.2rem;
      grid-template-columns: 1fr 1fr;
      margin-top: -7.5rem; } }

@media (max-width: 47.99em) {
  .featured-breaker__item:not(:first-child) {
    margin-top: 3.2rem; } }

@media (min-width: 48em) {
  .featured-breaker__item {
    position: relative; }
    .featured-breaker__item > * {
      box-shadow: 0 2px 15px 0 rgba(0, 0, 0, 0.08); } }

@media (min-width: 48em) {
  .social-teaser-grid {
    display: grid;
    grid-gap: 3.2rem;
    grid-template-columns: 1fr 1fr; } }

@media (max-width: 47.99em) {
  .social-teaser-grid__item:not(:last-child) {
    margin-bottom: 2rem; } }

.map__marker-inner {
  position: relative; }

.map__marker-button {
  bottom: 0;
  color: #868686;
  height: 4rem;
  left: -2rem;
  position: absolute;
  transition: color 350ms ease;
  width: 4rem; }
  .map__marker-button[aria-selected='true'] {
    color: #0c55a6; }

.map__mapbox {
  height: 400px;
  width: 100%; }
  @media (min-width: 48em) {
    .map__mapbox {
      height: 600px; } }

.mapbox-grid__inner {
  display: flex;
  flex-wrap: wrap;
  margin: -0.5rem; }

.mapbox-grid__map {
  padding: 0.5rem;
  width: 100%; }

@media (min-width: 48em) {
  .mapbox-grid__map--5 {
    width: calc((100%/12)*5); } }

@media (min-width: 48em) {
  .mapbox-grid__map--7 {
    width: calc((100%/12)*7); } }

.cart__table {
  width: 100%; }
  @media (max-width: 47.99em) {
    .cart__table {
      display: block; } }

@media (max-width: 47.99em) {
  .cart__table-head {
    border: 0;
    clip: rect(0 0 0 0);
    clip-path: inset(50%);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    white-space: nowrap;
    width: 1px; } }

.cart__table-row {
  border-bottom: 1px solid #e2e2e2; }
  @media (max-width: 47.99em) {
    .cart__table-row {
      display: block; } }

.cart__table-head,
.cart__table-data {
  padding: 1.5rem 2rem;
  text-align: left;
  vertical-align: top; }
  @media (max-width: 47.99em) {
    .cart__table-head,
    .cart__table-data {
      display: block;
      padding: 2rem 0; } }

@media (max-width: 47.99em) {
  .cart__table-data::before {
    content: attr(data-label);
    display: block;
    font-weight: bold;
    margin-bottom: 2rem;
    text-align: left; } }

@media (min-width: 48em) {
  .cart__table-data--teaser {
    display: flex; } }

.cart__table-data--amount {
  max-width: 24rem; }

.cart__teaser-image {
  max-width: 14rem; }
  @media (max-width: 47.99em) {
    .cart__teaser-image {
      margin-bottom: 2rem; } }
  @media (min-width: 48em) {
    .cart__teaser-image {
      flex-shrink: 0;
      margin-right: 2.5rem; } }

.cart__teaser-kicker {
  margin-bottom: 1rem; }

.cart__teaser-text {
  color: #535353;
  margin-top: 1rem; }

.cart__amount {
  display: flex; }

.cart__amount-input {
  border-right: 0; }

.cart__amount-submit {
  background-color: #e2e2e2;
  border: 2px solid #e2e2e2;
  border-left: 0;
  height: 3.2rem;
  padding-left: calc(0.5rem + 2px);
  padding-right: 0.5rem; }
  .cart__amount-submit:hover, .cart__amount-submit:focus {
    background-color: #0c55a6;
    border-color: #0c55a6;
    color: #fff; }

.cart__amount-submit-icon {
  font-size: 2rem; }

.cart__amount-text {
  color: #535353;
  font-size: 1.2rem;
  line-height: 1.4;
  margin-top: 1rem; }

.cart__amount-text-icon {
  margin: 0 0.2rem; }

.cart__remove-link {
  font-size: 1.2rem;
  white-space: nowrap; }
  .cart__remove-link:hover {
    text-decoration: underline; }

.cart__remove-link-icon {
  background-color: #e2e2e2;
  border-radius: 50%;
  font-size: 1.7rem;
  margin-right: 0.5rem;
  margin-top: 2rem;
  padding: 2px;
  vertical-align: sub; }

.cart__info {
  margin-top: 2rem; }
  @media (min-width: 48em) {
    .cart__info {
      display: flex;
      justify-content: space-between;
      margin-top: 4rem; } }

.cart__info-content {
  display: flex; }
  @media (min-width: 48em) {
    .cart__info-content {
      flex-basis: 50%; } }

.cart__info-icon {
  flex-shrink: 0;
  margin-right: 0.5rem;
  margin-top: 0.5rem; }

@media (max-width: 47.99em) {
  .cart__prices {
    margin-top: 2rem; } }

@media (min-width: 48em) {
  .cart__prices {
    padding: 0 4rem; } }

.form__headline {
  margin-bottom: 3rem; }

.form__field {
  margin-bottom: 2rem;
  width: 100%; }
  @media (min-width: 48em) {
    .form__field {
      margin-bottom: 3rem; } }

@media (min-width: 48em) {
  .form__field-column {
    column-gap: 3rem;
    display: grid;
    grid-template-columns: 1fr 1fr; } }

.form__prices {
  margin: 4rem 0;
  max-width: 40rem; }

.form__text {
  display: flex; }
  @media (min-width: 48em) {
    .form__text {
      flex-basis: 50%; } }

.form__text-icon {
  flex-shrink: 0;
  margin-right: 0.5rem;
  margin-top: 0.5rem; }

.form__button {
  margin-top: 3rem; }

@media (min-width: 48em) {
  .person-feature {
    align-items: center;
    display: flex;
    justify-content: space-between; } }

@media (max-width: 47.99em) {
  .person-feature__text + .person-feature__person {
    margin-top: 3rem; } }

@media (min-width: 48em) {
  .person-feature__person {
    flex-shrink: 0;
    max-width: 35%; } }

@media (max-width: 47.99em) {
  .person-feature__person + .person-feature__text {
    margin-top: 3rem; } }

@media (min-width: 48em) {
  .person-feature__text {
    max-width: 60%; } }

.person-detail .person-detail__inner {
  display: flex;
  flex-direction: column;
  position: relative; }
  @media (min-width: 75em) {
    .person-detail .person-detail__inner {
      display: block;
      flex-direction: unset;
      margin: 0 -2.4rem; } }

@media (min-width: 75em) {
  .person-detail .person-detail__col-1 {
    float: left;
    padding: 0 2.4rem;
    width: 40%; } }

@media (min-width: 75em) {
  .person-detail .person-detail__col-2 {
    float: left;
    padding: 0 2.4rem;
    width: 60%; } }

.person-detail .person-detail__img {
  width: 100%; }

.person-detail .person-detail__socialmedia {
  margin-bottom: 3rem; }

.person-detail .person-detail__header {
  margin: 0 0 3rem; }
  @media (min-width: 75em) {
    .person-detail .person-detail__header {
      border-bottom: 1px solid #e2e2e2;
      margin: 0 2.4rem 3rem;
      padding-bottom: 3rem; } }

.person-detail .person-detail__socialmedia-link {
  margin: 2rem 0; }
  @media (min-width: 75em) {
    .person-detail .person-detail__socialmedia-link {
      margin: 2rem 0 0; } }

.person-detail .person-detail__socialmedia-link:first-child {
  margin-top: 4rem; }

.person-detail .person-detail__name {
  margin-bottom: 1.5rem; }

.person-detail .person-detail__role {
  margin-bottom: 0.5rem; }

.person-detail .person-detail__slogan {
  color: #868686; }

.person-detail .person-detail__section {
  margin: 0 0 2rem; }

.person-detail .person-detail__section-title {
  padding: 1.5rem 0; }

.person-detail .person-detail__section-text {
  border-top: 1px solid #e2e2e2;
  padding: 2rem 0;
  /* stylelint-disable-next-line max-nesting-depth */
  /* stylelint-disable-next-line max-nesting-depth */ }
  .person-detail .person-detail__section-text.js-link, .person-detail .person-detail__section-text.js-link-external {
    padding: 1.5rem 0; }
  .person-detail .person-detail__section-text .link {
    /* stylelint-disable-line */ }
  @media (min-width: 75em) {
    .person-detail .person-detail__section-text {
      padding: 2rem; }
      .person-detail .person-detail__section-text.js-link, .person-detail .person-detail__section-text.js-link-external {
        padding: 1.5rem 2rem; } }

.person-detail .person-detail__section-text:last-child {
  border-bottom: 1px solid #e2e2e2; }

.person-detail__downloads {
  border-top: 1px solid #e2e2e2; }
  .person-detail__downloads .person-detail__downloads-title {
    padding: 1.5rem 0; }
  .person-detail__downloads .person-detail__downloads-link:not(:last-child) {
    margin-bottom: 1rem; }
  .person-detail__downloads .icon--download-2 {
    height: 0.5em;
    width: 0.5em; }

.logo-grid {
  list-style: none;
  margin: 0;
  padding-left: 0;
  display: flex;
  flex-wrap: wrap; }

.logo-grid__item {
  flex-basis: calc(50% - 2.5rem); }
  .logo-grid__item:nth-child(odd) {
    margin-right: 2.5rem; }
  .logo-grid__item:not(:nth-last-child(-n+2)) {
    margin-bottom: 2rem; }

.link-tabs__nav-wrapper {
  padding: 0 2.4rem;
  position: relative; }
  @media (max-width: 47.99em) {
    .link-tabs__nav-wrapper {
      background-color: #f9f9f9;
      margin-left: -2.4rem;
      margin-right: -2.4rem; } }
  @media (min-width: 48em) {
    .link-tabs__nav-wrapper {
      border-bottom: 1px solid #e2e2e2;
      padding: 0 3.5rem; } }

.link-tabs__button {
  height: 100%;
  position: absolute;
  top: 0;
  width: 2.4rem; }
  .link-tabs__button .scrollable__button {
    align-items: center;
    display: flex;
    font-size: 2.4rem;
    height: 100%; }
  .link-tabs__button .scrollable__button:hover {
    color: #0c55a6; }
  @media (max-width: 47.99em) {
    .link-tabs__button {
      display: none; } }

.link-tabs__button--left {
  left: 0; }

.link-tabs__button--right {
  right: 0; }

.link-tabs__button--disabled {
  display: none; }

.link-tabs__nav {
  list-style: none;
  margin: 0;
  padding-left: 0;
  display: flex;
  -ms-overflow-style: none;
  overflow-x: auto;
  overflow-y: hidden;
  padding: 2rem 0;
  scrollbar-width: none; }
  @media (min-width: 48em) {
    .link-tabs__nav {
      padding: 1rem 0 calc(1rem + 4px); } }

.link-tabs__nav::-webkit-scrollbar {
  display: none; }

.link-tabs__item {
  margin-right: 2rem; }
  .link-tabs__item:last-child {
    margin-right: 0; }
  @media (min-width: 48em) {
    .link-tabs__item {
      margin-right: 3rem; } }

.link-tabs__link {
  color: #868686;
  font-size: 1.8rem;
  letter-spacing: 0.8px;
  line-height: 1.2;
  padding: 1rem 0;
  white-space: nowrap; }
  .link-tabs__link:hover, .link-tabs__link:focus {
    color: #0c55a6; }
  .t-cond .link-tabs__link:hover,
  .t-cond .link-tabs__link:focus {
    border-bottom: 4px solid #e2e2e2;
    color: #0e0e0e; }
  .link-tabs__link[aria-selected='true'] {
    border-bottom: 4px solid #0c55a6;
    color: #0e0e0e; }
  .t-cond .link-tabs__link[aria-selected='true'] {
    border-bottom: 4px solid #0e0e0e;
    color: #0e0e0e; }
  @media (min-width: 48em) {
    .link-tabs__link {
      padding: 1rem 1.5rem; } }

.link-tabs__content[aria-hidden='true'] {
  display: none; }

@media (min-width: 48em) {
  .link-tabs__content {
    margin-top: 3rem; } }

.link-tabs__content-block:not(:last-child) {
  margin-bottom: 2.8rem; }
  @media (min-width: 48em) {
    .link-tabs__content-block:not(:last-child) {
      margin-bottom: 4rem; } }

.link-tabs__content-block--media {
  max-width: 67rem; }

.quote-breaker {
  background-image: url("..//images/background/breaker-dark.svg");
  background-position: 20% 100%;
  background-size: cover;
  padding-bottom: 2.4rem;
  padding-top: 2.4rem; }
  @media (min-width: 48em) {
    .quote-breaker {
      background-position: center; } }
  @media (min-width: 75em) {
    .quote-breaker {
      padding-bottom: 6rem;
      padding-top: 6rem; } }
  @media (min-width: 75em) and (max-width: 87.49em) {
    .quote-breaker {
      padding-right: 12rem; } }

.t-cond .quote-breaker {
  background-color: #0e0e0e;
  background-image: none; }

.quote-breaker__inner {
  margin-left: auto;
  margin-right: auto;
  max-width: 100%; }
  @media all {
    .quote-breaker__inner {
      padding-left: 2.4rem;
      padding-right: 2.4rem; } }
  @media (min-width: 23.4375em) {
    .quote-breaker__inner {
      padding-left: 2.4rem;
      padding-right: 2.4rem; } }
  @media (min-width: 48em) {
    .quote-breaker__inner {
      padding-left: 2.4rem;
      padding-right: 2.4rem; } }
  @media (min-width: 75em) {
    .quote-breaker__inner {
      padding-left: 2.4rem;
      padding-right: 2.4rem;
      width: 107.2rem; } }
  @media (min-width: 87.5em) {
    .quote-breaker__inner {
      padding-left: 2.4rem;
      padding-right: 2.4rem; } }

.quote-breaker__text {
  font-family: "Merriweather", "Georgia", serif;
  font-size: 2rem;
  font-weight: bold;
  line-height: 1.5; }
  @media (min-width: 48em) {
    .quote-breaker__text {
      font-size: 3rem; } }

.quote-breaker__text p {
  quotes: '»' '«'; }
  .quote-breaker__text p::before {
    content: open-quote; }
  .quote-breaker__text p::after {
    content: close-quote; }

.quote-breaker__source {
  line-height: 1.3;
  margin-top: 2rem; }
  @media (min-width: 48em) {
    .quote-breaker__source {
      margin-top: 4rem; } }

.quote-breaker__name {
  font-size: 2rem; }

.quote-breaker__description {
  font-size: 1.2rem;
  margin-top: 1rem; }

.grid {
  position: relative; }

.grid__items {
  display: grid;
  gap: 1.6rem; }
  @media (min-width: 48em) {
    .grid__items {
      grid-template-columns: repeat(2, 1fr); }
      .grid__items .grid__item--full {
        grid-column: 1 / 3; } }
  @media (min-width: 75em) {
    .grid__items {
      gap: 3.2rem;
      grid-template-columns: repeat(4, 1fr); }
      .grid__items .grid__item--full {
        grid-column: 1 / 5; } }

@media (min-width: 48em) {
  .grid--33 .grid__items {
    grid-template-columns: repeat(2, 1fr); } }

@media (min-width: 75em) {
  .grid--33 .grid__items {
    grid-template-columns: repeat(3, 1fr); } }

@media (min-width: 48em) {
  .grid--50 .grid__items {
    grid-template-columns: repeat(2, 1fr); } }

@media (min-width: 75em) {
  .grid--50 .grid__items {
    grid-template-columns: repeat(2, 1fr); } }

@media (min-width: 48em) {
  .grid--33-66 .grid__items {
    grid-template-columns: repeat(2, 1fr); } }

@media (min-width: 75em) {
  .grid--33-66 .grid__items {
    grid-template-columns: 0.333fr 0.666fr; } }

@media (min-width: 48em) {
  .grid--66-33 .grid__items {
    grid-template-columns: repeat(2, 1fr); } }

@media (min-width: 75em) {
  .grid--66-33 .grid__items {
    grid-template-columns: 0.666fr 0.333fr; } }

.infobox-breaker__inner {
  height: 35rem;
  position: relative; }
  @media (min-width: 48em) {
    .infobox-breaker__inner {
      height: 35rem; } }
  @media (min-width: 75em) {
    .infobox-breaker__inner {
      height: 51.5rem; } }

.infobox-breaker__figure {
  position: relative; }
  .t-cond .infobox-breaker__figure::before {
    background-image: linear-gradient(360deg, rgba(0, 0, 0, 0) 0%, #000 100%);
    bottom: 0;
    content: '';
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    z-index: 1; }
  .infobox-breaker__figure .image--cover {
    height: 35rem; }
    @media (min-width: 48em) {
      .infobox-breaker__figure .image--cover {
        height: 35rem; } }
    @media (min-width: 75em) {
      .infobox-breaker__figure .image--cover {
        height: 51.5rem; } }
  @media (min-width: 48em) {
    .infobox-breaker__figure .figure__caption {
      bottom: 0;
      top: auto; } }

.infobox-breaker__text {
  margin-left: auto;
  margin-right: auto;
  max-width: 100%;
  color: #0e0e0e;
  display: flex;
  flex-direction: column;
  height: 100%;
  left: 50%;
  position: absolute;
  top: 0;
  transform: translateX(-50%);
  width: 100%;
  z-index: 1; }
  @media all {
    .infobox-breaker__text {
      padding-left: 2.4rem;
      padding-right: 2.4rem; } }
  @media (min-width: 23.4375em) {
    .infobox-breaker__text {
      padding-left: 2.4rem;
      padding-right: 2.4rem; } }
  @media (min-width: 48em) {
    .infobox-breaker__text {
      padding-left: 2.4rem;
      padding-right: 2.4rem; } }
  @media (min-width: 75em) {
    .infobox-breaker__text {
      padding-left: 2.4rem;
      padding-right: 2.4rem;
      width: 107.2rem; } }
  @media (min-width: 87.5em) {
    .infobox-breaker__text {
      padding-left: 2.4rem;
      padding-right: 2.4rem; } }
  .t-cond .infobox-breaker__text {
    color: #fff; }
  .infobox-breaker--inverted .infobox-breaker__text {
    color: #fff;
    text-shadow: 0 1px 1px rgba(0, 0, 0, 0.25); }
  .infobox-breaker--right .infobox-breaker__text {
    align-items: flex-end;
    text-align: right; }
  .infobox-breaker--center .infobox-breaker__text {
    align-items: center;
    text-align: center; }
  @media (max-width: 47.99em) {
    .infobox-breaker__text {
      display: flex;
      flex-direction: column;
      justify-content: center; } }
  @media (min-width: 48em) {
    .infobox-breaker__text {
      padding-bottom: 9rem;
      padding-top: 6rem; } }

.infobox-breaker__kicker {
  color: #0e0e0e;
  margin-bottom: 2rem;
  position: relative; }
  .t-cond .infobox-breaker__kicker {
    color: #fff; }
  .infobox-breaker--inverted .infobox-breaker__kicker {
    color: #fff; }
  .infobox-breaker__kicker::after {
    background-color: #0e0e0e;
    bottom: -1rem;
    content: '';
    height: 1px;
    left: 0;
    position: absolute;
    width: 3rem; }
  .t-cond .infobox-breaker__kicker::after {
    background-color: #fff; }
  .infobox-breaker--inverted .infobox-breaker__kicker::after {
    background-color: #fff; }
  .infobox-breaker--right .infobox-breaker__kicker::after {
    left: auto;
    right: 0; }
  .infobox-breaker--center .infobox-breaker__kicker::after {
    left: 50%;
    transform: translateX(-50%); }
  @media (min-width: 48em) {
    .infobox-breaker__kicker {
      font-size: 1.6rem;
      margin-bottom: 3rem; }
      .infobox-breaker__kicker::after {
        bottom: -1.5rem;
        width: 4rem; } }
  @media (min-width: 75em) {
    .infobox-breaker__kicker {
      font-size: 1.8rem;
      margin-bottom: 5rem; }
      .infobox-breaker__kicker::after {
        width: 5.5rem; } }

.infobox-breaker__headline {
  font-size: 2rem;
  line-height: 1.2; }
  @media (min-width: 48em) {
    .infobox-breaker__headline {
      font-size: 3rem; } }
  @media (min-width: 75em) {
    .infobox-breaker__headline {
      font-size: 5rem;
      max-width: 80%; } }

.infobox-breaker__infobox {
  margin-left: auto;
  margin-right: auto;
  max-width: 100%;
  position: relative;
  z-index: 1; }
  @media all {
    .infobox-breaker__infobox {
      padding-left: 2.4rem;
      padding-right: 2.4rem; } }
  @media (min-width: 23.4375em) {
    .infobox-breaker__infobox {
      padding-left: 2.4rem;
      padding-right: 2.4rem; } }
  @media (min-width: 48em) {
    .infobox-breaker__infobox {
      padding-left: 2.4rem;
      padding-right: 2.4rem; } }
  @media (min-width: 75em) {
    .infobox-breaker__infobox {
      padding-left: 2.4rem;
      padding-right: 2.4rem;
      width: 107.2rem; } }
  @media (min-width: 87.5em) {
    .infobox-breaker__infobox {
      padding-left: 2.4rem;
      padding-right: 2.4rem; } }
  .infobox-breaker__infobox .infobox {
    max-width: 75rem; }
  .infobox-breaker__infobox .infobox-breaker--center .infobox {
    margin: 0 auto; }
  @media (max-width: 47.99em) {
    .infobox-breaker__infobox {
      margin-top: 3.2rem; } }
  @media (min-width: 48em) {
    .infobox-breaker__infobox {
      margin-top: -7.5rem; } }

@media (min-width: 48em) {
  .masonry__items {
    margin-right: -3.2rem; } }

.masonry__item,
.masonry__items-grid-sizer {
  width: 100%; }
  @media (min-width: 48em) {
    .masonry__item,
    .masonry__items-grid-sizer {
      width: calc(50% - 3.2rem); } }
  @media (min-width: 75em) {
    .masonry__item,
    .masonry__items-grid-sizer {
      width: calc(33.333% - 3.2rem); } }
  @media (min-width: 87.5em) {
    .masonry__item,
    .masonry__items-grid-sizer {
      width: calc(25% - 3.2rem); } }

.masonry__item {
  margin-bottom: 1.6rem; }
  @media (min-width: 48em) {
    .masonry__item {
      margin-bottom: 3.2rem; } }

.masonry__items-gutter-sizer {
  width: 1.6rem; }
  @media (min-width: 48em) {
    .masonry__items-gutter-sizer {
      width: 3.2rem; } }

.partner-breaker__content-wrap {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  margin-bottom: 6rem; }
  @media (min-width: 48em) {
    .partner-breaker__content-wrap {
      flex-direction: row;
      gap: 3.2rem;
      justify-content: space-between; } }
  @media (min-width: 75em) {
    .partner-breaker__content-wrap {
      margin-bottom: 9.2rem; } }

@media (min-width: 48em) {
  .partner-breaker__content {
    flex-grow: 1;
    flex-shrink: 0;
    max-width: 67rem;
    width: 50%; } }

.partner-breaker__headline {
  padding-bottom: 2rem;
  position: relative; }
  .partner-breaker__headline::after {
    background-color: #0e0e0e;
    bottom: 1rem;
    content: '';
    display: block;
    height: 1px;
    left: 0;
    position: absolute;
    width: 5.5rem; }
    @media (min-width: 48em) {
      .partner-breaker__headline::after {
        bottom: 1.5rem; } }
  @media (min-width: 48em) {
    .partner-breaker__headline {
      padding-bottom: 3rem; } }

.partner-breaker__list {
  list-style: none;
  margin: 0;
  padding-left: 0;
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  gap: 4rem; }
  @media (min-width: 75em) {
    .partner-breaker__list {
      gap: 6rem; } }

.partner-breaker__item {
  text-align: center;
  width: calc(50% - 4rem); }
  @media (min-width: 48em) {
    .partner-breaker__item {
      width: auto; } }

.blog-card-slider {
  display: block;
  position: relative;
  width: 100%; }

.blog-card-slider__headline {
  display: block;
  font-family: "Lato", "Lucida Grande", Tahoma, "Helvetica", "Arial", sans-serif !important;
  font-size: 3.2rem;
  font-weight: bold !important;
  margin-bottom: 1em;
  position: relative;
  text-align: center;
  width: 100%; }

.blog-card-slider__track {
  gap: 2.4rem; }

.blog-card-slider__slide {
  display: flex;
  flex-grow: 1;
  max-width: 86vw;
  min-width: 86vw; }

.blog-card-slider__slides {
  padding-left: 2.4rem; }

.blog-card-slider__controls {
  display: flex;
  justify-content: center;
  margin-top: 6.6rem;
  width: 100%; }

.blog-card-slider__button {
  display: flex;
  justify-content: center;
  margin-top: 7rem;
  position: relative;
  width: 100%; }

@media (min-width: 48em) {
  .blog-card-slider__headline {
    font-size: 6rem; }
  .blog-card-slider__track {
    gap: 3.2rem; }
  .blog-card-slider__slide {
    max-width: calc(48vw - 3.2rem);
    min-width: calc(48vw - 3.2rem); }
  .blog-card-slider__slides {
    padding-left: 0; } }

@media (min-width: 75em) {
  .blog-card-slider__slide {
    max-width: calc(33vw - 3.2rem);
    min-width: calc(33vw - 3.2rem); } }

.expert-card-slider {
  display: block;
  position: relative;
  width: 100%; }
  .expert-card-slider__headline {
    display: block;
    font-family: "Lato", "Lucida Grande", Tahoma, "Helvetica", "Arial", sans-serif !important;
    font-size: 3.2rem;
    font-weight: bold !important;
    margin-bottom: 1em;
    position: relative;
    text-align: center;
    width: 100%; }
  .expert-card-slider__track {
    gap: 3.2rem; }
  .expert-card-slider__slide {
    max-width: 24rem;
    min-width: 24rem; }
  .expert-card-slider__slides {
    padding-left: 2.4rem; }
  .expert-card-slider__controls {
    display: flex;
    justify-content: center;
    margin-top: 6.6rem;
    width: 100%; }
  @media (min-width: 75em) {
    .expert-card-slider__headline {
      font-size: 6rem; }
    .expert-card-slider__slides {
      padding-left: 0; } }

.image-link-tile-slider {
  display: block;
  position: relative;
  width: 100%; }
  .image-link-tile-slider__headline {
    display: block;
    font-family: "Lato", "Lucida Grande", Tahoma, "Helvetica", "Arial", sans-serif !important;
    font-size: 3.2rem;
    font-weight: bold !important;
    margin-bottom: 1em;
    position: relative;
    text-align: center;
    width: 100%; }
  .image-link-tile-slider__track {
    gap: 2.4rem; }
  .image-link-tile-slider__slide {
    max-width: calc(85vw - 2.4rem);
    min-width: calc(85vw - 2.4rem); }
  .image-link-tile-slider__slides {
    padding-left: 2.4rem; }
  .image-link-tile-slider__controls {
    display: flex;
    justify-content: center;
    margin-top: 6.6rem;
    width: 100%; }
  @media (min-width: 48em) {
    .image-link-tile-slider__slide {
      max-width: calc(50vw - 2.4rem);
      min-width: calc(50vw - 2.4rem); } }
  @media (min-width: 75em) {
    .image-link-tile-slider__headline {
      font-size: 6rem; }
    .image-link-tile-slider__track {
      gap: 3.2rem; }
    .image-link-tile-slider__slide {
      max-width: calc(33vw - 3.2rem);
      min-width: calc(33vw - 3.2rem); }
    .image-link-tile-slider__slides {
      padding-left: 0; } }

.news-overview {
  display: block;
  position: relative;
  width: 100%; }

.news-overview__news,
.news-overview__publications {
  display: none;
  position: relative;
  width: 100%; }
  @media (min-width: 75em) {
    .news-overview__news,
    .news-overview__publications {
      display: block; } }

@media (min-width: 75em) {
  .news-overview__news-slider-wrapper,
  .news-overview__publications-slider-wrapper {
    display: none; } }

.news-overview__news-slide,
.news-overview__publications-slide {
  max-width: 86vw;
  min-width: 86vw; }

.news-overview__news-slides,
.news-overview__publications-slides {
  display: flex;
  gap: 2.4rem; }

.news-overview__news-controls,
.news-overview__publications-controls {
  justify-content: center;
  margin-top: 6.6rem;
  padding-bottom: 6rem;
  width: 100%; }

.news-overview__news-button,
.news-overview__publications-button {
  display: flex;
  justify-content: center;
  position: relative;
  width: 100%; }

@media (min-width: 75em) {
  .news-overview__news-slide,
  .news-overview__publications-slide {
    max-width: calc(33% - 3.2rem);
    min-width: calc(33% - 3.2rem);
    transform: none !important; }
  .news-overview__news-slider,
  .news-overview__publications-slider {
    display: block;
    position: relative;
    width: 100%; }
  .news-overview__news-slides,
  .news-overview__publications-slides {
    cursor: inherit !important;
    flex-wrap: wrap;
    gap: 3.2rem;
    left: auto !important; }
  .news-overview__news-slide.news-teaser--featured,
  .news-overview__publications-slide.news-teaser--featured {
    max-width: none;
    min-width: 100%;
    width: 100%; }
  .news-overview__news-controls,
  .news-overview__publications-controls {
    display: none; }
  .news-overview__news-button,
  .news-overview__publications-button {
    margin-top: 6rem; } }

.special-teasers {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%; }

.special-teasers__headline {
  display: block;
  font-family: "Lato", "Lucida Grande", Tahoma, "Helvetica", "Arial", sans-serif !important;
  font-size: 3.2rem;
  font-weight: bold !important;
  margin-bottom: 1em;
  position: relative;
  text-align: center;
  width: 100%; }

.special-teasers__opener {
  display: block;
  height: 50.8rem;
  margin-bottom: 4rem;
  max-height: 50.8rem;
  max-width: 100%;
  min-width: 100%;
  overflow: hidden;
  position: relative;
  width: 100%; }

.special-teasers__opener-img {
  display: block;
  height: auto;
  max-height: 50.8rem;
  object-fit: cover;
  position: relative;
  width: 100%; }
  .special-teasers__opener-img img {
    height: 50.8rem;
    max-height: 50.8rem;
    object-fit: cover;
    width: 100%; }

.special-teasers__opener-title {
  font-family: "Lato", "Lucida Grande", Tahoma, "Helvetica", "Arial", sans-serif;
  align-items: center;
  color: #fff;
  display: flex;
  font-size: 3.2rem;
  font-weight: bold;
  height: 100%;
  hyphens: auto;
  left: 50%;
  line-height: 1.2em;
  max-width: 105rem;
  position: absolute;
  top: 0;
  transform: translate(-50%, -10%);
  width: 100%;
  z-index: 20; }
  @media (max-width: 74.99em) {
    .special-teasers__opener-title {
      padding: 0 4.45rem; } }
  @media (min-width: 75em) {
    .special-teasers__opener-title {
      font-size: 6rem; } }

.special-teasers__opener--shadow::before {
  background-color: rgba(0, 0, 0, 0.4);
  content: '';
  display: block;
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 10; }

.special-teasers__slider {
  margin: 0 auto;
  max-width: 105rem;
  width: 100%; }

.index-chart-flow__menu {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  gap: 1rem;
  margin-bottom: 2rem; }
  @media (max-width: 47.99em) {
    .index-chart-flow__menu .p-select:first-child {
      display: none; } }
  @media (min-width: 48em) {
    .index-chart-flow__menu {
      flex-direction: row; } }

.index-chart-flow__content {
  display: flex;
  flex-direction: column-reverse; }
  @media (min-width: 48em) {
    .index-chart-flow__content {
      flex-direction: row; } }

.index-chart-flow__ranking {
  background-color: #fff;
  padding: 1rem;
  width: 100%; }
  @media (min-width: 48em) {
    .index-chart-flow__ranking {
      margin-right: 2rem;
      width: 22rem; } }

.index-chart-flow__display {
  margin-bottom: 2rem;
  width: 100%; }
  @media (min-width: 48em) {
    .index-chart-flow__display {
      margin: 0; } }

.index-chart-flow__chart {
  height: 40rem;
  width: 100%; }

.index-chart-flow__table {
  height: 40rem;
  margin-bottom: 2rem; }
  .index-chart-flow__table .p-datatable-table-container {
    max-width: 82rem; }

.index-chart-flow__slider-controls {
  align-items: center;
  display: flex;
  margin-bottom: 2rem; }

.index-chart-flow__slider-controls-inner {
  margin: 0 2rem;
  width: 100%; }

.index-chart-flow__slider-input {
  font-size: 1.4rem !important;
  text-align: center;
  user-select: none;
  width: 6rem; }

.index-chart-flow__actions {
  display: flex;
  justify-content: space-between; }

.index-chart-flow__action + .index-chart-flow__action {
  margin-left: 1rem; }

.index-chart-flow__ranking-headline {
  margin-bottom: 3rem; }

.index-chart-flow__ranking-year {
  color: #0c55a6; }

.index-chart-flow__ranking-list {
  list-style: none;
  margin: 0;
  padding-left: 0; }

.index-chart-flow__ranking-flag {
  font-size: 2rem;
  margin-left: 0.75rem; }

.index-chart-flow__ranking-number {
  color: #868686;
  letter-spacing: 0.5px;
  line-height: 1.1; }

.index-chart-flow__ranking-fallback {
  color: #868686;
  font-size: 1.2rem;
  line-height: 1.5; }

.index-chart-flow__ranking-list-item {
  margin-bottom: 1rem; }

.index-chart-flow__ranking-list-item:first-child {
  display: flex;
  flex-direction: column-reverse; }
  .index-chart-flow__ranking-list-item:first-child .index-chart-flow__ranking-number {
    color: #0e0e0e;
    font-weight: bold; }
  .index-chart-flow__ranking-list-item:first-child .index-chart-flow__ranking-flag {
    display: block;
    font-size: 6rem;
    margin-bottom: 0.5rem;
    margin-left: 0; }
  .index-chart-flow__ranking-list-item:first-child .index-chart-flow__ranking-text {
    font-weight: bold; }

.index-chart-spider__menu {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  gap: 1rem;
  margin-bottom: 2rem; }
  @media (max-width: 47.99em) {
    .index-chart-spider__menu .p-select:first-child {
      display: none; } }
  @media (min-width: 48em) {
    .index-chart-spider__menu {
      flex-direction: row; } }

.index-chart-spider__content {
  display: flex;
  flex-direction: column-reverse; }
  @media (min-width: 48em) {
    .index-chart-spider__content {
      flex-direction: row; } }

.index-chart-spider__display {
  margin-bottom: 2rem;
  width: 100%; }
  @media (min-width: 48em) {
    .index-chart-spider__display {
      margin: 0; } }

.index-chart-spider__chart {
  height: 40rem;
  margin-bottom: 2rem;
  width: 100%; }

.index-chart-spider__table {
  height: 40rem;
  margin-bottom: 2rem; }

.index-chart-spider__slider-controls {
  align-items: center;
  display: flex;
  text-align: center; }

.index-chart-spider__slider-controls-inner {
  margin: 0 2rem;
  width: 100%; }

.index-chart-spider__slider {
  margin-bottom: 1rem; }
  .index-chart-spider__slider .p-slider-range {
    background: transparent; }

.index-chart-spider__slider-input {
  font-size: 1.4rem !important;
  text-align: center;
  user-select: none;
  width: 60px; }

.index-chart-spider__tag {
  position: relative;
  top: -2rem; }

.index-chart-spider__actions {
  display: flex;
  justify-content: flex-end; }
